import { uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import Select, { OnChangeValue } from "react-select";

import { useAppSelector } from "../../hooks";
import { defaultSelectProps } from "../../utils";

type MetadataValueSelectOption = { value: number; label: string };

const MetadataValueSelect = ({
    className,
    fieldId,
    onChange,
    selected,
}: {
    className?: string;
    fieldId: number;
    selected: number[];
    onChange: (value: number[]) => void;
}) => {
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const options: MetadataValueSelectOption[] = useMemo(() => {
        const field = interviewMetadataFields.find((f) => f.id === fieldId);
        return field
            ? uniqBy(
                  interviewMetadataValues[fieldId].map((val) => ({
                      value: val.id,
                      label: val.data,
                  })),
                  (opt) => opt.label,
              )
            : [];
    }, [fieldId, interviewMetadataFields, interviewMetadataValues]);

    const selectedOptions: MetadataValueSelectOption[] = useMemo(() => {
        const field = interviewMetadataFields.find((f) => f.id === fieldId);
        return field
            ? options.filter((val) => selected.includes(val.value))
            : [];
    }, [fieldId, interviewMetadataFields, options, selected]);

    const handleChange = useCallback(
        (newValue: OnChangeValue<MetadataValueSelectOption, true>) => {
            onChange(newValue.map((val) => val.value));
        },
        [onChange],
    );

    return (
        <Select
            {...defaultSelectProps}
            className={className}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
            options={options}
            value={selectedOptions}
            onChange={handleChange}
        />
    );
};

export default MetadataValueSelect;
