import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { Filter } from "../filters";
import {
    Competitor,
    DecisionReason,
    Interview,
    InterviewMetadataField,
    InterviewMetadataFieldValuesWithIds,
    InterviewOutcome,
    Project,
    ProjectQuestionSummary,
    Prompt,
    Question,
    QuestionGroup,
    Quote,
    ResponseSummary,
    Subtheme,
} from "../types/goldpan";

interface ProjectState {
    isProjectLoading: boolean;
    project: Project | null;
    interviews: Interview[];
    interviewMetadataFields: InterviewMetadataField[];
    interviewMetadataValues: InterviewMetadataFieldValuesWithIds;
    competitors: Competitor[];
    questions: Question[];
    questionGroups: QuestionGroup[];
    questionResponseSummaries: ResponseSummary[];
    projectQuestionSummaries: ProjectQuestionSummary[];
    subthemes: Subtheme[];
    aiPrompts: Prompt[];
    quotes: Quote[];
    outcomes: InterviewOutcome[];
    decisionReasons: DecisionReason[];
    filters: Filter[];
}

const initialState: ProjectState = {
    isProjectLoading: true,
    project: null,
    interviews: [],
    interviewMetadataFields: [],
    interviewMetadataValues: {},
    competitors: [],
    questions: [],
    questionGroups: [],
    questionResponseSummaries: [],
    projectQuestionSummaries: [],
    subthemes: [],
    aiPrompts: [],
    quotes: [],
    outcomes: [],
    decisionReasons: [],
    filters: [],
};

const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setIsProjectLoading: (state, action: PayloadAction<boolean>) => {
            state.isProjectLoading = action.payload;
        },
        resetProject: (state) => {
            state.project = null;
            state.interviews = [];
            state.interviewMetadataFields = [];
            state.interviewMetadataValues = {};
            state.competitors = [];
            state.questions = [];
            state.questionGroups = [];
            state.questionResponseSummaries = [];
            state.projectQuestionSummaries = [];
            state.subthemes = [];
            state.aiPrompts = [];
            state.quotes = [];
            state.outcomes = [];
            state.decisionReasons = [];
            state.filters = [];
        },
        setInterviews: (state, action: PayloadAction<Interview[]>) => {
            state.interviews = action.payload;

            const metadataValues = state.interviewMetadataValues;
            action.payload.forEach((interview) => {
                interview.metadata_list.forEach((metadata) => {
                    metadataValues[metadata.interview_field.id] =
                        metadataValues[metadata.interview_field.id] ===
                        undefined
                            ? [{ data: metadata.data, id: metadata.id }]
                            : [
                                  ...metadataValues[
                                      metadata.interview_field.id
                                  ],
                                  { data: metadata.data, id: metadata.id },
                              ];
                });
            });

            state.interviewMetadataValues = metadataValues;
        },
        setInterviewMetadataFields: (
            state,
            action: PayloadAction<InterviewMetadataField[]>,
        ) => {
            state.interviewMetadataFields = action.payload;
        },
        setProject: (state, action: PayloadAction<Project>) => {
            state.project = action.payload;
        },
        setCompetitors: (state, action: PayloadAction<Competitor[]>) => {
            state.competitors = action.payload;
        },
        setQuestions: (state, action: PayloadAction<Question[]>) => {
            state.questions = action.payload;
        },
        setQuestionGroups: (state, action: PayloadAction<QuestionGroup[]>) => {
            state.questionGroups = action.payload;
        },
        setQuestionResponseSummaries: (
            state,
            action: PayloadAction<ResponseSummary[]>,
        ) => {
            state.questionResponseSummaries = action.payload;
        },
        setProjectQuestionSummaries: (
            state,
            action: PayloadAction<ProjectQuestionSummary[]>,
        ) => {
            state.projectQuestionSummaries = action.payload;
        },
        setSubthemes: (state, action: PayloadAction<Subtheme[]>) => {
            state.subthemes = action.payload;
        },
        setAiPrompts: (state, action: PayloadAction<Prompt[]>) => {
            state.aiPrompts = action.payload;
        },
        setQuotes: (state, action: PayloadAction<Quote[]>) => {
            state.quotes = action.payload;
        },
        setOutcomes: (state, action: PayloadAction<InterviewOutcome[]>) => {
            state.outcomes = action.payload;
        },
        setDecisionReasons: (
            state,
            action: PayloadAction<DecisionReason[]>,
        ) => {
            state.decisionReasons = action.payload;
        },
        setFilters: (state, action: PayloadAction<Filter[]>) => {
            state.filters = action.payload;
        },
    },
});

export const {
    resetProject,
    setAiPrompts,
    setCompetitors,
    setDecisionReasons,
    setFilters,
    setInterviewMetadataFields,
    setInterviews,
    setIsProjectLoading,
    setOutcomes,
    setProject,
    setProjectQuestionSummaries,
    setQuestionGroups,
    setQuestionResponseSummaries,
    setQuestions,
    setQuotes,
    setSubthemes,
} = projectSlice.actions;

export default projectSlice.reducer;
