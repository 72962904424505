import { compact, uniqBy } from "lodash";
import { useMemo } from "react";

import {
    Comparison,
    ComparisonType,
    MAX_COMPARISONS,
    comparisonClassnames,
} from "../../comparisons";
import { useAppSelector } from "../../hooks";
import Button, { ButtonVariant } from "../button";
import Checkbox from "../checkbox";

const isComparisonEqual = (
    comparison1: Comparison,
    comparison2: Comparison,
) => {
    return (
        comparison1.type === comparison2.type &&
        comparison1.data === comparison2.data
    );
};

const ComparisonSidebar = ({
    activeComparison,
    onChangeComparison,
}: {
    activeComparison: Comparison[];
    onChangeComparison: (filters: Comparison[]) => void;
}) => {
    const project = useAppSelector((state) => state.project.project);
    const outcomes = useAppSelector((state) => state.project.outcomes);
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const comparisonOptions: Comparison[] = useMemo(() => {
        if (!project?.main_interview_field) {
            return outcomes.map((outcome) => ({
                type: ComparisonType.OUTCOMES,
                outcomeId: outcome.id,
                data: outcome.name,
            }));
        }
        const field = interviewMetadataFields.find(
            (f) => f.id === project.main_interview_field,
        );
        return field
            ? uniqBy(
                  interviewMetadataValues[field.id].map((d) => ({
                      type: ComparisonType.METADATA,
                      fieldId: field.id,
                      valueId: d.id,
                      data: d.data,
                  })),
                  "data",
              )
            : [];
    }, [interviewMetadataFields, interviewMetadataValues, outcomes, project]);

    return (
        <div className="bg-white shadow rounded-lg place-self-start w-full">
            <div className="p-4 h-16 flex items-center justify-between">
                <div className="font-bold">Compare</div>
                {activeComparison.length > 0 && (
                    <div className="flex gap-2 items-center">
                        <Button
                            className="no-bg icon-only clear-all-comparisons"
                            icon="close"
                            tooltip="Clear comparison"
                            variant={ButtonVariant.DANGER}
                            onClick={() => onChangeComparison([])}
                        />
                    </div>
                )}
            </div>

            <div className="flex flex-col gap-2 px-4 pb-4">
                {comparisonOptions.sort().map((option) => {
                    const classNameColor = activeComparison.find((active) =>
                        isComparisonEqual(active, option),
                    )?.color;
                    const className = `accent-${classNameColor} text-${classNameColor}`;

                    const nextColor =
                        activeComparison.length === 0
                            ? comparisonClassnames[0]
                            : comparisonClassnames.filter(
                                  (c) =>
                                      !compact(
                                          activeComparison.map((a) => a.color),
                                      ).includes(c),
                              )[0];

                    return (
                        <Checkbox
                            checkboxClassName={className}
                            checked={
                                !!activeComparison.find((active) =>
                                    isComparisonEqual(active, option),
                                )
                            }
                            className={className}
                            disabled={
                                !activeComparison.find((active) =>
                                    isComparisonEqual(active, option),
                                ) && activeComparison.length >= MAX_COMPARISONS
                            }
                            key={`${option.type}${option.data}`}
                            label={option.data}
                            labelClassName={className}
                            onChange={(checked) =>
                                checked
                                    ? onChangeComparison(
                                          activeComparison.concat([
                                              {
                                                  ...option,
                                                  color: nextColor,
                                              },
                                          ]),
                                      )
                                    : onChangeComparison(
                                          activeComparison.filter(
                                              (o) =>
                                                  !isComparisonEqual(o, option),
                                          ),
                                      )
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ComparisonSidebar;
