import { useCallback, useMemo, useState } from "react";
import Select, { Options } from "react-select";
import CreatableSelect from "react-select/creatable";

import { getProjectIdFromUrl, saveQuestion } from "../../api";
import {
    InterviewParagraph,
    Question,
    ResponseSummaryOption,
    SelectOption,
} from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import FormField from "../form-field";
import Icon from "../icon";
import Modal from "../modal";
import QuoteInput from "./quote-input";

interface Props {
    responseSummaryOption: ResponseSummaryOption;
    question: Question;
    paragraphOptions: Options<SelectOption>;
    interviewParagraphs: InterviewParagraph[];
    isDisabled: boolean;
    onUpdateQuestion: (question: Question) => void;
    onUpdateResponseSummaryOption: (
        responseSummaryOption: ResponseSummaryOption,
    ) => void;
    onDeleteResponseSummaryOption: () => Promise<void>;
}

const ResponseSummaryOptionCard: React.FC<Props> = ({
    interviewParagraphs,
    isDisabled,
    onDeleteResponseSummaryOption,
    onUpdateQuestion,
    onUpdateResponseSummaryOption,
    paragraphOptions,
    question,
    responseSummaryOption,
}) => {
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSavingNewOption, setIsSavingNewOption] = useState(false);

    const questionOptionOptions = useMemo(
        () =>
            question.options.map((option) => ({
                value: option.id,
                label: option.text,
            })),
        [question],
    );

    const handleCreateNewOption = useCallback(
        (text: string) => {
            setIsSavingNewOption(true);
            saveQuestion(getProjectIdFromUrl(), {
                ...question,
                options: [
                    ...question.options,
                    {
                        id: null,
                        text,
                        ai_prompt_description: "",
                    },
                ],
            }).then((questionResponse) => {
                setIsSavingNewOption(false);
                if (questionResponse) {
                    const newOption = questionResponse.data.options.find(
                        (opt) => opt.text === text,
                    );
                    onUpdateQuestion(questionResponse.data);
                    if (newOption) {
                        onUpdateResponseSummaryOption({
                            ...responseSummaryOption,
                            option: newOption.id,
                        });
                    }
                }
            });
        },
        [
            question,
            onUpdateQuestion,
            onUpdateResponseSummaryOption,
            responseSummaryOption,
        ],
    );

    const handleDeleteResponseSummaryOption = useCallback(async () => {
        setIsDeleting(true);
        await onDeleteResponseSummaryOption();
        setIsConfirmingDelete(false);
        setIsDeleting(true);
    }, [onDeleteResponseSummaryOption]);

    const handleQuoteChanged = useCallback(
        (text: string) =>
            onUpdateResponseSummaryOption({
                ...responseSummaryOption,
                quotes: [
                    {
                        ...responseSummaryOption.quotes[0],
                        text,
                    },
                ],
            }),
        [onUpdateResponseSummaryOption, responseSummaryOption],
    );

    return (
        <div className="border-t mt-2 pt-2">
            <Modal
                isOpen={isConfirmingDelete}
                onClose={() => setIsConfirmingDelete(false)}
                onConfirm={handleDeleteResponseSummaryOption}
            >
                Are you sure?
            </Modal>

            <div className="flex flex-grow gap-2">
                <div className="flex-grow w-1/3">
                    <FormField label="Option">
                        <CreatableSelect
                            {...defaultSelectProps}
                            className="mt-2"
                            isDisabled={isDisabled}
                            isLoading={isSavingNewOption}
                            options={questionOptionOptions}
                            value={
                                questionOptionOptions.find(
                                    (i) =>
                                        i.value ===
                                        responseSummaryOption.option,
                                ) ?? null
                            }
                            onChange={(opt) => {
                                onUpdateResponseSummaryOption({
                                    ...responseSummaryOption,
                                    option: opt!.value,
                                });
                            }}
                            onCreateOption={handleCreateNewOption}
                        />
                    </FormField>
                </div>
                <div className="flex-grow flex flex-col gap-2 w-2/3">
                    <div>
                        <div className="flex items-center gap-2">
                            <label>Paragraph</label>
                            {responseSummaryOption.quotes[0]
                                .interview_paragraph && (
                                <Button
                                    className="no-bg icon-only"
                                    onClick={() => {
                                        const paragraph =
                                            document.getElementById(
                                                "paragraph-" +
                                                    responseSummaryOption
                                                        .quotes[0]
                                                        .interview_paragraph,
                                            );
                                        paragraph?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                    }}
                                >
                                    <Icon icon="visibility" />
                                </Button>
                            )}
                        </div>
                        <Select
                            {...defaultSelectProps}
                            isClearable
                            isDisabled={isDisabled}
                            options={paragraphOptions}
                            value={
                                paragraphOptions.find(
                                    (i) =>
                                        i.value ===
                                        responseSummaryOption.quotes[0]
                                            .interview_paragraph,
                                ) ?? null
                            }
                            onChange={(opt) => {
                                onUpdateResponseSummaryOption({
                                    ...responseSummaryOption,
                                    quotes: [
                                        {
                                            ...responseSummaryOption.quotes[0],
                                            interview_paragraph: opt
                                                ? opt.value
                                                : null,
                                            // update text if quote field is blank
                                            text:
                                                responseSummaryOption.quotes[0]
                                                    .text === "" && opt
                                                    ? interviewParagraphs.find(
                                                          (p) =>
                                                              p.id ===
                                                              opt.value,
                                                      )?.text ?? ""
                                                    : responseSummaryOption
                                                          .quotes[0].text,
                                        },
                                    ],
                                });
                            }}
                        />
                    </div>
                    <QuoteInput
                        isDisabled={isDisabled}
                        paragraph={
                            interviewParagraphs.find(
                                (p) =>
                                    p.id ===
                                    responseSummaryOption.quotes[0]
                                        .interview_paragraph,
                            )?.text
                        }
                        quote={responseSummaryOption.quotes[0].text}
                        onChange={handleQuoteChanged}
                    />
                </div>
                <Button
                    className="mt-8 self-start icon-only"
                    disabled={isDisabled}
                    icon="close"
                    isLoading={isDeleting}
                    variant={ButtonVariant.DANGER}
                    onClick={() => setIsConfirmingDelete(true)}
                />
            </div>
        </div>
    );
};

export default ResponseSummaryOptionCard;
