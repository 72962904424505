import { ResponsivePie } from "@nivo/pie";
import classNames from "classnames";
import { flatMap, reverse, sortBy } from "lodash";
import groupBy from "lodash/groupBy";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import Icon from "../components/icon";
import { useAppSelector } from "../hooks";
import { Sentiment } from "../types/goldpan";
import { assertNonNull, getPercentage, getSetimentColor } from "../utils";
import InterviewTable from "./interview-table";

const ProjectSummaryApp = () => {
    const isProjectLoading = useAppSelector(
        (state) => state.project.isProjectLoading,
    );
    const project = useAppSelector((state) => state.project.project);
    const projectQuestionSummaries = useAppSelector(
        (state) => state.project.projectQuestionSummaries,
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const outcomes = useAppSelector((state) => state.project.outcomes);
    const subthemes = useAppSelector((state) => state.project.subthemes);
    const questions = useAppSelector((state) => state.project.questions);
    const decisionReasons = useAppSelector(
        (state) => state.project.decisionReasons,
    );
    const questionResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );

    const projectSummary = useMemo(() => {
        if (isProjectLoading) {
            return null;
        }

        return {
            interviewCount: interviews.length,
            outcomeDecisionReasons: outcomes.map((outcome) => {
                const outcomeInterviews = interviews.filter(
                    (interview) => interview.outcome?.id === outcome.id,
                );
                return {
                    ...outcome,
                    interviewCount: outcomeInterviews.length,
                    subthemes: subthemes.map((subtheme) => {
                        const subthemeDecisions = decisionReasons.filter(
                            (decision) =>
                                outcomeInterviews
                                    .map((interview) => interview.id)
                                    .includes(decision.interview) &&
                                decision.subtheme === subtheme.id,
                        );
                        return {
                            ...subtheme,
                            count: subthemeDecisions.length,
                            percentage:
                                subthemeDecisions.length /
                                decisionReasons.filter((decision) =>
                                    outcomeInterviews
                                        .map((interview) => interview.id)
                                        .includes(decision.interview),
                                ).length,
                        };
                    }),
                };
            }),
        };
    }, [isProjectLoading, interviews, outcomes, subthemes, decisionReasons]);

    const data: { value: number; color: string }[] = useMemo(() => {
        if (!projectSummary) {
            return [];
        }
        const groupedBySentiment = groupBy(
            projectSummary.outcomeDecisionReasons,
            (outcome) => outcome.sentiment,
        );
        const outcomeData = flatMap(
            groupedBySentiment,
            (outcomes, sentiment) => {
                return outcomes.map((outcome, index) => {
                    return {
                        id: outcome.id,
                        label: outcome.name,
                        value: outcome.interviewCount,
                        sentiment,
                        color: getSetimentColor(sentiment as Sentiment, index),
                    };
                });
            },
        );
        return outcomeData.sort((data) =>
            data.sentiment === Sentiment.POSITIVE
                ? -1
                : data.sentiment === Sentiment.NEUTRAL
                ? 0
                : 1,
        );
    }, [projectSummary]);

    if (interviews.length === 0) {
        return (
            <div className="mt-10 max-w-4xl mx-auto text-xl text-gray-400 text-center">
                Enter interview data to see insights
            </div>
        );
    }

    return (
        <>
            <div className="lg:ml-4 grid grid-cols-1 lg:grid-cols-3">
                <div className="col-span-1">
                    <div className="card relative" id="interview-details">
                        <div className="floating-card flex items-center justify-between bg-gp-blue px-6 h-16 rounded-lg shadow-md text-white">
                            {projectSummary ? (
                                <div className="font-bold text-4xl">
                                    {projectSummary.interviewCount}
                                </div>
                            ) : (
                                <div className="animate-pulse rounded-full bg-gray-300 h-10 w-10"></div>
                            )}
                            <div className="font-bold">Total interviews</div>
                        </div>
                        <div className="flex flex-col gap-2 divide-y mt-20">
                            {projectSummary ? (
                                projectSummary.outcomeDecisionReasons.map(
                                    (outcomeData) => (
                                        <div
                                            className="flex pt-2 items-center justify-between"
                                            key={outcomeData.id}
                                        >
                                            <div className="font-bold text-2xl text-center">
                                                {outcomeData.interviewCount}
                                            </div>
                                            <div>
                                                <div className="text-right">
                                                    {outcomeData.name}
                                                </div>
                                                <div
                                                    className="text-xs font-bold text-right uppercase"
                                                    style={{
                                                        color: getSetimentColor(
                                                            outcomeData.sentiment,
                                                        ),
                                                    }}
                                                >
                                                    {outcomeData.sentiment}
                                                </div>
                                            </div>
                                        </div>
                                    ),
                                )
                            ) : (
                                <>
                                    <div className="animate-pulse flex pt-2 items-center justify-between">
                                        <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                                        <div className="rounded-full bg-gray-300 h-4 w-12"></div>
                                    </div>
                                    <div className="animate-pulse flex pt-2 items-center justify-between">
                                        <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                                        <div className="rounded-full bg-gray-300 h-4 w-12"></div>
                                    </div>
                                    <div className="animate-pulse flex pt-2 items-center justify-between">
                                        <div className="rounded-full bg-gray-300 h-10 w-10"></div>
                                        <div className="rounded-full bg-gray-300 h-4 w-16"></div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="mt-4 lg:mt-0 lg:col-span-2 lg:ml-12 card">
                    <div className="font-bold text-lg">
                        Interview outcome breakdown
                    </div>
                    <div className="h-[260px]">
                        {projectSummary ? (
                            <ResponsivePie
                                activeOuterRadiusOffset={8}
                                animate
                                borderWidth={1}
                                colors={{ datum: "data.color" }}
                                cornerRadius={3}
                                data={data}
                                enableArcLabels={false}
                                enableArcLinkLabels={false}
                                endAngle={90}
                                innerRadius={0.5}
                                isInteractive={false}
                                legends={[
                                    {
                                        anchor: "bottom",
                                        direction: "row",
                                        justify: false,
                                        translateX: 0,
                                        translateY: 56,
                                        itemsSpacing: 0,
                                        itemWidth: 100,
                                        itemHeight: 18,
                                        itemTextColor: "#000",
                                        itemDirection: "left-to-right",
                                        itemOpacity: 1,
                                        symbolSize: 18,
                                        symbolShape: "circle",
                                    },
                                ]}
                                margin={{
                                    top: 40,
                                    right: 80,
                                    bottom: 80,
                                    left: 80,
                                }}
                                padAngle={1.5}
                                startAngle={-90}
                            />
                        ) : (
                            <div className="p-8 animate-pulse h-full">
                                <div className="rounded-md bg-gray-300 h-full w-[60%] m-auto"></div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <h2 className="my-8 text-3xl font-bold">Decision reasons</h2>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-8">
                {projectSummary ? (
                    projectSummary.outcomeDecisionReasons.map((outcome) => {
                        if (
                            outcome.subthemes.length === 0 ||
                            outcome.interviewCount === 0
                        ) {
                            return null;
                        }
                        const totalDecisions = outcome.subthemes.reduce(
                            (sum, subtheme) => sum + subtheme.count,
                            0,
                        );
                        return (
                            <div
                                className="card flex flex-col"
                                key={outcome.id}
                            >
                                <h3 className="flex items-center gap-4 mb-2 py-2 border-b-2 border-black">
                                    <div className="text-xl font-semibold">
                                        {outcome.name}
                                    </div>{" "}
                                    <div
                                        className="text-xs font-bold text-right uppercase"
                                        style={{
                                            color: getSetimentColor(
                                                outcome.sentiment,
                                            ),
                                        }}
                                    >
                                        {outcome.sentiment}
                                    </div>
                                </h3>
                                <p className="text-sm font-medium">
                                    {totalDecisions}{" "}
                                    {totalDecisions === 1
                                        ? "decision"
                                        : "decisions"}{" "}
                                    across {outcome.interviewCount}{" "}
                                    {outcome.interviewCount === 1
                                        ? "interview"
                                        : "interviews"}
                                </p>
                                <div className="flex-1 mt-4">
                                    {reverse(sortBy(outcome.subthemes, "count"))
                                        .slice(0, 5)
                                        .map((subtheme, index) => {
                                            const themeName = `${subtheme.parent.name} > ${subtheme.name}`;
                                            const percentage = Math.round(
                                                subtheme.percentage * 100,
                                            );
                                            return (
                                                <div
                                                    className="h-12 border-l-8 pl-2 flex items-center gap-4"
                                                    key={subtheme.id}
                                                    style={{
                                                        background: `linear-gradient(to right, rgba(30, 27, 176, 0.1) ${percentage}%,#ffffff ${percentage}%)`,
                                                        borderColor: `rgba(30, 27, 176, ${Math.max(
                                                            0.1,
                                                            1 - index * 0.25,
                                                        )})`,
                                                    }}
                                                >
                                                    <div
                                                        className={classNames(
                                                            "font-bold w-8 text-center",
                                                            {
                                                                "text-4xl":
                                                                    index === 0,
                                                                "text-2xl":
                                                                    index === 1,
                                                                "text-xl":
                                                                    index > 1,
                                                            },
                                                        )}
                                                    >
                                                        {index + 1}.
                                                    </div>
                                                    <div
                                                        className={classNames({
                                                            "font-bold":
                                                                index === 0,
                                                            "text-xl":
                                                                index === 0,
                                                        })}
                                                    >
                                                        {themeName}{" "}
                                                        <span className="italic text-sm">
                                                            ({percentage}%)
                                                        </span>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <>
                        <div className="card flex flex-col">
                            <div className="flex items-center gap-4 mb-2 py-2 border-b-2 border-black">
                                <div className="animate-pulse">
                                    <div className="rounded-md bg-gray-300 w-12 h-6"></div>
                                </div>
                                <div className="animate-pulse">
                                    <div className="rounded-md bg-gray-300 w-12 h-6"></div>
                                </div>
                            </div>
                            <div className="animate-pulse">
                                <div className="rounded-md bg-gray-300 w-48 h-6"></div>
                            </div>
                            <div className="animate-pulse mt-4">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                        </div>
                        <div className="card flex flex-col">
                            <div className="flex items-center gap-4 mb-2 py-2 border-b-2 border-black">
                                <div className="animate-pulse">
                                    <div className="rounded-md bg-gray-300 w-12 h-6"></div>
                                </div>
                                <div className="animate-pulse">
                                    <div className="rounded-md bg-gray-300 w-12 h-6"></div>
                                </div>
                            </div>
                            <div className="animate-pulse">
                                <div className="rounded-md bg-gray-300 w-48 h-6"></div>
                            </div>
                            <div className="animate-pulse mt-4">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                            <div className="animate-pulse mt-1">
                                <div className="rounded-md bg-gray-300 w-full h-10"></div>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <h2 className="my-8 text-3xl font-bold">Buyer Journey</h2>
            <div className="grid grid-cols-12 gap-4">
                {questions.map((question) => {
                    const projectSummary = projectQuestionSummaries.find(
                        (summary) => summary.question === question.id,
                    );
                    return (
                        <div
                            className="flex flex-col card w-full h-full col-span-4"
                            key={question.id}
                        >
                            <div className="border-b-2 border-black mb-4">
                                <h3 className="text-xl font-semibold text-gp-blue-dark">
                                    {question.text}
                                </h3>
                                <p className="mb-2 text-sm font-medium gray-500">
                                    {question.subtext}
                                </p>
                            </div>
                            <div className="flex flex-col h-full">
                                {window.waffle.flag_is_active(
                                    "project_question_summaries",
                                ) &&
                                    projectSummary && (
                                        <div className="p-2 border rounded-md mb-1 bg-amber-100 border-amber-300">
                                            <div className="flex items-center text-amber-600">
                                                <Icon
                                                    className="mr-2"
                                                    icon="lightbulb"
                                                />
                                                <div>Summary</div>
                                            </div>
                                            <div className="text-sm">
                                                {projectSummary.summary}
                                            </div>
                                        </div>
                                    )}
                                {reverse(
                                    sortBy(question.options, (option) => {
                                        const questionResponseOptions = flatMap(
                                            questionResponseSummaries
                                                .filter(
                                                    (response) =>
                                                        response.question ===
                                                        question.id,
                                                )
                                                .map(
                                                    (summary) =>
                                                        summary.options,
                                                ),
                                        );
                                        return questionResponseOptions.filter(
                                            (response) =>
                                                response.option === option.id,
                                        ).length;
                                    }),
                                ).map((questionOption) => {
                                    const questionResponseOptions = flatMap(
                                        questionResponseSummaries
                                            .filter(
                                                (response) =>
                                                    response.question ===
                                                    question.id,
                                            )
                                            .map((summary) => summary.options),
                                    );
                                    const percentage = getPercentage(
                                        questionResponseOptions.filter(
                                            (response) =>
                                                response.option ===
                                                questionOption.id,
                                        ).length,
                                        questionResponseOptions.length,
                                    );

                                    return (
                                        <div
                                            className="flex flex-row mb-4"
                                            key={questionOption.id}
                                        >
                                            <div className="flex flex-row flex-1 text-sm pr-4 items-center">
                                                <div className="flex flex-1 flex-col">
                                                    <strong className="font-medium inline-flex mr-2">
                                                        {questionOption.text}
                                                    </strong>
                                                </div>
                                                <div className="flex flex-col px-4 text-center w-[80px]">
                                                    {percentage} %
                                                </div>
                                            </div>
                                            <div className="flex flex-col flex-1 justify-center">
                                                <div className="flex w-full h-3 rounded-lg bg-gray-200">
                                                    <div
                                                        className="flex h-3 rounded-lg  bg-gp-blue "
                                                        style={{
                                                            width: `${percentage}%`,
                                                        }}
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}

                                <div className="flex-grow"></div>
                                <div className="flex flex-row border-t border-gray-100 mt-8 pt-4 justify-end">
                                    <Link
                                        className="text-sm"
                                        to={`/project/${
                                            assertNonNull(project).id
                                        }/questions/`}
                                    >
                                        See All -&gt;{" "}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <h2 className="my-8 text-3xl font-bold">All Interviews</h2>
            <InterviewTable />
        </>
    );
};

export default ProjectSummaryApp;
