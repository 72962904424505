import { compact } from "lodash";

import {
    Interview,
    InterviewMetadataFieldValuesWithIds,
} from "./types/goldpan";

export enum FilterType {
    Outcome = "Outcome",
    InterviewMetadata = "InterviewMetadata",
    Competitor = "Competitor",
}

export type OutcomeFilter = {
    type: FilterType.Outcome;
    values: number[];
};
export type CompetitorFilter = {
    type: FilterType.Competitor;
    values: number[];
};
export type InterviewMetadataFilter = {
    type: FilterType.InterviewMetadata;
    field: number;
    values: number[];
};

export type Filter = OutcomeFilter | CompetitorFilter | InterviewMetadataFilter;

export const filterOfTypeExists = (
    filters: Filter[],
    filterType: FilterType,
) => {
    return filters.some(({ type }) => type === filterType);
};

export const filterInterviews = (
    allInterviews: Interview[],
    filters: Filter[],
    metadataValues: InterviewMetadataFieldValuesWithIds,
) => {
    return filters.length > 0
        ? allInterviews
              .filter(
                  (interview) =>
                      !filterOfTypeExists(filters, FilterType.Outcome) ||
                      (interview.outcome &&
                          filters.some(
                              (filter) =>
                                  filter.type === FilterType.Outcome &&
                                  filter.values.includes(interview.outcome!.id),
                          )),
              )
              .filter(
                  (interview) =>
                      !filterOfTypeExists(
                          filters,
                          FilterType.InterviewMetadata,
                      ) ||
                      filters
                          .filter(
                              (filter) =>
                                  filter.type === FilterType.InterviewMetadata,
                          )
                          .every(
                              (filter) =>
                                  filter.type ===
                                      FilterType.InterviewMetadata &&
                                  interview.metadata_list.some((metadata) => {
                                      // don't compare ids here since metadata values could be duplicated in the database
                                      // compare the actual string value instead
                                      const filterValues = compact(
                                          filter.values.map((val) =>
                                              metadataValues[filter.field].find(
                                                  (data) => data.id === val,
                                              ),
                                          ),
                                      ).map(({ data }) => data);
                                      return (
                                          metadata.interview_field.id ===
                                              filter.field &&
                                          filterValues.includes(metadata.data)
                                      );
                                  }),
                          ),
              )
        : allInterviews;
};
