import classNames from "classnames";
import { every, groupBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Select, { OptionProps, components } from "react-select";
import { v4 as uuidv4 } from "uuid";

import { saveQuestion } from "../../api";
import { Messages } from "../../apps/message-list";
import { useAppSelector } from "../../hooks";
import {
    Question,
    QuestionOption,
    QuestionType,
    SelectOption,
    WithUUID,
} from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import FormField from "../form-field";
import Icon from "../icon";
import Input from "../input";
import Modal from "../modal";
import Textarea from "../textarea";
import QuestionOptions from "./question-options";

type QuestionForm = Omit<Question, "options"> & {
    options: WithUUID<QuestionOption>[];
};

const emptyQuestion: QuestionForm = {
    id: null,
    text: "",
    subtext: "",
    ai_prompt_description: "",
    ordinal: -1,
    options: [],
    type: QuestionType.MULTI,
    group: null,
};

const questionToForm = (question: Question): QuestionForm => ({
    ...question,
    options: question.options.map((opt) => ({
        isDirty: false,
        uuid: uuidv4(),
        data: opt,
    })),
});
const formToQuestion = (form: QuestionForm): Question => ({
    ...form,
    options: form.options.map(({ data }) => data),
});

const questionTypeOptions = (singleSelectAvailable: boolean) => [
    { value: QuestionType.MULTI, label: "Multi select" },
    { value: QuestionType.LIST, label: "List" },
    {
        value: QuestionType.SINGLE,
        label: "Single select",
        isDisabled: !singleSelectAvailable,
    },
];

const questionTypeDescriptions: { [key in QuestionType]: string } = {
    [QuestionType.LIST]:
        "Able to select more than one option per interview. OpenAI will not suggest an option during analysis, will only provide supporting quotes.",
    [QuestionType.MULTI]:
        "Able to select more than one option per interview. OpenAI will suggest an option during analysis.",
    [QuestionType.SINGLE]: "Only one option per interview.",
};

const Option = ({
    children,
    className,
    ...props
}: OptionProps<SelectOption<QuestionType>>) => {
    return (
        <components.Option
            className={classNames(
                className,
                props.isDisabled && "hover:cursor-not-allowed",
            )}
            {...props}
        >
            {children}
            {!props.isDisabled && (
                <div className="text-gray-600 italic text-sm">
                    {questionTypeDescriptions[props.data.value]}
                </div>
            )}
            {props.isDisabled && (
                <div className="text-gray-300 italic text-sm">
                    Question already has responses with more than one option
                    selected
                </div>
            )}
        </components.Option>
    );
};

const EditQuestionModal = ({
    isOpen,
    onClose,
    onQuestionSaved,
    question,
}: {
    isOpen: boolean;
    question?: Question;
    onClose: () => void;
    onQuestionSaved: (question: Question) => void;
}) => {
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );
    const questionResponses = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );

    const responsesByInterviewId = useMemo(() => {
        const responseOptionIds = questionResponses.filter(
            (response) => response.question === question?.id,
        );
        return groupBy(responseOptionIds, (response) => response.interview);
    }, [question, questionResponses]);

    const singleSelectAvailable = useMemo(() => {
        return every(responsesByInterviewId, (responses) => {
            return responses.every((response) => response.options.length <= 1);
        });
    }, [responsesByInterviewId]);

    const [isSaving, setIsSaving] = useState(false);
    const [isEditingOptions, setIsEditingOptions] = useState(false);
    const [proposedQuestion, setProposedQuestion] = useState<QuestionForm>(
        question ? questionToForm(question) : emptyQuestion,
    );

    const questionGroupOptions = useMemo(
        () =>
            questionGroups.map((group) => ({
                label: group.title,
                value: group.id,
            })),
        [questionGroups],
    );

    const handleClose = useCallback(() => {
        setIsEditingOptions(false);
        onClose();
        setProposedQuestion(
            question ? questionToForm(question) : emptyQuestion,
        );
    }, [onClose, question]);

    const handleSaveQuestion = useCallback(async () => {
        setIsSaving(true);
        const response = await saveQuestion(
            project.id,
            formToQuestion(proposedQuestion),
        );
        if (response) {
            Messages.success("Question saved");
            onQuestionSaved(response.data);
            setProposedQuestion(questionToForm(response.data));
        }
        setIsSaving(false);
    }, [project, proposedQuestion, onQuestionSaved]);

    const content = useMemo(() => {
        return isEditingOptions ? (
            <QuestionOptions
                questionOptions={proposedQuestion.options}
                onUpdateQuestionOptions={(newOptions) =>
                    setProposedQuestion({
                        ...proposedQuestion,
                        options: newOptions,
                    })
                }
            />
        ) : (
            <div>
                <FormField label="Title">
                    <Input
                        autoFocus
                        className="question-title-input"
                        error={proposedQuestion.text.trim() === ""}
                        value={proposedQuestion.text}
                        onChange={(text) =>
                            setProposedQuestion({
                                ...proposedQuestion,
                                text,
                            })
                        }
                    />
                </FormField>
                <FormField className="mt-2" label="Description">
                    <Textarea
                        className="question-subtext-input resize-none h-24"
                        value={proposedQuestion.subtext}
                        onChange={(subtext) =>
                            setProposedQuestion({
                                ...proposedQuestion,
                                subtext,
                            })
                        }
                    />
                </FormField>
                <FormField className="mt-2" label="AI Description">
                    <Textarea
                        className="question-desc-input resize-none h-24"
                        value={proposedQuestion.ai_prompt_description}
                        onChange={(ai_prompt_description) =>
                            setProposedQuestion({
                                ...proposedQuestion,
                                ai_prompt_description,
                            })
                        }
                    />
                </FormField>
                <FormField className="mt-2" label="Question group">
                    <Select
                        {...defaultSelectProps}
                        className="question-group-select"
                        isClearable
                        isMulti={false}
                        options={questionGroupOptions}
                        placeholder="Ungrouped"
                        value={questionGroupOptions.find(
                            (opt) => opt.value === proposedQuestion.group,
                        )}
                        onChange={(opt) =>
                            setProposedQuestion({
                                ...proposedQuestion,
                                group: opt!.value,
                            })
                        }
                    />
                </FormField>
                <FormField className="mt-2" label="Question type">
                    <Select
                        {...defaultSelectProps}
                        className="question-type-select"
                        components={{ Option }}
                        isClearable={false}
                        isMulti={false}
                        options={questionTypeOptions(singleSelectAvailable)}
                        value={questionTypeOptions(singleSelectAvailable).find(
                            (t) => t.value === proposedQuestion.type,
                        )}
                        onChange={(opt) =>
                            setProposedQuestion({
                                ...proposedQuestion,
                                type: opt!.value,
                            })
                        }
                    />
                </FormField>
            </div>
        );
    }, [
        isEditingOptions,
        proposedQuestion,
        questionGroupOptions,
        singleSelectAvailable,
    ]);

    const footer = useMemo(() => {
        return isEditingOptions ? (
            <div className="flex w-full justify-end">
                <Button
                    className="no-bg flex items-center gap-2"
                    onClick={() => setIsEditingOptions(false)}
                >
                    <Icon className="h-5 w-5" icon="chevron_left" />
                    <span>Back to question details</span>
                </Button>
            </div>
        ) : (
            <div className="flex items-center justify-between">
                <Button className="no-bg" onClick={handleClose}>
                    Cancel
                </Button>
                <div className="flex items-center gap-4">
                    <Button
                        className="no-bg"
                        onClick={() => setIsEditingOptions(true)}
                    >
                        Manage options
                    </Button>
                    <Button
                        disabled={proposedQuestion.text.trim() === ""}
                        id="modal-confirm"
                        isLoading={isSaving}
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleSaveQuestion}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        );
    }, [
        handleSaveQuestion,
        isEditingOptions,
        isSaving,
        handleClose,
        proposedQuestion,
    ]);

    return (
        <Modal
            footer={footer}
            isConfirmLoading={isSaving}
            isOpen={isOpen}
            title="Edit question"
            onClose={handleClose}
        >
            {content}
        </Modal>
    );
};

export default EditQuestionModal;
