import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getHubspotIntegration } from "../api";
import Loader from "../components/loader";
import { HubspotIntegrationList } from "../types/goldpan";

const HubspotIntegration = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const [isLoading, setIsLoading] = useState(true);
    const [lists, setLists] = useState<HubspotIntegrationList[]>([]);

    useEffect(() => {
        getHubspotIntegration(projectId).then((response) => {
            if (!response) {
                return;
            }
            setLists(response.data);
            setIsLoading(false);
        });
    }, [projectId]);

    if (isLoading) {
        return (
            <div className="flex justify-center mt-16">
                <Loader large />
            </div>
        );
    }

    return (
        <>
            <header className="flex flex-col pb-5 border-b-4 border-black mb-8">
                <h1 className="text-5xl font-bold">Hubspot</h1>
            </header>
            <section className="bg-white shadow-md rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 border border-gray-50 rounded-sm">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b border-gray-50">
                        <tr>
                            <th className="px-6 py-3" scope="col">
                                ILS ID
                            </th>
                            <th className="px-6 py-3" scope="col">
                                Name
                            </th>
                            <th className="px-6 py-3" scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {lists.map((list) => (
                            <tr
                                className="bg-white border-b hover:bg-gray-50"
                                key={list.id}
                            >
                                <th
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                    scope="row"
                                >
                                    {list.id}
                                </th>
                                <td className="px-6 py-4">{list.name}</td>
                                <td className="px-6 py-4">
                                    <a
                                        href={`/project/${projectId}/integrations/hubspot/${list.id}/`}
                                    >
                                        Download CSV
                                    </a>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </>
    );
};

export default HubspotIntegration;
