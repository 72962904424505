import { useCallback, useMemo } from "react";
import Select, { OnChangeValue } from "react-select";

import { useAppSelector } from "../../hooks";
import { InterviewOutcome, SelectOption } from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";

type OutcomeSelectOption = SelectOption & { outcome: InterviewOutcome };

const OutcomeSelect = ({
    className,
    onChange,
    selected,
}: {
    className?: string;
    selected: number[];
    onChange: (value: number[]) => void;
}) => {
    const outcomes = useAppSelector((state) => state.project.outcomes);

    const options: OutcomeSelectOption[] = useMemo(
        () =>
            outcomes.map((opt) => ({
                value: opt.id,
                label: opt.name,
                outcome: opt,
            })),
        [outcomes],
    );

    const selectedOptions: OutcomeSelectOption[] = useMemo(() => {
        return selected
            .filter(
                (selectedOpt) =>
                    !!outcomes.find((outcome) => outcome.id === selectedOpt),
            )
            .map((selectedOpt) => {
                const outcome = assertNonNull(
                    outcomes.find((outcome) => outcome.id === selectedOpt),
                );
                return {
                    value: outcome.id,
                    label: outcome.name,
                    outcome,
                };
            });
    }, [outcomes, selected]);

    const handleChange = useCallback(
        (newValue: OnChangeValue<OutcomeSelectOption, true>) => {
            onChange(newValue.map((val) => val.value));
        },
        [onChange],
    );

    return (
        <Select
            {...defaultSelectProps}
            className={className}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
            options={options}
            value={selectedOptions}
            onChange={handleChange}
        />
    );
};

export default OutcomeSelect;
