import Select from "react-select";

import { ProjectForm, ProjectFormArrayTypes } from "../../apps/create-project";
import { Sentiment } from "../../types/goldpan";
import { capitalize, defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import Input from "../input";
import NextStepButton from "./next-step-button";

const CreateProjectInterviewsStep = ({
    addItemToProjectAttribute,
    onGoToNextStep,
    proposedProject,
    removeItemFromProjectAttribute,
    stepValidationErrors,
    updateProjectAttribute,
}: {
    proposedProject: ProjectForm;
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
    updateProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
        obj: ProjectFormArrayTypes,
    ) => void;
    removeItemFromProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
    ) => void;
    addItemToProjectAttribute: (
        attribute: keyof ProjectForm,
        obj?: ProjectFormArrayTypes,
    ) => void;
}) => (
    <>
        <div className="font-bold mb-2">Fields</div>
        {proposedProject.interview_fields.map((field, index) => (
            <div className="flex items-center gap-2" key={index}>
                <Input
                    className="interview-field"
                    placeholder="Field name"
                    type="text"
                    value={field}
                    onChange={(field) =>
                        updateProjectAttribute("interview_fields", index, field)
                    }
                />
                <Button
                    className="icon-only"
                    icon="close"
                    variant={ButtonVariant.DANGER}
                    onClick={() =>
                        removeItemFromProjectAttribute(
                            "interview_fields",
                            index,
                        )
                    }
                />
            </div>
        ))}
        <Button
            className="self-end"
            id="add-field-button"
            onClick={() => addItemToProjectAttribute("interview_fields")}
        >
            Add field
        </Button>

        <div className="font-bold mb-2">Outcomes</div>
        {proposedProject.outcomes.map((outcome, index) => (
            <div className="flex items-center gap-2" key={index}>
                <Input
                    className="interview-outcome-text w-2/3"
                    placeholder="Outcome"
                    type="text"
                    value={outcome.name}
                    onChange={(name) =>
                        updateProjectAttribute("outcomes", index, {
                            ...outcome,
                            name,
                        })
                    }
                />
                <Select
                    {...defaultSelectProps}
                    className="flex-1 interview-outcome-sentiment"
                    options={[
                        { label: "Neutral", value: Sentiment.NEUTRAL },
                        {
                            label: "Positive",
                            value: Sentiment.POSITIVE,
                        },
                        {
                            label: "Negative",
                            value: Sentiment.NEGATIVE,
                        },
                    ]}
                    value={{
                        label: capitalize(outcome.sentiment),
                        value: outcome.sentiment,
                    }}
                    onChange={(opt) =>
                        updateProjectAttribute("outcomes", index, {
                            ...outcome,
                            sentiment: opt!.value,
                        })
                    }
                />
                <Button
                    className="icon-only"
                    icon="close"
                    variant={ButtonVariant.DANGER}
                    onClick={() =>
                        removeItemFromProjectAttribute("outcomes", index)
                    }
                />
            </div>
        ))}
        <Button
            className="self-end"
            id="add-outcome-button"
            onClick={() =>
                addItemToProjectAttribute("outcomes", {
                    name: "",
                    sentiment: Sentiment.NEUTRAL,
                })
            }
        >
            Add outcome
        </Button>

        <NextStepButton
            stepValidationErrors={stepValidationErrors}
            onGoToNextStep={onGoToNextStep}
        />
    </>
);

export default CreateProjectInterviewsStep;
