import classNames from "classnames";
import { useState } from "react";

import Button, { ButtonVariant } from "../button";
import Modal from "../modal";

interface Props {
    saveClassName?: string;
    deleteClassName?: string;
    isSaved: boolean;
    isSaving: boolean;
    isDeleting: boolean;
    isDisabled?: boolean;
    onSave: () => void;
    onDelete: () => void;
}

const SaveDeleteButtons: React.FC<Props> = ({
    deleteClassName,
    isDeleting,
    isDisabled,
    isSaved,
    isSaving,
    onDelete,
    onSave,
    saveClassName,
}) => {
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false);
    return (
        <>
            <Modal
                isOpen={isConfirmingDelete}
                onClose={() => setIsConfirmingDelete(false)}
                onConfirm={() => {
                    onDelete();
                    setIsConfirmingDelete(false);
                }}
            >
                Are you sure?
            </Modal>
            <Button
                className={classNames("text-sm", saveClassName)}
                disabled={isSaved || isDisabled}
                icon={!isSaved ? "save" : "done"}
                isLoading={isSaving}
                variant={ButtonVariant.SUCCESS}
                onClick={onSave}
            >
                {!isSaved ? "Save" : "Saved"}
            </Button>
            <Button
                className={classNames("text-sm", deleteClassName)}
                disabled={isDisabled}
                icon="delete"
                isLoading={isDeleting}
                variant={ButtonVariant.DANGER}
                onClick={() => setIsConfirmingDelete(true)}
            >
                Delete
            </Button>
        </>
    );
};

export default SaveDeleteButtons;
