import { useCallback, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { deleteCompetitor, saveCompetitor } from "../../api";
import { Messages } from "../../apps/message-list";
import { useAppDispatch, useAppSelector, useConfirm } from "../../hooks";
import { setCompetitors } from "../../stores/project";
import { Competitor, WithUUID } from "../../types/goldpan";
import { assertNonNull } from "../../utils";
import Button, { ButtonVariant } from "../button";
import Input from "../input";

const ProjectCompetitors = () => {
    const dispatch = useAppDispatch();
    const competitors = useAppSelector((state) => state.project.competitors);
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );

    const [isSaving, setIsSaving] = useState(false);
    const [proposedCompetitors, setProposedCompetitors] = useState<
        WithUUID<Competitor>[]
    >(
        competitors.map((competitor) => ({
            isDirty: false,
            uuid: uuidv4(),
            data: competitor,
        })),
    );

    const { confirm, ConfirmationDialog } = useConfirm(
        "Are you sure you want to delete this competitor?",
    );

    const handleUpdateCompetitor = useCallback(
        (uuid: string, data: Partial<Competitor>) => {
            setProposedCompetitors(
                proposedCompetitors.map((competitor) => {
                    if (competitor.uuid === uuid) {
                        return {
                            ...competitor,
                            isDirty: true,
                            data: {
                                ...competitor.data,
                                ...data,
                            },
                        };
                    }
                    return competitor;
                }),
            );
        },
        [proposedCompetitors],
    );

    const handleAddNew = useCallback(() => {
        const newCompetitor: WithUUID<Competitor> = {
            isDirty: true,
            uuid: uuidv4(),
            data: { id: null, name: "New competitor" },
        };
        setProposedCompetitors([newCompetitor].concat(proposedCompetitors));
    }, [proposedCompetitors]);

    const handleSave = useCallback(
        async (uuid: string) => {
            const competitor = proposedCompetitors.find((c) => c.uuid === uuid);
            if (!competitor) {
                return;
            }
            setIsSaving(true);
            const response = await saveCompetitor(project.id, competitor.data);

            if (response) {
                const competitors = proposedCompetitors.map((c) => {
                    if (c.uuid === competitor.uuid) {
                        return {
                            ...c,
                            isDirty: false,
                            data: response.data,
                        };
                    }
                    return c;
                });
                dispatch(setCompetitors(competitors.map(({ data }) => data)));
                setProposedCompetitors(competitors);
                Messages.success("Competitors updated");
            }
            setIsSaving(false);
        },
        [dispatch, project, proposedCompetitors],
    );

    const handleDelete = useCallback(
        async (uuid: string) => {
            const competitor = proposedCompetitors.find((c) => c.uuid === uuid);
            if (!competitor) {
                return;
            }

            const confirmation = await confirm();
            if (confirmation) {
                setIsSaving(true);
                const response = await deleteCompetitor(
                    project.id,
                    competitor.data,
                );

                if (response) {
                    const competitors = proposedCompetitors.filter(
                        (c) => c.uuid !== uuid,
                    );
                    dispatch(
                        setCompetitors(competitors.map(({ data }) => data)),
                    );
                    setProposedCompetitors(competitors);
                    Messages.success("Competitor deleted");
                }
                setIsSaving(false);
            }
        },
        [confirm, dispatch, project, proposedCompetitors],
    );

    return (
        <div className="max-w-4xl flex flex-col gap-2">
            <ConfirmationDialog />
            <div className="mb-2 flex">
                <Button
                    className="add-new-competitor-btn"
                    onClick={handleAddNew}
                >
                    Add new
                </Button>
            </div>
            {proposedCompetitors.map((competitor) => (
                <div
                    className="card-slim flex items-center justify-between mb-2"
                    key={competitor.uuid}
                >
                    <div className="flex items-center">
                        <Input
                            className="competitor-input"
                            value={competitor.data.name}
                            onChange={(val) =>
                                handleUpdateCompetitor(competitor.uuid, {
                                    name: val,
                                })
                            }
                        />
                    </div>
                    <div className="flex items-center">
                        <Button
                            className="save-competitor-btn text-sm mr-1"
                            disabled={isSaving || !competitor.isDirty}
                            icon="save"
                            variant={ButtonVariant.SUCCESS}
                            onClick={() => handleSave(competitor.uuid)}
                        >
                            Save
                        </Button>
                        <Button
                            className="delete-competitor-btn text-sm"
                            disabled={isSaving}
                            icon="delete"
                            variant={ButtonVariant.DANGER}
                            onClick={() => handleDelete(competitor.uuid)}
                        >
                            Delete
                        </Button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProjectCompetitors;
