import { ProjectForm } from "../../apps/create-project";
import Input from "../input";
import NextStepButton from "./next-step-button";

const CreateProjectDetailsStep = ({
    onGoToNextStep,
    onProjectChange,
    proposedProject,
    stepValidationErrors,
}: {
    proposedProject: ProjectForm;
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
    onProjectChange: (project: ProjectForm) => void;
}) => (
    <>
        <Input
            id="project-title"
            maxLength={100}
            placeholder="Project title"
            type="text"
            value={proposedProject.title}
            onChange={(title) => onProjectChange({ ...proposedProject, title })}
        />
        <Input
            id="project-client-name"
            maxLength={100}
            placeholder="Client name"
            type="text"
            value={proposedProject.client_name}
            onChange={(client_name) =>
                onProjectChange({ ...proposedProject, client_name })
            }
        />
        <NextStepButton
            stepValidationErrors={stepValidationErrors}
            onGoToNextStep={onGoToNextStep}
        />
    </>
);

export default CreateProjectDetailsStep;
