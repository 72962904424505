import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
    checkCeleryTask,
    refineInterviewParagraphs,
    saveInterview,
} from "../../api";
import { Messages } from "../../apps/message-list";
import {
    AiParagraphChange,
    AiParagraphChangeType,
    ProposedTranscriptChange,
} from "../../apps/transcript-o-matic";
import Button, { ButtonPadding, ButtonVariant } from "../../components/button";
import Textarea from "../../components/textarea";
import { useAppDispatch, useAppSelector, useConfirm } from "../../hooks";
import { setInterviews } from "../../stores/project";
import {
    Interview,
    InterviewParagraph,
    InterviewStatus,
    TranscriptSpeaker,
} from "../../types/goldpan";
import { interviewParserUrl } from "../../urls";
import ProposedChange from "./proposed-change";

const TranscriptRefinement = ({
    interview,
    onUpdateInterviewParagraphs,
    projectId,
}: {
    interview: Interview;
    projectId: number;
    onUpdateInterviewParagraphs: (paragraphs: InterviewParagraph[]) => void;
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const allInterviews = useAppSelector((state) => state.project.interviews);
    const [proposedParagraphChanges, setProposedParagraphChanges] = useState<
        ProposedTranscriptChange[]
    >([]);
    const [isRefining, setIsRefining] = useState(false);
    const [isRefinmentChangesLoading, setIsRefinmentChangesLoading] =
        useState(false);
    const [isSavingInterview, setIsSavingInterview] = useState(false);
    const [refinementPrompt, setRefinementPrompt] = useState("");
    const {
        confirm: confirmRerunRefinement,
        ConfirmationDialog: RerunRefinementConfirmationDialog,
    } = useConfirm(
        "Running another refinement will overwrite any unapplied changes. Continue?",
    );
    const {
        confirm: confirmProceed,
        ConfirmationDialog: ProceedConfirmationDialog,
    } = useConfirm("You still have unapplied changes. Continue?");

    const handleRefineParagraphs = useCallback(async () => {
        if (proposedParagraphChanges.length > 0) {
            const confirmation = await confirmRerunRefinement();
            if (!confirmation) {
                return;
            }
        }

        setProposedParagraphChanges([]);
        setIsRefinmentChangesLoading(true);
        const response = await refineInterviewParagraphs(
            projectId,
            interview.id,
            { prompt: refinementPrompt },
        );
        if (response) {
            checkCeleryTask<AiParagraphChange[]>(
                response.data.task_id,
                (response) => {
                    setProposedParagraphChanges(
                        response.map((change) => ({
                            ...change,
                            uuid: uuidv4(),
                        })),
                    );
                    Messages.success("Refinement changes ready for review");
                },
                () => setIsRefinmentChangesLoading(false),
            );
        }
    }, [
        confirmRerunRefinement,
        interview,
        projectId,
        proposedParagraphChanges,
        refinementPrompt,
    ]);

    const handleProceed = useCallback(async () => {
        if (proposedParagraphChanges.length > 0) {
            const confirmation = await confirmProceed();
            if (!confirmation) {
                return;
            }
        }

        setIsSavingInterview(true);
        const response = await saveInterview(projectId, {
            ...interview,
            status: InterviewStatus.DRAFT,
        });
        setIsSavingInterview(false);
        if (!response) {
            return;
        }
        dispatch(setInterviews(allInterviews.concat(response.data)));
        navigate(interviewParserUrl(projectId, interview.id));
    }, [
        allInterviews,
        confirmProceed,
        dispatch,
        interview,
        navigate,
        projectId,
        proposedParagraphChanges,
    ]);

    const handleUpdateProposedChange = useCallback(
        (uuid: string, data: Partial<ProposedTranscriptChange>) => {
            setProposedParagraphChanges(
                proposedParagraphChanges.map((oldChange) => {
                    if (oldChange.uuid === uuid) {
                        if (
                            oldChange.change_type ===
                            AiParagraphChangeType.CHANGE
                        ) {
                            return {
                                ...oldChange,
                                ...data,
                                change_type: AiParagraphChangeType.CHANGE,
                            };
                        } else if (
                            oldChange.change_type ===
                            AiParagraphChangeType.REMOVE
                        ) {
                            return {
                                ...oldChange,
                                ...data,
                                change_type: AiParagraphChangeType.REMOVE,
                            };
                        }
                    }
                    return oldChange;
                }),
            );
        },
        [proposedParagraphChanges],
    );

    const handleDeleteProposedChange = useCallback(
        (uuid: string) => {
            setProposedParagraphChanges(
                proposedParagraphChanges.filter(
                    (change) => change.uuid !== uuid,
                ),
            );
        },
        [proposedParagraphChanges],
    );

    const handleApplyProposedChange = useCallback(
        (uuid: string) => {
            const change = proposedParagraphChanges.find(
                (change) => change.uuid === uuid,
            );
            if (!change) {
                return;
            }

            const newParagraphs = interview.paragraphs
                .filter(
                    (paragraph) =>
                        change.change_type !== AiParagraphChangeType.REMOVE ||
                        paragraph.ordinal !== change.paragraph_id,
                )
                .map((paragraph) => {
                    if (
                        paragraph.ordinal === change.paragraph_id &&
                        change.change_type === AiParagraphChangeType.CHANGE
                    ) {
                        return {
                            ...paragraph,
                            text: change.dialogue,
                            interviewer:
                                change.speaker === TranscriptSpeaker.GOLDPAN,
                        };
                    }
                    return paragraph;
                });
            setIsSavingInterview(true);
            onUpdateInterviewParagraphs(newParagraphs);
            handleDeleteProposedChange(uuid);
            setIsSavingInterview(false);
        },
        [
            handleDeleteProposedChange,
            interview.paragraphs,
            onUpdateInterviewParagraphs,
            proposedParagraphChanges,
        ],
    );

    return (
        <>
            <RerunRefinementConfirmationDialog />
            <ProceedConfirmationDialog />
            <div className="mb-4">
                {isRefining ? (
                    <div className="w-full max-w-[700px] flex items-center gap-4">
                        <Textarea
                            className="flex-grow resize-none h-24"
                            disabled={isRefinmentChangesLoading}
                            placeholder="Enter prompt..."
                            value={refinementPrompt}
                            onChange={setRefinementPrompt}
                        />
                        <div className="flex flex-col items-center justify-between self-stretch">
                            <Button
                                className="w-32"
                                disabled={
                                    isSavingInterview ||
                                    isRefinmentChangesLoading
                                }
                                onClick={() => setIsRefining(false)}
                            >
                                Done
                            </Button>
                            <Button
                                className="w-32"
                                disabled={
                                    refinementPrompt.trim() === "" ||
                                    isSavingInterview
                                }
                                isLoading={isRefinmentChangesLoading}
                                variant={ButtonVariant.PRIMARY}
                                onClick={handleRefineParagraphs}
                            >
                                Refine
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="flex items-center justify-end gap-4">
                        <Button
                            disabled={isSavingInterview}
                            onClick={() => setIsRefining(true)}
                        >
                            Begin refinement
                        </Button>
                        <Button
                            isLoading={isSavingInterview}
                            variant={ButtonVariant.PRIMARY}
                            onClick={handleProceed}
                        >
                            Proceed to Analysis
                        </Button>
                    </div>
                )}
            </div>
            <div className="mt-4 ai-transcript-height">
                {interview.paragraphs.map((paragraph) => {
                    const changes = proposedParagraphChanges.filter(
                        (c) => c.paragraph_id === paragraph.ordinal,
                    );

                    return (
                        <div
                            className="w-full flex flex-grow gap-4 mb-2"
                            key={paragraph.id}
                        >
                            <div className="w-1/2 flex flex-col">
                                <div className="p-4 rounded-md bg-white shadow-md relative">
                                    {changes.length === 0 && isRefining && (
                                        <>
                                            <Button
                                                className="absolute top-1 right-12 no-bg icon-only"
                                                disabled={
                                                    isRefinmentChangesLoading ||
                                                    isSavingInterview
                                                }
                                                icon="edit"
                                                padding={ButtonPadding.SLIM}
                                                tooltip="Manual edit"
                                                onClick={() =>
                                                    setProposedParagraphChanges(
                                                        proposedParagraphChanges.concat(
                                                            {
                                                                change_type:
                                                                    AiParagraphChangeType.CHANGE,
                                                                paragraph_id:
                                                                    paragraph.ordinal,
                                                                speaker:
                                                                    paragraph.interviewer
                                                                        ? TranscriptSpeaker.GOLDPAN
                                                                        : TranscriptSpeaker.INTERVIEWEE,
                                                                dialogue:
                                                                    paragraph.text,
                                                                isEditing: true,
                                                                uuid: uuidv4(),
                                                            },
                                                        ),
                                                    )
                                                }
                                            />
                                            <Button
                                                className="absolute top-1 right-1 no-bg icon-only"
                                                disabled={
                                                    isRefinmentChangesLoading ||
                                                    isSavingInterview
                                                }
                                                icon="delete"
                                                padding={ButtonPadding.SLIM}
                                                tooltip="Remove paragraph"
                                                variant={ButtonVariant.DANGER}
                                                onClick={() =>
                                                    setProposedParagraphChanges(
                                                        proposedParagraphChanges.concat(
                                                            {
                                                                change_type:
                                                                    AiParagraphChangeType.REMOVE,
                                                                paragraph_id:
                                                                    paragraph.ordinal,
                                                                uuid: uuidv4(),
                                                            },
                                                        ),
                                                    )
                                                }
                                            />
                                        </>
                                    )}
                                    <div className="font-bold">
                                        {paragraph.interviewer
                                            ? TranscriptSpeaker.GOLDPAN
                                            : TranscriptSpeaker.INTERVIEWEE}
                                        :
                                    </div>
                                    <div>{paragraph.text}</div>
                                </div>
                            </div>
                            <div className="w-1/2 flex flex-col">
                                {isRefining &&
                                    changes.map((change) => (
                                        <ProposedChange
                                            change={change}
                                            isDisabled={
                                                isRefinmentChangesLoading ||
                                                isSavingInterview
                                            }
                                            key={change.uuid}
                                            onApplyProposedChange={
                                                handleApplyProposedChange
                                            }
                                            onDeleteProposedChange={
                                                handleDeleteProposedChange
                                            }
                                            onUpdateProposedChange={
                                                handleUpdateProposedChange
                                            }
                                        />
                                    ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default TranscriptRefinement;
