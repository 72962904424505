import Tippy from "@tippyjs/react";
import { sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

import { mergeQuestionOptions, saveQuestion } from "../../api";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setQuestions, setQuotes } from "../../stores/project";
import { Question, QuestionOption } from "../../types/goldpan";
import { interviewUrl } from "../../urls";
import { assertNonNull, defaultSelectProps } from "../../utils";
import Button, { ButtonPadding, ButtonVariant } from "../button";
import QuestionSelector from "../data-cleanup/question-selector";
import Icon from "../icon";
import Input from "../input";
import Modal from "../modal";
import Textarea from "../textarea";

const EditQuestionOptions = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);

    const questions = useAppSelector((state) => state.project.questions);
    const interviews = useAppSelector((state) => state.project.interviews);
    const quotes = useAppSelector((state) => state.project.quotes);
    const dispatch = useAppDispatch();
    const [selectedQuestion, setSelectedQuestion] = useState<Question | null>(
        null,
    );
    const [proposedOptions, setProposedOptions] = useState<QuestionOption[]>(
        [],
    );
    const [editingOptionId, setEditingOptionId] = useState<number | null>(null);
    const [selectedOptionIds, setSelectedOptionIds] = useState<number[]>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [isMerging, setIsMerging] = useState(false);
    const [isConfirmingMerge, setIsConfirmingMerge] = useState(false);
    const [isConfirmingMergeAgain, setIsConfirmingMergeAgain] = useState(false);

    const handleChangeQuestion = useCallback(
        (questionId: number) => {
            const question = assertNonNull(
                questions.find((q) => q.id === questionId),
            );
            setSelectedQuestion(question);
        },
        [questions],
    );

    useEffect(() => {
        if (!selectedQuestion) {
            return;
        }
        setProposedOptions(selectedQuestion.options);
    }, [selectedQuestion]);

    const handleSaveOption = useCallback(async () => {
        if (!selectedQuestion) {
            return;
        }
        setIsSaving(true);
        const response = await saveQuestion(projectId, {
            ...selectedQuestion,
            options: proposedOptions,
        });
        setIsSaving(false);
        if (!response) {
            return;
        }
        setProposedOptions(response.data.options);
        setEditingOptionId(null);
    }, [projectId, proposedOptions, selectedQuestion]);

    const handleCancelEdit = useCallback(() => {
        if (!selectedQuestion) {
            return;
        }
        setProposedOptions(selectedQuestion.options);
        setEditingOptionId(null);
    }, [selectedQuestion]);

    const handleCloseMergeWindows = useCallback(() => {
        setIsConfirmingMerge(false);
        setIsConfirmingMergeAgain(false);
        setSelectedOptionIds([]);
    }, []);

    const handleMergeOptions = useCallback(async () => {
        if (!selectedQuestion || selectedOptionIds.length !== 2) {
            return;
        }

        setIsMerging(true);
        const questionResponse = await mergeQuestionOptions(
            projectId,
            assertNonNull(selectedQuestion.id),
            selectedOptionIds[0],
            selectedOptionIds[1],
        );
        if (!questionResponse) {
            setIsMerging(false);
            return;
        }

        dispatch(
            setQuotes(
                quotes.map((quote) => {
                    if (quote.question_option !== selectedOptionIds[0]) {
                        return quote;
                    }
                    return {
                        ...quote,
                        question_option: selectedOptionIds[1],
                    };
                }),
            ),
        );
        dispatch(
            setQuestions(
                questions.map((question) => {
                    if (question.id !== selectedQuestion.id) {
                        return question;
                    }
                    return questionResponse.data;
                }),
            ),
        );
        setSelectedQuestion(questionResponse.data);
        setProposedOptions(questionResponse.data.options);

        setIsMerging(false);
        handleCloseMergeWindows();
    }, [
        dispatch,
        projectId,
        questions,
        quotes,
        selectedOptionIds,
        selectedQuestion,
        handleCloseMergeWindows,
    ]);

    return (
        <>
            {isConfirmingMerge && (
                <Modal
                    isConfirmDisabled={selectedOptionIds.length < 2}
                    isOpen
                    onClose={handleCloseMergeWindows}
                    onConfirm={() => setIsConfirmingMergeAgain(true)}
                >
                    <div className="font-bold">Merge options:</div>
                    <div className="flex items-center">
                        <div className="flex-1">
                            {
                                proposedOptions.find(
                                    (opt) => opt.id === selectedOptionIds[0],
                                )?.text
                            }
                        </div>
                        <div className="p-6 text-xl">
                            <Icon icon="double_arrow" />
                        </div>
                        <div className="flex-1">
                            <Select
                                {...defaultSelectProps}
                                isMulti={false}
                                options={proposedOptions
                                    .filter(
                                        (opt) =>
                                            opt.id !== selectedOptionIds[0],
                                    )
                                    .map((opt) => ({
                                        value: assertNonNull(opt.id),
                                        label: opt.text,
                                    }))}
                                value={
                                    selectedOptionIds[1]
                                        ? proposedOptions
                                              .map((opt) => ({
                                                  value: assertNonNull(opt.id),
                                                  label: opt.text,
                                              }))
                                              .find(
                                                  (opt) =>
                                                      opt.value ===
                                                      selectedOptionIds[1],
                                              )
                                        : null
                                }
                                onChange={(opt) =>
                                    setSelectedOptionIds([
                                        selectedOptionIds[0],
                                        opt!.value,
                                    ])
                                }
                            />
                        </div>
                    </div>
                </Modal>
            )}
            {isConfirmingMergeAgain && (
                <Modal
                    confirmButtonVariant={ButtonVariant.DANGER}
                    isOpen
                    title="Are you sure?"
                    onClose={handleCloseMergeWindows}
                    onConfirm={handleMergeOptions}
                >
                    <p>
                        This will merge option{" "}
                        <span className="font-bold">
                            &quot;
                            {
                                proposedOptions.find(
                                    (opt) => opt.id === selectedOptionIds[0],
                                )?.text
                            }
                            &quot;
                        </span>{" "}
                        into{" "}
                        <span className="font-bold">
                            &quot;
                            {
                                proposedOptions.find(
                                    (opt) => opt.id === selectedOptionIds[1],
                                )?.text
                            }
                            &quot;
                        </span>
                        .
                    </p>
                    <p className="text-red-500 font-bold">
                        This action is not reversible
                    </p>
                </Modal>
            )}
            <div className="w-[500px]">
                <QuestionSelector
                    disabled={!!editingOptionId}
                    questions={questions}
                    selectedQuestionId={selectedQuestion?.id ?? null}
                    onChange={handleChangeQuestion}
                />
            </div>

            <div className="mt-4">
                {selectedQuestion &&
                    sortBy(proposedOptions, "id").map((option) => {
                        return (
                            <div
                                className="data-cleanup-options mb-8 p-6 h-96 rounded-md flex items-start gap-2 relative bg-gray-100"
                                key={option.id}
                            >
                                <Button
                                    className="absolute top-4 right-4 text-sm"
                                    disabled={isMerging || !!editingOptionId}
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={() => {
                                        setIsConfirmingMerge(true);
                                        setSelectedOptionIds([option.id!]);
                                    }}
                                >
                                    Merge into...
                                </Button>
                                <div>
                                    <div className="h-7 flex items-center gap-1">
                                        <label>Option</label>
                                        {editingOptionId === option.id && (
                                            <div className="flex items-center gap-1 ml-4">
                                                <Button
                                                    className="text-sm"
                                                    icon="save"
                                                    isLoading={isSaving}
                                                    padding={ButtonPadding.SLIM}
                                                    variant={
                                                        ButtonVariant.SUCCESS
                                                    }
                                                    onClick={handleSaveOption}
                                                />
                                                <Button
                                                    className="text-sm"
                                                    disabled={isSaving}
                                                    icon="close"
                                                    padding={ButtonPadding.SLIM}
                                                    variant={
                                                        ButtonVariant.DANGER
                                                    }
                                                    onClick={handleCancelEdit}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-2 w-96 flex gap-1 items-center">
                                        <Input
                                            className="w-full"
                                            disabled={
                                                !!editingOptionId &&
                                                editingOptionId !== option.id
                                            }
                                            value={option.text}
                                            onChange={(text) => {
                                                setProposedOptions(
                                                    proposedOptions.map(
                                                        (opt) => {
                                                            if (
                                                                opt.id !==
                                                                option.id
                                                            ) {
                                                                return opt;
                                                            }
                                                            return {
                                                                ...opt,
                                                                text,
                                                            };
                                                        },
                                                    ),
                                                );
                                            }}
                                            onFocus={() =>
                                                setEditingOptionId(option.id)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-col self-stretch">
                                    <div className="h-7 flex items-center gap-2">
                                        <label>AI description</label>
                                        {option.ai_prompt_description.trim() ===
                                            "" && (
                                            <Tippy content="AI description is missing">
                                                <div className="flex items-center text-amber-500">
                                                    <Icon
                                                        className="missing-ai-description"
                                                        icon="warning"
                                                    />
                                                </div>
                                            </Tippy>
                                        )}
                                        {editingOptionId === option.id && (
                                            <div className="flex items-center gap-1">
                                                <Button
                                                    className="save-ai-desc text-sm"
                                                    icon="save"
                                                    isLoading={isSaving}
                                                    padding={ButtonPadding.SLIM}
                                                    variant={
                                                        ButtonVariant.SUCCESS
                                                    }
                                                    onClick={handleSaveOption}
                                                />
                                                <Button
                                                    className="text-sm"
                                                    disabled={isSaving}
                                                    icon="close"
                                                    padding={ButtonPadding.SLIM}
                                                    variant={
                                                        ButtonVariant.DANGER
                                                    }
                                                    onClick={handleCancelEdit}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-2 w-96 flex-1">
                                        <Textarea
                                            className="ai-desc-input w-full h-full resize-none"
                                            disabled={
                                                !!editingOptionId &&
                                                editingOptionId !== option.id
                                            }
                                            value={option.ai_prompt_description}
                                            onChange={(
                                                ai_prompt_description,
                                            ) => {
                                                setProposedOptions(
                                                    proposedOptions.map(
                                                        (opt) => {
                                                            if (
                                                                opt.id !==
                                                                option.id
                                                            ) {
                                                                return opt;
                                                            }
                                                            return {
                                                                ...opt,
                                                                ai_prompt_description,
                                                            };
                                                        },
                                                    ),
                                                );
                                            }}
                                            onFocus={() =>
                                                setEditingOptionId(option.id)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="flex-1 flex flex-col gap-2 h-full">
                                    <label>Quotes</label>
                                    <div className="flex-1 overflow-scroll">
                                        {quotes
                                            .filter(
                                                (quote) =>
                                                    quote.question_option ===
                                                    option.id,
                                            )
                                            .sort(
                                                (quote1, quote2) =>
                                                    quote1.interview -
                                                    quote2.interview,
                                            )
                                            .map((quote) => {
                                                const interview = assertNonNull(
                                                    interviews.find(
                                                        (i) =>
                                                            i.id ===
                                                            quote.interview,
                                                    ),
                                                );
                                                return (
                                                    <div
                                                        className="data-cleanup-quotes border-l-4 p-2 border-gp-blue"
                                                        key={quote.id}
                                                    >
                                                        <Link
                                                            className="mb-1"
                                                            to={interviewUrl(
                                                                projectId,
                                                                interview.slug,
                                                            )}
                                                        >
                                                            {interview.code}
                                                        </Link>
                                                        <div>{quote.text}</div>
                                                    </div>
                                                );
                                            })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default EditQuestionOptions;
