import classNames from "classnames";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import { useCallback, useMemo, useState } from "react";

import { createProject } from "../api";
import Button, { ButtonVariant } from "../components/button";
import CreateProjectCompetitorsStep from "../components/create-project/competitors-step";
import CreateProjectDetailsStep from "../components/create-project/details-step";
import CreateProjectInterviewsStep from "../components/create-project/interviews-step";
import CreateProjectQuestionsStep from "../components/create-project/questions-step";
import CreateProjectThemesStep from "../components/create-project/themes-step";
import Icon from "../components/icon";
import { Sentiment } from "../types/goldpan";

enum CreateProjectStep {
    DETAILS = "Details",
    INTERVIEWS = "Interviews",
    COMPETITORS = "Competitors",
    THEMES = "Themes",
    QUESTIONS = "Questions",
    REVIEW = "Review",
}

const stepOrder = [
    CreateProjectStep.DETAILS,
    CreateProjectStep.INTERVIEWS,
    CreateProjectStep.COMPETITORS,
    CreateProjectStep.THEMES,
    CreateProjectStep.QUESTIONS,
    CreateProjectStep.REVIEW,
];

const validators: {
    [key in CreateProjectStep]: (proposedProject: ProjectForm) => string[];
} = {
    [CreateProjectStep.DETAILS]: (proposedProject) => {
        const errors = [];
        if (proposedProject.title.trim() === "") {
            errors.push("Title must not be blank");
        }
        if (proposedProject.client_name.trim() === "") {
            errors.push("Client name must not be blank");
        }
        return errors;
    },
    [CreateProjectStep.INTERVIEWS]: (proposedProject) => {
        const errors = [];
        if (
            proposedProject.interview_fields.some(
                (field) => field.trim() === "",
            )
        ) {
            errors.push("Field names must not be blank");
        }
        if (
            uniq(proposedProject.interview_fields).length !==
            proposedProject.interview_fields.length
        ) {
            errors.push("All fields must be uniquely named");
        }
        if (proposedProject.outcomes.length === 0) {
            errors.push("You need at least one outcome");
        }
        if (
            proposedProject.outcomes.some(
                (outcome) => outcome.name.trim() === "",
            )
        ) {
            errors.push("Outcome names must not be blank");
        }
        if (
            uniqBy(proposedProject.outcomes, "name").length !==
            proposedProject.outcomes.length
        ) {
            errors.push("All outcomes must be uniquely named");
        }
        return errors;
    },
    [CreateProjectStep.COMPETITORS]: (proposedProject) => {
        const errors = [];
        if (
            proposedProject.competitors.some(
                (competitor) => competitor.trim() === "",
            )
        ) {
            errors.push("Competitor names must not be blank");
        }
        if (
            uniq(proposedProject.competitors).length !==
            proposedProject.competitors.length
        ) {
            errors.push("All competitors must be uniquely named");
        }
        return errors;
    },
    [CreateProjectStep.THEMES]: (proposedProject) => {
        const errors = [];
        if (proposedProject.themes.length === 0) {
            errors.push("You need at least one theme");
        }
        if (
            proposedProject.themes.some((theme) => theme.subthemes.length === 0)
        ) {
            errors.push("You need at least one subtheme");
        }
        if (
            proposedProject.themes.some(
                (theme) =>
                    theme.name.trim() === "" ||
                    theme.subthemes.some(
                        (subtheme) => subtheme.name.trim() === "",
                    ),
            )
        ) {
            errors.push("Theme names must not be blank");
        }
        if (
            uniqBy(proposedProject.themes, "name").length !==
            proposedProject.themes.length
        ) {
            errors.push("All themes must be uniquely named");
        }
        if (
            proposedProject.themes.some(
                (theme) =>
                    uniqBy(theme.subthemes, "name").length !==
                    theme.subthemes.length,
            )
        ) {
            errors.push("All themes must be uniquely named");
        }
        return errors;
    },
    [CreateProjectStep.QUESTIONS]: (proposedProject) => {
        const errors = [];
        if (proposedProject.questions.length === 0) {
            errors.push("You need at least one question");
        }
        if (
            proposedProject.questions.some(
                (question) => question.options.length === 0,
            )
        ) {
            errors.push("You need at least one option");
        }
        if (
            proposedProject.questions.some(
                (question) =>
                    question.text.trim() === "" ||
                    question.options.some(
                        (option) => option.text.trim() === "",
                    ),
            )
        ) {
            errors.push("Question and options must not be blank");
        }
        if (
            uniqBy(proposedProject.questions, "text").length !==
            proposedProject.questions.length
        ) {
            errors.push("All questions must be uniquely named");
        }
        if (
            proposedProject.questions.some(
                (question) =>
                    uniqBy(question.options, "text").length !==
                    question.options.length,
            )
        ) {
            errors.push("All question options must be uniquely named");
        }
        return errors;
    },
    [CreateProjectStep.REVIEW]: () => {
        return [];
    },
};

const Steps = ({
    activeStep,
    onClick,
    proposedProject,
}: {
    proposedProject: ProjectForm;
    activeStep: CreateProjectStep;
    onClick: (stepIndex: CreateProjectStep) => void;
}) => {
    return (
        <ol className="mx-auto flex items-center w-full max-w-[1150px] text-sm font-medium text-center text-gray-500">
            {stepOrder.map((step, index) => (
                <li
                    className={classNames("flex items-center", {
                        "md:w-full after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10":
                            index < stepOrder.length - 1,
                        "text-gp-blue": activeStep === step,
                    })}
                    key={step}
                >
                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
                        <button
                            className="flex items-center px-2 py-1 rounded-md text-inherit hover:bg-gp-blue hover:text-white hover:no-underline"
                            onClick={() => onClick(step)}
                        >
                            {stepOrder.findIndex((s) => s === activeStep) >
                            index ? (
                                <Icon
                                    className={classNames("fill-current mr-2", {
                                        "text-red-500":
                                            validators[step](proposedProject)
                                                .length > 0,
                                    })}
                                    icon={
                                        validators[step](proposedProject)
                                            .length > 0
                                            ? "error"
                                            : "check_circle"
                                    }
                                />
                            ) : (
                                <span className="mr-2">{index + 1}.</span>
                            )}
                            {step}
                        </button>
                    </span>
                </li>
            ))}
        </ol>
    );
};

export type ProjectFormArrayTypes =
    | string
    | { name: string; sentiment: Sentiment }
    | { name: string; subthemes: { name: string; description: string }[] }
    | {
          text: string;
          subtext: string;
          description: string;
          options: { text: string; description: string }[];
      };

export type ProjectForm = {
    title: string;
    client_name: string;
    competitors: string[];
    interview_fields: string[];
    outcomes: {
        name: string;
        sentiment: Sentiment;
    }[];
    themes: {
        name: string;
        subthemes: { name: string; description: string }[];
    }[];
    questions: {
        text: string;
        subtext: string;
        description: string;
        options: { text: string; description: string }[];
    }[];
};

const CreateProjectApp = () => {
    const [isCreatingProject, setIsCreatingProject] = useState(false);
    const [activeStep, setActiveStep] = useState(CreateProjectStep.DETAILS);
    const [proposedProject, setProposedProject] = useState<ProjectForm>({
        title: "",
        client_name: "",
        competitors: [""],
        interview_fields: [""],
        outcomes: [{ name: "", sentiment: Sentiment.NEUTRAL }],
        themes: [{ name: "", subthemes: [{ name: "", description: "" }] }],
        questions: [
            {
                text: "",
                subtext: "",
                description: "",
                options: [{ text: "", description: "" }],
            },
        ],
    });

    const handleGoToNextStep = useCallback(() => {
        const currentIndex = stepOrder.findIndex((s) => s === activeStep);
        if (currentIndex < stepOrder.length - 1) {
            setActiveStep(stepOrder[currentIndex + 1]);
        }
    }, [activeStep]);

    const stepValidationErrors = useMemo(() => {
        return validators[activeStep](proposedProject);
    }, [activeStep, proposedProject]);
    const allValidationErrors = useMemo(() => {
        return stepOrder.reduce<string[]>(
            (errors, step) => errors.concat(validators[step](proposedProject)),
            [],
        );
    }, [proposedProject]);

    const updateFormAttributeAtIndex = useCallback(
        (
            attribute: keyof ProjectForm,
            index: number,
            obj: ProjectFormArrayTypes,
        ) => {
            setProposedProject({
                ...proposedProject,
                [attribute]: [
                    ...proposedProject[attribute].slice(0, index),
                    obj,
                    ...proposedProject[attribute].slice(index + 1),
                ],
            });
        },
        [proposedProject],
    );

    const addItemToFormAttribute = useCallback(
        (attribute: keyof ProjectForm, obj: ProjectFormArrayTypes = "") => {
            setProposedProject({
                ...proposedProject,
                [attribute]: [...proposedProject[attribute], obj],
            });
        },
        [proposedProject],
    );

    const removeItemFromFormAttribute = useCallback(
        (attribute: keyof ProjectForm, index: number) => {
            setProposedProject({
                ...proposedProject,
                [attribute]: [
                    ...proposedProject[attribute].slice(0, index),
                    ...proposedProject[attribute].slice(index + 1),
                ],
            });
        },
        [proposedProject],
    );

    const handleCreateProject = useCallback(() => {
        setIsCreatingProject(true);
        createProject(proposedProject)
            .then((project) => {
                if (project) {
                    window.location.href = `/project/${project.data.id}/`;
                }
            })
            .finally(() => setIsCreatingProject(false));
    }, [proposedProject]);

    let content = null;
    if (activeStep === CreateProjectStep.DETAILS) {
        content = (
            <CreateProjectDetailsStep
                proposedProject={proposedProject}
                stepValidationErrors={stepValidationErrors}
                onGoToNextStep={handleGoToNextStep}
                onProjectChange={setProposedProject}
            />
        );
    } else if (activeStep === CreateProjectStep.INTERVIEWS) {
        content = (
            <CreateProjectInterviewsStep
                addItemToProjectAttribute={addItemToFormAttribute}
                proposedProject={proposedProject}
                removeItemFromProjectAttribute={removeItemFromFormAttribute}
                stepValidationErrors={stepValidationErrors}
                updateProjectAttribute={updateFormAttributeAtIndex}
                onGoToNextStep={handleGoToNextStep}
            />
        );
    } else if (activeStep === CreateProjectStep.COMPETITORS) {
        content = (
            <CreateProjectCompetitorsStep
                addItemToProjectAttribute={addItemToFormAttribute}
                proposedProject={proposedProject}
                removeItemFromProjectAttribute={removeItemFromFormAttribute}
                stepValidationErrors={stepValidationErrors}
                updateProjectAttribute={updateFormAttributeAtIndex}
                onGoToNextStep={handleGoToNextStep}
            />
        );
    } else if (activeStep === CreateProjectStep.THEMES) {
        content = (
            <CreateProjectThemesStep
                addItemToProjectAttribute={addItemToFormAttribute}
                proposedProject={proposedProject}
                removeItemFromProjectAttribute={removeItemFromFormAttribute}
                stepValidationErrors={stepValidationErrors}
                updateProjectAttribute={updateFormAttributeAtIndex}
                onGoToNextStep={handleGoToNextStep}
            />
        );
    } else if (activeStep === CreateProjectStep.QUESTIONS) {
        content = (
            <CreateProjectQuestionsStep
                addItemToProjectAttribute={addItemToFormAttribute}
                proposedProject={proposedProject}
                removeItemFromProjectAttribute={removeItemFromFormAttribute}
                stepValidationErrors={stepValidationErrors}
                updateProjectAttribute={updateFormAttributeAtIndex}
                onGoToNextStep={handleGoToNextStep}
            />
        );
    } else if (activeStep === CreateProjectStep.REVIEW) {
        content = (
            <>
                <div className="flex flex-col gap-4 divide-y">
                    <div className="flex flex-col gap-2">
                        <div className="font-bold">Title:</div>
                        <div>{proposedProject.title}</div>
                    </div>
                    <div className="pt-4 flex flex-col gap-2">
                        <div className="font-bold">Client:</div>
                        <div>{proposedProject.client_name}</div>
                    </div>
                    <div className="pt-4 flex flex-col gap-2">
                        <div className="font-bold">Outcomes:</div>
                        {proposedProject.outcomes
                            .filter((outcome) => outcome.name.trim() !== "")
                            .map((outcome) => (
                                <div key={outcome.name}>
                                    {outcome.name} - {outcome.sentiment}
                                </div>
                            ))}
                    </div>
                    <div className="pt-4 flex flex-col gap-2">
                        <div className="font-bold">Competitors:</div>
                        {proposedProject.competitors.map((competitor) => (
                            <div key={competitor}>{competitor}</div>
                        ))}
                    </div>
                    <div className="pt-4 flex flex-col gap-2">
                        <div className="font-bold">Themes:</div>
                        {proposedProject.themes.map((theme) => (
                            <>
                                <div key={theme.name}>{theme.name}</div>
                                <div className="flex flex-col gap-1 ml-2">
                                    {theme.subthemes.map((subtheme) => (
                                        <div key={subtheme.name}>
                                            {subtheme.name}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                    <div className="pt-4 flex flex-col gap-2">
                        <div className="font-bold">Questions:</div>
                        {proposedProject.questions.map((question) => (
                            <>
                                <div key={question.text}>{question.text}</div>
                                <div className="flex flex-col gap-1 ml-2">
                                    {question.options.map((option) => (
                                        <div key={option.text}>
                                            {option.text}
                                        </div>
                                    ))}
                                </div>
                            </>
                        ))}
                    </div>
                </div>
                <Button
                    className="text-lg"
                    disabled={allValidationErrors.length > 0}
                    id="create-project-button"
                    isLoading={isCreatingProject}
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleCreateProject}
                >
                    Create
                </Button>
            </>
        );
    }

    return (
        <>
            <Steps
                activeStep={activeStep}
                proposedProject={proposedProject}
                onClick={(step) => setActiveStep(step)}
            />
            <div className="mt-6 mb-12 flex flex-col gap-4 w-[36rem] mx-auto">
                {content}
            </div>
        </>
    );
};

export default CreateProjectApp;
