import { PropsWithChildren } from "react";

interface Props extends Omit<React.HTMLProps<HTMLTextAreaElement>, "onChange"> {
    onChange?: (value: string) => void;
}

const Textarea: React.FC<PropsWithChildren<Props>> = ({
    children,
    onChange,
    ...rest
}) => {
    return (
        <textarea
            onChange={(evt) => onChange && onChange(evt.currentTarget.value)}
            {...rest}
        >
            {children}
        </textarea>
    );
};

export default Textarea;
