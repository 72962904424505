import Axios from "axios";
import { FormEvent, useCallback, useState } from "react";

import { getNextFromUrl, handleError, headers } from "../api";
import Button, { ButtonVariant } from "../components/button";
import Icon from "../components/icon";
import Input from "../components/input";
import { Messages } from "./message-list";

const Login: React.FC = () => {
    const nextUrl = getNextFromUrl();
    const [email, setEmail] = useState("");
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [loginComplete, setLoginComplete] = useState(false);

    const handleLogin = useCallback(
        (evt: FormEvent) => {
            evt.preventDefault();
            Messages.removeAll();
            setIsLoggingIn(true);
            Axios.post<void>(
                "/login/",
                { email, next_url: nextUrl },
                {
                    headers: headers(),
                },
            )
                .then(() => {
                    Messages.success("Check your email for your login token");
                    setLoginComplete(true);
                })
                .catch(handleError)
                .finally(() => setIsLoggingIn(false));
        },
        [email, nextUrl],
    );

    return (
        <div className="flex flex-col min-h-screen md:flex-row md:h-screen">
            <div className="m-auto p-12 md:w-1/2">
                <div className="md:w-2/3 m-auto">
                    <form onSubmit={handleLogin}>
                        <div className="relative w-full mb-2">
                            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <Icon className="text-gray-500" icon="mail" />
                            </div>
                            <Input
                                autoFocus
                                className="with-icon"
                                name="email"
                                placeholder="Email"
                                type="email"
                                value={email}
                                onChange={setEmail}
                            />
                        </div>

                        <Button
                            className="w-full"
                            disabled={!email || loginComplete}
                            id="submit-token-request"
                            isLoading={isLoggingIn}
                            type="submit"
                            variant={ButtonVariant.PRIMARY}
                        >
                            Request login token
                        </Button>
                    </form>
                </div>
            </div>
            <div className="flex-1 flex flex-col md:justify-center bg-gp-blue text-white p-12 md:w-1/2">
                <div>
                    <img
                        alt="Goldpan"
                        className="mb-2 max-w-[120px]"
                        src={`${window.STATIC_URL}images/logos/GoldpanLightLogo.svg`}
                    />
                </div>
                <h1 className="text-5xl font-bold mb-2">
                    Win-Loss Analysis and Buyer Research for Revenue Teams
                </h1>
                <p className="text-lg border-t-2 border-gp-teal pt-2">
                    We conduct and manage win-loss programs for tech companies
                    who care about the buying experience.
                </p>
            </div>
        </div>
    );
};

export default Login;
