import { debounce, entries, flatMap, groupBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Modal from "./components/modal";
import { Filter, FilterType } from "./filters";
import type { AppDispatch, RootState } from "./stores";
import { setFilters } from "./stores/project";

export const useCheckWhenLeaving = (condition: boolean) => {
    useEffect(() => {
        const onBeforeUnload = condition
            ? (event: BeforeUnloadEvent) => {
                  const message = "Sure you want to leave?";
                  event.preventDefault();
                  event.returnValue = message;
              }
            : undefined;
        if (onBeforeUnload) {
            window.addEventListener("beforeunload", onBeforeUnload);
        }
        return () => {
            if (onBeforeUnload) {
                window.removeEventListener("beforeunload", onBeforeUnload);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [condition]);
};

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDebounce = (callback: () => void, timeout = 1000) => {
    const ref = useRef<() => void>();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = () => {
            ref.current?.();
        };

        return debounce(func, timeout);
    }, [timeout]);

    return debouncedCallback;
};

/**
 * Store filters in the URL
 *
 * ?<filterType>=<id>,<id>...
 *
 * or in the case interview metadata (special since it could have more than one filter)
 *
 * ?InterviewMetadata=<fieldId>;<id>,<id>+<fieldId>;<id>,<id>...
 */
const metadataFieldDataSeparator = ";";
const metadataFieldSeparator = "+";
const valueSeparator = ",";
const parseValues = (values: string) =>
    values.split(valueSeparator).map((i) => parseInt(i, 10));

const filtersToURLParams = (filters: Filter[]) => {
    const params = new URLSearchParams();
    const groupedFilters = groupBy(filters, (filter) => filter.type);
    entries(groupedFilters).forEach(([type, filterList]) => {
        params.set(
            type,
            filterList
                .map(
                    (filter) =>
                        `${
                            filter.type === FilterType.InterviewMetadata
                                ? filter.field + metadataFieldDataSeparator
                                : ""
                        }${filter.values.join(valueSeparator)}`,
                )
                .join(metadataFieldSeparator),
        );
    });
    return params;
};

export const urlParamsToFilters = (searchParams: URLSearchParams) => {
    const filters: Filter[] = flatMap(
        [...searchParams.entries()],
        ([type, value]: [string, string]): Filter[] => {
            switch (type) {
                case FilterType.InterviewMetadata: {
                    const fields = value.split(metadataFieldSeparator);
                    return fields.map((f) => {
                        const s = f.split(metadataFieldDataSeparator);
                        return {
                            type,
                            field: parseInt(s[0], 10),
                            values: parseValues(s[1]),
                        };
                    });
                }
                case FilterType.Competitor:
                    return [
                        {
                            type,
                            values: parseValues(value),
                        },
                    ];
                case FilterType.Outcome:
                    return [
                        {
                            type,
                            values: parseValues(value),
                        },
                    ];
                default:
                    return [];
            }
        },
    );
    return filters;
};

export const useFilterParams = (): [Filter[], (filters: Filter[]) => void] => {
    const dispatch = useAppDispatch();
    const stateFilters = useAppSelector((state) => state.project.filters);
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        setSearchParams(filtersToURLParams(stateFilters));
    }, [setSearchParams, stateFilters]);

    const handleSetFilters = useCallback(
        (filters: Filter[]) => {
            dispatch(setFilters(filters));
        },
        [dispatch],
    );

    return [stateFilters, handleSetFilters];
};

export const useConfirm = (message: string) => {
    const [promise, setPromise] = useState<{
        resolve: (val: boolean | PromiseLike<boolean>) => void;
    } | null>(null);

    const confirm = () =>
        new Promise<boolean>((resolve) => {
            setPromise({ resolve });
        });

    const handleClose = () => {
        setPromise(null);
    };

    const handleConfirm = useCallback(() => {
        promise?.resolve(true);
        handleClose();
    }, [promise]);

    const handleCancel = useCallback(() => {
        promise?.resolve(false);
        handleClose();
    }, [promise]);

    const ConfirmationDialog = useMemo(
        // eslint-disable-next-line react/display-name
        () => () => (
            <Modal
                isOpen={promise !== null}
                onClose={handleCancel}
                onConfirm={handleConfirm}
            >
                {message}
            </Modal>
        ),
        [handleCancel, handleConfirm, message, promise],
    );

    return { ConfirmationDialog, confirm };
};
