import React, { useMemo } from "react";

import { Comparison, ComparisonType } from "../../comparisons";
import { Filter, FilterType, filterInterviews } from "../../filters";
import { useAppSelector } from "../../hooks";
import { Question, QuestionType } from "../../types/goldpan";
import SubCard from "../layout/sub-card";
import { OptionContentProps, getOptionData } from "./helpers";
import ListQuestionOptionContent from "./list-question-option-content";
import MultiQuestionOptionContent from "./multi-question-option-content";
import SingleSelectQuestionOptionContent from "./single-select-question-option-content";

const contentClass: { [key in QuestionType]: React.FC<OptionContentProps> } = {
    [QuestionType.LIST]: ListQuestionOptionContent,
    [QuestionType.MULTI]: MultiQuestionOptionContent,
    [QuestionType.SINGLE]: SingleSelectQuestionOptionContent,
};

const ProjectSummaryQuestion = ({
    activeComparison,
    onQuestionOptionClick,
    question,
    selectedFilters,
    tagCount,
}: {
    question: Question;
    activeComparison: Comparison[];
    selectedFilters: Filter[];
    tagCount: number;
    onQuestionOptionClick?: (questionOptionId: number) => void;
}) => {
    const questionResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );
    const projectQuestionSummaries = useAppSelector(
        (state) => state.project.projectQuestionSummaries,
    );
    const projectSummary = projectQuestionSummaries.find(
        (summary) => summary.question === question.id,
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters.filter(
                (filter) =>
                    // discard filters that use the same attribute as the comparisons so the comparisons aren't wonky
                    !activeComparison.find(
                        (comparison) =>
                            (comparison.type === ComparisonType.OUTCOMES &&
                                filter.type === FilterType.Outcome) ||
                            (comparison.type === ComparisonType.METADATA &&
                                filter.type === FilterType.InterviewMetadata &&
                                comparison.fieldId === filter.field),
                    ),
            ),
            interviewMetadataValues,
        );
    }, [
        interviews,
        selectedFilters,
        interviewMetadataValues,
        activeComparison,
    ]);

    const comparisonFilteredInterviews = useMemo(() => {
        return activeComparison.map((comparison) => {
            if (comparison.type === ComparisonType.OUTCOMES) {
                return filterInterviews(
                    filteredInterviews,
                    [
                        {
                            type: FilterType.Outcome,
                            values: [comparison.outcomeId],
                        },
                    ],
                    interviewMetadataValues,
                );
            }
            return filterInterviews(
                filteredInterviews,
                [
                    {
                        type: FilterType.InterviewMetadata,
                        field: comparison.fieldId,
                        values: [comparison.valueId],
                    },
                ],
                interviewMetadataValues,
            );
        });
    }, [activeComparison, filteredInterviews, interviewMetadataValues]);

    const comparisonData =
        activeComparison.length === 0
            ? [
                  getOptionData(
                      question,
                      filteredInterviews,
                      questionResponseSummaries,
                  ),
              ]
            : comparisonFilteredInterviews.map((interviews, index) =>
                  getOptionData(
                      question,
                      interviews,
                      questionResponseSummaries,
                      activeComparison[index],
                  ),
              );

    const optionContent = React.createElement(contentClass[question.type], {
        comparisonData,
        tagCount: tagCount,
        onQuestionOptionClick,
    });

    return (
        <SubCard key={question.id}>
            <div className="pb-4">
                <div className="font-bold pt-1 w-3/4 leading-5">
                    {question.text}
                </div>
                {question.subtext && (
                    <div className="text-xs w-3/4 pt-1">{question.subtext}</div>
                )}
            </div>

            {window.waffle.flag_is_active("project_question_summaries") &&
                projectSummary && (
                    <div className="mt-2 p-2 border rounded-md mb-2 bg-amber-100 border-amber-300 text-sm">
                        <div className="flex items-center text-amber-600">
                            <div>Summary</div>
                        </div>
                        <div>{projectSummary.summary}</div>
                    </div>
                )}

            {optionContent}
        </SubCard>
    );
};

export default ProjectSummaryQuestion;
