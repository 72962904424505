import classNames from "classnames";
import { PropsWithChildren } from "react";

const SubCard = ({
    children,
    className,
}: PropsWithChildren<{ className?: string }>) => {
    return (
        <div className={classNames("p-5 bg-gp-beige rounded-lg", className)}>
            {children}
        </div>
    );
};

export default SubCard;
