import { DraggableSyntheticListeners } from "@dnd-kit/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { QuestionOption, WithUUID } from "../../types/goldpan";
import Button, { ButtonVariant } from "../button";
import Icon from "../icon";
import Input from "../input";
import Textarea from "../textarea";

interface Props {
    dragOverlay?: boolean;
    listeners?: DraggableSyntheticListeners;
    style?: React.CSSProperties;
    questionOption: WithUUID<QuestionOption>;
    onUpdateQuestionOption: (
        newQuestionOption: WithUUID<QuestionOption>,
    ) => void;
    onRemoveQuestionOption: () => void;
}

const QuestionOptionItem = React.memo(
    React.forwardRef<HTMLDivElement, Props>(
        (
            {
                dragOverlay,
                listeners,
                onRemoveQuestionOption,
                onUpdateQuestionOption,
                questionOption,
                style,
            },
            ref,
        ) => {
            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = "grabbing";

                return () => {
                    document.body.style.cursor = "";
                };
            }, [dragOverlay]);

            const [isEditing, setIsEditing] = useState(false);
            const [proposedOption, setProposedOption] =
                useState(questionOption);

            const handleConfirm = useCallback(() => {
                onUpdateQuestionOption(proposedOption);
                setIsEditing(false);
            }, [onUpdateQuestionOption, proposedOption]);
            const handleReset = useCallback(() => {
                setProposedOption(questionOption);
                setIsEditing(false);
            }, [questionOption]);

            const canDelete = useMemo(
                () => !questionOption.data.has_responses,
                [questionOption.data.has_responses],
            );

            return (
                <div
                    className="border rounded-md shadow-md p-4 mb-2 last:mb-0 flex items-center justify-between"
                    ref={ref}
                    style={style}
                >
                    <div className="flex-1 mr-4">
                        {isEditing ? (
                            <div>
                                <Input
                                    error={
                                        proposedOption.data.text.trim() === ""
                                    }
                                    placeholder="Option"
                                    value={proposedOption.data.text}
                                    onChange={(text) =>
                                        setProposedOption({
                                            ...proposedOption,
                                            isDirty: true,
                                            data: {
                                                ...proposedOption.data,
                                                text,
                                            },
                                        })
                                    }
                                />
                                <Textarea
                                    className="resize-none mt-2"
                                    placeholder="AI Prompt Description"
                                    value={
                                        proposedOption.data
                                            .ai_prompt_description
                                    }
                                    onChange={(ai_prompt_description) =>
                                        setProposedOption({
                                            ...proposedOption,
                                            isDirty: true,
                                            data: {
                                                ...proposedOption.data,
                                                ai_prompt_description,
                                            },
                                        })
                                    }
                                />
                            </div>
                        ) : (
                            questionOption.data.text
                        )}
                    </div>
                    <div className="flex items-center justify-end gap-2">
                        {isEditing ? (
                            <>
                                <Button
                                    className="no-bg"
                                    icon="check"
                                    variant={ButtonVariant.SUCCESS}
                                    onClick={handleConfirm}
                                />
                                <Button
                                    className="no-bg"
                                    icon="close"
                                    variant={ButtonVariant.DANGER}
                                    onClick={handleReset}
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    className="no-bg"
                                    icon="edit"
                                    onClick={() => setIsEditing(true)}
                                />
                                <Button
                                    className="no-bg"
                                    disabled={!canDelete}
                                    icon="delete"
                                    tooltip={
                                        !canDelete
                                            ? "Option has been used in a response"
                                            : undefined
                                    }
                                    variant={ButtonVariant.DANGER}
                                    onClick={onRemoveQuestionOption}
                                />
                            </>
                        )}
                        <Icon
                            className="w-4 h-4 cursor-grabbing"
                            icon="handle"
                            {...listeners}
                        />
                    </div>
                </div>
            );
        },
    ),
);

export default QuestionOptionItem;
