import { DndContext } from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { QuestionOption, WithUUID } from "../../types/goldpan";
import Button from "../button";
import SortableQuestionOption from "./sortable-question-option";

const QuestionOptions = ({
    onUpdateQuestionOptions,
    questionOptions,
}: {
    questionOptions: WithUUID<QuestionOption>[];
    onUpdateQuestionOptions: (newOptions: WithUUID<QuestionOption>[]) => void;
}) => {
    const handleUpdateQuestionOption = useCallback(
        (newQuestionOption: WithUUID<QuestionOption>) => {
            onUpdateQuestionOptions(
                questionOptions.map((opt) => {
                    if (opt.uuid === newQuestionOption.uuid) {
                        return newQuestionOption;
                    }
                    return opt;
                }),
            );
        },
        [onUpdateQuestionOptions, questionOptions],
    );
    const handleAddQuestionOption = useCallback(() => {
        const newQuestionOption: WithUUID<QuestionOption> = {
            isDirty: true,
            uuid: uuidv4(),
            data: {
                id: null,
                text: "New option",
                ai_prompt_description: "",
            },
        };
        onUpdateQuestionOptions([newQuestionOption].concat(questionOptions));
    }, [onUpdateQuestionOptions, questionOptions]);
    const handleRemoveQuestionOption = useCallback(
        (uuid: string) => {
            onUpdateQuestionOptions(
                questionOptions.filter((opt) => opt.uuid !== uuid),
            );
        },
        [onUpdateQuestionOptions, questionOptions],
    );
    return (
        <>
            <div className="flex justify-end mb-2">
                <Button onClick={handleAddQuestionOption}>Add option</Button>
            </div>
            <DndContext
                onDragEnd={({ active, over }) => {
                    const activeOption = questionOptions.findIndex(
                        ({ uuid }) => uuid === active.id,
                    );
                    const overOption = questionOptions.findIndex(
                        ({ uuid }) => uuid === over?.id,
                    );
                    if (activeOption < 0 || overOption < 0) {
                        return;
                    }
                    onUpdateQuestionOptions(
                        arrayMove(questionOptions, activeOption, overOption),
                    );
                }}
            >
                <SortableContext
                    items={questionOptions.map((opt) => opt.uuid)}
                    strategy={verticalListSortingStrategy}
                >
                    {questionOptions.map((opt) => (
                        <SortableQuestionOption
                            id={opt.uuid}
                            key={opt.uuid}
                            questionOption={opt}
                            onRemoveQuestionOption={() =>
                                handleRemoveQuestionOption(opt.uuid)
                            }
                            onUpdateQuestionOption={handleUpdateQuestionOption}
                        />
                    ))}
                </SortableContext>
            </DndContext>
        </>
    );
};

export default QuestionOptions;
