import { ProjectForm, ProjectFormArrayTypes } from "../../apps/create-project";
import Button, { ButtonVariant } from "../button";
import Input from "../input";
import NextStepButton from "./next-step-button";

const CreateProjectCompetitorsStep = ({
    addItemToProjectAttribute,
    onGoToNextStep,
    proposedProject,
    removeItemFromProjectAttribute,
    stepValidationErrors,
    updateProjectAttribute,
}: {
    proposedProject: ProjectForm;
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
    updateProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
        obj: ProjectFormArrayTypes,
    ) => void;
    removeItemFromProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
    ) => void;
    addItemToProjectAttribute: (
        attribute: keyof ProjectForm,
        obj?: ProjectFormArrayTypes,
    ) => void;
}) => (
    <>
        {proposedProject.competitors.map((competitor, index) => (
            <div className="flex items-center gap-2" key={index}>
                <Input
                    className="competitor-field"
                    placeholder="Competitor"
                    type="text"
                    value={competitor}
                    onChange={(competitor) =>
                        updateProjectAttribute("competitors", index, competitor)
                    }
                />
                <Button
                    className="icon-only"
                    icon="close"
                    variant={ButtonVariant.DANGER}
                    onClick={() =>
                        removeItemFromProjectAttribute("competitors", index)
                    }
                />
            </div>
        ))}
        <Button
            className="self-end"
            id="add-competitor-button"
            onClick={() => addItemToProjectAttribute("competitors")}
        >
            Add competitor
        </Button>
        <NextStepButton
            stepValidationErrors={stepValidationErrors}
            onGoToNextStep={onGoToNextStep}
        />
    </>
);

export default CreateProjectCompetitorsStep;
