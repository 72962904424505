import classNames from "classnames";
import dayjs from "dayjs";
import { flatMap, sortBy, uniq } from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { saveInterview } from "../api";
import Button, { ButtonVariant } from "../components/button";
import DropdownMenu from "../components/dropdown-menu";
import QuestionGroupResponses from "../components/interview-summary/question-group-responses";
import QuoteSlim from "../components/interview-summary/quote-slim";
import Card from "../components/layout/card";
import CardTitle from "../components/layout/card-title";
import SubCard from "../components/layout/sub-card";
import QuoteDisplay from "../components/quote";
import { useAppDispatch, useAppSelector } from "../hooks";
import { generateInterviewPdf } from "../pdf";
import { PageWithHeader } from "../routers/project-router";
import { setInterviews } from "../stores/project";
import { InterviewStatus, Sentiment } from "../types/goldpan";
import { interviewParserUrl, questionGroupUrl } from "../urls";
import { assertNonNull, publishConfetti } from "../utils";
import { Messages } from "./message-list";

const InterviewSummary = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const { interviewSlug } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interview = useMemo(
        () =>
            assertNonNull(
                interviews.find(
                    (interview) => interview.slug === interviewSlug,
                ),
            ),
        [interviewSlug, interviews],
    );

    const subthemes = useAppSelector((state) => state.project.subthemes);
    const questions = useAppSelector((state) => state.project.questions);
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );
    const allQuotes = useAppSelector((state) => state.project.quotes);
    const allResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );
    const allDecisionReasons = useAppSelector(
        (state) => state.project.decisionReasons,
    );

    const [isPublishing, setIsPublishing] = useState(false);

    const interviewDecisionReasons = useMemo(
        () =>
            allDecisionReasons.filter(
                (decision) => decision.interview === interview?.id,
            ),
        [allDecisionReasons, interview],
    );
    const interviewQuotes = useMemo(
        () => allQuotes.filter((quote) => quote.interview === interview?.id),
        [allQuotes, interview],
    );
    const interviewResponseSummaries = useMemo(
        () =>
            allResponseSummaries.filter(
                (summary) => summary.interview === interview?.id,
            ),
        [allResponseSummaries, interview],
    );

    const linkedQuoteIds = useMemo(() => {
        const decisionReasonQuotes = flatMap(
            interviewDecisionReasons.map((decision) =>
                decision.quotes.map((quote) => quote.id!),
            ),
        );
        const responseSummaryQuotes = flatMap(
            interviewResponseSummaries.map((response) =>
                flatMap(
                    response.options.map((option) =>
                        option.quotes.map((quote) => quote.id!),
                    ),
                ),
            ),
        );
        return uniq([...decisionReasonQuotes, ...responseSummaryQuotes]);
    }, [interviewDecisionReasons, interviewResponseSummaries]);

    const handlePublishInterview = useCallback(() => {
        if (!interview || interview.status === InterviewStatus.PUBLISHED) {
            return;
        }

        setIsPublishing(true);
        saveInterview(projectId, {
            ...interview,
            status: InterviewStatus.PUBLISHED,
        })
            .then((response) => {
                if (response) {
                    dispatch(
                        setInterviews(
                            interviews.map((i) => {
                                if (i.id !== response.data.id) {
                                    return i;
                                }
                                return response.data;
                            }),
                        ),
                    );
                    publishConfetti();
                    Messages.success(
                        "Interview is now visible to all project members",
                    );
                }
            })
            .finally(() => setIsPublishing(false));
    }, [interview, projectId, dispatch, interviews]);

    const positiveQuotes = useMemo(
        () =>
            interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.POSITIVE &&
                    !linkedQuoteIds.includes(quote.id!),
            ),
        [interviewQuotes, linkedQuoteIds],
    );
    const negativeQuotes = useMemo(
        () =>
            interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.NEGATIVE &&
                    !linkedQuoteIds.includes(quote.id!),
            ),
        [interviewQuotes, linkedQuoteIds],
    );
    const neutralQuotes = useMemo(
        () =>
            interviewQuotes.filter(
                (quote) =>
                    quote.sentiment === Sentiment.NEUTRAL &&
                    !linkedQuoteIds.includes(quote.id!),
            ),
        [interviewQuotes, linkedQuoteIds],
    );

    const decisionsExist = useMemo(
        () =>
            subthemes.some((subtheme) => {
                const decisions = interviewDecisionReasons.filter(
                    (decision) => decision.subtheme === subtheme.id,
                );
                return decisions.length > 0;
            }),
        [interviewDecisionReasons, subthemes],
    );
    const questionResponsesExist = useMemo(
        () =>
            questions.some((question) => {
                const responses = interviewResponseSummaries.filter(
                    (response) => response.question === question.id,
                );
                return responses.length > 0;
            }),
        [interviewResponseSummaries, questions],
    );
    const quotesExist = useMemo(
        () =>
            positiveQuotes.length > 0 ||
            negativeQuotes.length > 0 ||
            neutralQuotes.length > 0,
        [negativeQuotes, neutralQuotes, positiveQuotes],
    );

    const handleDownloadPDF = useCallback(() => {
        if (!interview) {
            return;
        }
        generateInterviewPdf(
            interview,
            interviewMetadataFields,
            subthemes,
            decisionsExist ? interviewDecisionReasons : [],
            questions,
            questionResponsesExist ? interviewResponseSummaries : [],
            positiveQuotes,
            negativeQuotes,
            neutralQuotes,
        );
    }, [
        decisionsExist,
        interview,
        interviewDecisionReasons,
        interviewMetadataFields,
        interviewResponseSummaries,
        negativeQuotes,
        neutralQuotes,
        positiveQuotes,
        questionResponsesExist,
        questions,
        subthemes,
    ]);

    if (!interview) {
        return <div className="mt-8 text-center">Interview not found</div>;
    }

    const canPublish =
        interview.status === InterviewStatus.DRAFT &&
        window.goldpanUser?.permissions.includes("core.change_interview");
    const canUseInterviewParser = window.goldpanUser?.permissions.includes(
        "core.use_interview_ai_parser",
    );

    return (
        <PageWithHeader
            actions={
                canPublish || canUseInterviewParser ? (
                    <div className="flex items-center gap-2">
                        {window.waffle.flag_is_active("interview_pdf") && (
                            <Button icon="download" onClick={handleDownloadPDF}>
                                Download PDF
                            </Button>
                        )}
                        {canPublish && (
                            <Button
                                className="text-base"
                                isLoading={isPublishing}
                                variant={ButtonVariant.PRIMARY}
                                onClick={handlePublishInterview}
                            >
                                Publish
                            </Button>
                        )}
                        {canUseInterviewParser && (
                            <DropdownMenu
                                menuItems={[
                                    [
                                        {
                                            icon: "beaker",
                                            title: "Go to Interview Parser",
                                            onClick: () =>
                                                navigate(
                                                    interviewParserUrl(
                                                        projectId,
                                                        interview.id,
                                                    ),
                                                ),
                                        },
                                    ],
                                ]}
                                title="Actions"
                            />
                        )}
                    </div>
                ) : undefined
            }
            heading={`${
                interview.status === InterviewStatus.DRAFT ? "Draft: " : ""
            }Interview Summary`}
        >
            <Card>
                <div className="text-sm">
                    {dayjs(interview.date).format("MMM D, YYYY")}
                </div>
                <div className="flex flex-row gap-12 pb-6">
                    <CardTitle>{interview.name}</CardTitle>
                    {interview.outcome && (
                        <span
                            className={`rounded-full py-1 font-semibold px-3 truncate overflow-hidden
                                                                bg-gp-sentiment-${interview.outcome.sentiment}-light text-gp-sentiment-${interview.outcome.sentiment}

                                                        `}
                        >
                            {interview.outcome.name}
                        </span>
                    )}
                </div>
                <SubCard>
                    <div className="flex-1 grid grid-cols-1 lg:grid-cols-5 gap-4">
                        {interviewMetadataFields.map((field) => {
                            const data = interview.metadata_list.find(
                                (f) => f.interview_field.id === field.id,
                            );
                            return (
                                <div
                                    className="col-span-1 flex flex-col"
                                    key={field.id}
                                >
                                    <div className="font-semibold mb-0.5">
                                        {field.display_title}
                                    </div>
                                    <div className="text-gray-500">
                                        {data ? (
                                            <span>{data.data}</span>
                                        ) : (
                                            <span>(blank)</span>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </SubCard>
            </Card>

            {decisionsExist && (
                <>
                    <h2 className="my-8 text-3xl font-bold">
                        Decision reasons
                    </h2>
                    <div className="flex flex-col card">
                        {subthemes.map((subtheme) => {
                            const decisions = interviewDecisionReasons.filter(
                                (decision) => decision.subtheme === subtheme.id,
                            );
                            if (decisions.length === 0) {
                                return null;
                            }

                            return (
                                <div
                                    className="flex flex-col py-4 border-b first:pt-0 last:pb-0 last:border-none"
                                    key={subtheme.id}
                                >
                                    <div className="flex flex-row text-sm font-medium text-gray-500">
                                        <div className="text-gray-500">
                                            {subtheme.parent.name} &#8250;{" "}
                                            {subtheme.name}
                                        </div>
                                    </div>

                                    {decisions.map((reason) => {
                                        return (
                                            <div
                                                className="py-2 flex flex-col lg:flex-row justify-between gap-4"
                                                id={`decision_reason_${reason.id}`}
                                                key={reason.id}
                                            >
                                                <div className="w-full lg:w-2/5 text-lg font-semibold text-black whitespace-pre-line">
                                                    {reason.summary
                                                        ? reason.summary
                                                        : "No summary"}
                                                </div>

                                                <div className="flex flex-col gap-2 w-full lg:w-3/5">
                                                    {reason.quotes.map(
                                                        (quote) => (
                                                            <QuoteSlim
                                                                key={quote.id}
                                                                quote={quote}
                                                            />
                                                        ),
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </>
            )}

            {questionResponsesExist &&
                !window.waffle.flag_is_active("home-v2") && (
                    <>
                        <h2 className="my-8 text-3xl font-bold">
                            Buyer Journey
                        </h2>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                            {questions.map((question) => {
                                const responses =
                                    interviewResponseSummaries.filter(
                                        (response) =>
                                            response.question === question.id,
                                    );
                                if (responses.length === 0) {
                                    return null;
                                }
                                return (
                                    <Card
                                        className="flex flex-col w-full"
                                        key={question.id}
                                    >
                                        <div className="border-b-2 border-black mb-4">
                                            <h3 className="text-xl font-semibold text-gp-blue-dark">
                                                {question.text}
                                            </h3>
                                            {question.subtext && (
                                                <p className="mb-2 text-sm font-medium text-gp-blue-dark italic">
                                                    {question.subtext}
                                                </p>
                                            )}
                                        </div>
                                        <div className="flex flex-col">
                                            {responses.map((response) => {
                                                const sortedOptions = sortBy(
                                                    response.options,
                                                    (responseOption) => {
                                                        const selectedOption =
                                                            question.options.find(
                                                                (opt) => {
                                                                    return (
                                                                        opt.id ===
                                                                        responseOption.option
                                                                    );
                                                                },
                                                            );
                                                        return (
                                                            selectedOption?.ordinal ??
                                                            1
                                                        );
                                                    },
                                                );
                                                return (
                                                    <div
                                                        className="flex flex-col "
                                                        id={`question_response_${response.id}`}
                                                        key={response.id}
                                                    >
                                                        <div className="label-gray mb-2">
                                                            Summary
                                                        </div>
                                                        <div className="font-medium pb-4 border-b whitespace-pre-line">
                                                            {response.summary}
                                                        </div>
                                                        {sortedOptions.map(
                                                            (
                                                                responseOption,
                                                            ) => {
                                                                const option =
                                                                    question.options.find(
                                                                        (opt) =>
                                                                            opt.id ===
                                                                            responseOption.option,
                                                                    );
                                                                return (
                                                                    <div
                                                                        key={
                                                                            responseOption.id
                                                                        }
                                                                    >
                                                                        <div className="label-gray text-xs mt-2">
                                                                            Selected
                                                                            option
                                                                        </div>
                                                                        <div className="font-bold mb-2">
                                                                            {
                                                                                option?.text
                                                                            }
                                                                        </div>
                                                                        <div className="flex flex-col items-center">
                                                                            {responseOption.quotes.map(
                                                                                (
                                                                                    quote,
                                                                                ) => (
                                                                                    <QuoteSlim
                                                                                        key={
                                                                                            quote.id
                                                                                        }
                                                                                        quote={
                                                                                            quote
                                                                                        }
                                                                                    />
                                                                                ),
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Card>
                                );
                            })}
                        </div>
                    </>
                )}

            {questionResponsesExist &&
                window.waffle.flag_is_active("home-v2") && (
                    <>
                        <h2 className="my-8 text-3xl font-bold">
                            Buyer Journey
                        </h2>
                        <div className="flex flex-col gap-4">
                            {questionGroups.map((questionGroup) => {
                                const groupQuestions = questions.filter(
                                    ({ group }) => group === questionGroup.id,
                                );
                                const allResponses =
                                    interviewResponseSummaries.filter(
                                        (response) =>
                                            groupQuestions
                                                .map(({ id }) => id)
                                                .includes(response.question),
                                    );
                                if (
                                    groupQuestions.length === 0 ||
                                    allResponses.length === 0
                                ) {
                                    return null;
                                }

                                return (
                                    <QuestionGroupResponses
                                        detailLink={questionGroupUrl(
                                            projectId,
                                            questionGroup.slug,
                                        )}
                                        groupQuestions={groupQuestions}
                                        interviewResponseSummaries={
                                            interviewResponseSummaries
                                        }
                                        key={questionGroup.id}
                                        title={questionGroup.title}
                                    />
                                );
                            })}
                            {questions.filter(({ group }) => group === null)
                                .length > 0 && (
                                <QuestionGroupResponses
                                    groupQuestions={questions.filter(
                                        ({ group }) => group === null,
                                    )}
                                    interviewResponseSummaries={
                                        interviewResponseSummaries
                                    }
                                    title="Ungrouped"
                                />
                            )}
                        </div>
                    </>
                )}

            {quotesExist && (
                <>
                    <h2 className="my-8 text-3xl font-bold">Quotes</h2>
                    <div>
                        {(positiveQuotes.length > 0 ||
                            negativeQuotes.length > 0) && (
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                                <div className="flex flex-col card grid-span-1">
                                    <h3 className="text-xl font-semibold mb-2 border-b-2 text-green-800 border-green-800 pb-1">
                                        Positive
                                    </h3>

                                    {interviewQuotes
                                        .filter(
                                            (quote) =>
                                                quote.sentiment ===
                                                    Sentiment.POSITIVE &&
                                                !linkedQuoteIds.includes(
                                                    quote.id!,
                                                ),
                                        )
                                        .map((quote) => (
                                            <QuoteDisplay
                                                key={quote.id}
                                                quote={quote}
                                                transcriptLink
                                            />
                                        ))}
                                </div>
                                <div className="flex flex-col card grid-span-1">
                                    <h3 className="text-xl font-semibold mb-2 border-b-2 text-red-800 border-red-800 pb-1">
                                        Negative
                                    </h3>

                                    {interviewQuotes
                                        .filter(
                                            (quote) =>
                                                quote.sentiment ===
                                                    Sentiment.NEGATIVE &&
                                                !linkedQuoteIds.includes(
                                                    quote.id!,
                                                ),
                                        )
                                        .map((quote) => (
                                            <QuoteDisplay
                                                key={quote.id}
                                                quote={quote}
                                                transcriptLink
                                            />
                                        ))}
                                </div>
                            </div>
                        )}
                        {neutralQuotes.length > 0 && (
                            <div className="card mt-4">
                                <h3 className="text-xl font-semibold mb-2 border-b-2 border-black pb-1">
                                    Neutral
                                </h3>
                                <div className="columns-1 lg:columns-2 gap-4">
                                    {interviewQuotes
                                        .filter(
                                            (quote) =>
                                                quote.sentiment ===
                                                    Sentiment.NEUTRAL &&
                                                !linkedQuoteIds.includes(
                                                    quote.id!,
                                                ),
                                        )
                                        .map((quote) => (
                                            <div
                                                className="w-full inline-block break-inside-avoid mt-4"
                                                key={quote.id}
                                            >
                                                <QuoteDisplay
                                                    quote={quote}
                                                    transcriptLink
                                                />
                                            </div>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                </>
            )}

            <h2 className="my-8 text-3xl font-bold">Transcript</h2>
            <div className="flex flex-col card">
                {interview.paragraphs.map((paragraph) => {
                    const hasQuotes =
                        interviewQuotes.filter(
                            (quote) =>
                                quote.interview_paragraph === paragraph.id,
                        ).length > 0;
                    return (
                        <React.Fragment key={paragraph.id}>
                            <div className="text-xs text-gray-500 mt-2 mb-2">
                                {paragraph.interviewer
                                    ? "Goldpan"
                                    : "Interviewee"}
                            </div>
                            <div
                                className={classNames(
                                    "interview-paragraph w-full lg:w-1/2 mb-4 relative self-start",
                                    {
                                        "font-medium": paragraph.interviewer,
                                        "text-black": !paragraph.interviewer,
                                        "p-4 bg-blue-50 border-blue-900 border-l-4":
                                            hasQuotes,
                                    },
                                )}
                                id={`paragraph_${paragraph.id}`}
                            >
                                <div className="whitespace-pre-line">
                                    {paragraph.text}
                                </div>
                            </div>
                        </React.Fragment>
                    );
                })}
            </div>
        </PageWithHeader>
    );
};

export default InterviewSummary;
