import { ResponsivePie } from "@nivo/pie";
import dayjs from "dayjs";
import { reverse, sortBy, take } from "lodash";
import groupBy from "lodash/groupBy";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { Comparison } from "../comparisons";
import ComparisonSidebar from "../components/filters/comparison-sidebar";
import FilterSidebar from "../components/filters/filter-sidebar";
import InterviewPopover from "../components/interview-popover";
import Card from "../components/layout/card";
import SubCard from "../components/layout/sub-card";
import ProjectSummaryQuestionGroup from "../components/project-summary/project-summary-question-group";
import { useAppSelector, useFilterParams } from "../hooks";
import { Interview } from "../types/goldpan";
import { questionGroupUrl } from "../urls";
import { CHART_COLORS, assertNonNull } from "../utils";

const getInterviewMetadata = (interview: Interview, fieldId: number) => {
    const field = interview.metadata_list.find(
        (metadata) => metadata.interview_field.id === fieldId,
    );
    return field ? field.data : "-";
};

const ProjectSummaryAppV2 = () => {
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const questions = useAppSelector((state) => state.project.questions);
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );

    const [selectedFilters, setSelectedFilters] = useFilterParams();
    const [activeComparison, setSelectedComparison] = useState<Comparison[]>(
        [],
    );

    const mainInterviewField = useMemo(
        () =>
            project?.main_interview_field
                ? assertNonNull(
                      interviewMetadataFields.find(
                          (f) => f.id === project.main_interview_field,
                      ),
                  )
                : null,
        [project, interviewMetadataFields],
    );

    const chartBreakdownData: {
        value: number;
        color: string;
        label: string;
    }[] = useMemo(() => {
        const groupedInterviews = groupBy(interviews, (interview) => {
            return mainInterviewField
                ? getInterviewMetadata(interview, mainInterviewField.id)
                : interview.outcome
                ? interview.outcome.name
                : "empty";
        });
        const chartData = Object.keys(groupedInterviews).map((data) => {
            const interviews = groupedInterviews[data];
            return {
                id: data,
                label: data,
                value: interviews.length,
            };
        });
        return reverse(sortBy(chartData, ["value", "id"])).map(
            (item, index) => ({
                ...item,
                color: CHART_COLORS[index % CHART_COLORS.length],
            }),
        );
    }, [interviews, mainInterviewField]);

    if (interviews.length === 0) {
        return (
            <div className="mt-10 max-w-4xl mx-auto text-xl text-gray-400 text-center">
                Enter interview data to see insights
            </div>
        );
    }

    return (
        <>
            <div className="lg:ml-4 grid grid-cols-1 xl:grid-cols-3">
                <div className="col-span-1">
                    <div className="card relative" id="interview-details">
                        <div className="floating-card flex items-center justify-between bg-gp-blue px-6 h-16 rounded-lg shadow-md text-white">
                            <div className="font-bold text-4xl">
                                {interviews.length}
                            </div>
                            <div className="font-bold">Total interviews</div>
                        </div>
                        <div className="flex flex-col gap-2 divide-y mt-20">
                            {take(chartBreakdownData, 5).map((item) => (
                                <div
                                    className="flex pt-2 items-center justify-between"
                                    key={item.label}
                                >
                                    <div className="font-bold text-2xl text-center">
                                        {item.value}
                                    </div>
                                    <div>
                                        <div className="text-right">
                                            {item.label}
                                        </div>
                                        <div className="text-sm text-gray-500 uppercase text-right">
                                            {mainInterviewField
                                                ? mainInterviewField.display_title
                                                : "Outcome"}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="mt-4 xl:mt-0 xl:ml-12 card">
                    <div className="font-bold text-lg pb-2 border-b">
                        Interview outcome breakdown
                    </div>
                    <div className="h-[260px]">
                        <ResponsivePie
                            activeOuterRadiusOffset={8}
                            animate
                            borderWidth={1}
                            colors={{ datum: "data.color" }}
                            cornerRadius={3}
                            data={chartBreakdownData}
                            enableArcLabels={false}
                            enableArcLinkLabels={false}
                            endAngle={90}
                            innerRadius={0.5}
                            isInteractive
                            margin={{
                                top: 40,
                                right: 80,
                                bottom: 40,
                                left: 80,
                            }}
                            padAngle={1.5}
                            startAngle={-90}
                        />
                    </div>
                    <div className="flex flex-wrap items-center justify-center gap-4">
                        {chartBreakdownData.map((data) => (
                            <div
                                className="flex items-center gap-1"
                                key={data.label}
                            >
                                <div
                                    className="h-4 w-4 rounded"
                                    style={{ background: data.color }}
                                ></div>
                                <div>{`${data.label} (${data.value})`}</div>
                            </div>
                        ))}
                    </div>
                </div>
                <Card className="mt-4 xl:mt-0 xl:ml-4 flex flex-col">
                    <div className="flex items-center gap-2 mb-4 pb-2 border-b">
                        <div className="font-bold text-lg">
                            Recent interviews
                        </div>
                        <Link
                            className="text-sm"
                            to={`/project/${project.id}/interview/`}
                        >
                            See All -&gt;{" "}
                        </Link>
                    </div>
                    <div className="grid grid-cols-3 gap-2">
                        <div className="italic">Name</div>
                        <div className="italic">Date</div>
                        <div className="italic">
                            {mainInterviewField
                                ? mainInterviewField.display_title
                                : "Outcome"}
                        </div>
                    </div>
                    <div className="flex-1 flex flex-col gap-2">
                        {take(
                            reverse(
                                sortBy(
                                    interviews,
                                    (interview) => new Date(interview.date),
                                ),
                            ),
                            5,
                        ).map((interview) => (
                            <SubCard
                                className="grid grid-cols-3 gap-2 mt-2"
                                key={interview.id}
                            >
                                <InterviewPopover
                                    className="font-bold"
                                    interview={interview}
                                />
                                <div className="text-sm">
                                    {dayjs(interview.date).format(
                                        "MMM D, YYYY",
                                    )}
                                </div>
                                <div className="text-sm">
                                    {mainInterviewField
                                        ? getInterviewMetadata(
                                              interview,
                                              mainInterviewField.id,
                                          )
                                        : interview.outcome
                                        ? interview.outcome.name
                                        : "-"}
                                </div>
                            </SubCard>
                        ))}
                    </div>
                </Card>
            </div>

            <div className="mt-8 border-t py-8 lg:grid lg:grid-cols-[350px_1fr] gap-4">
                <div className="hidden lg:flex flex flex-col gap-2">
                    <FilterSidebar
                        activeComparison={activeComparison}
                        activeFilters={selectedFilters}
                        hideCompetitors
                        onChangeFilters={setSelectedFilters}
                    />
                    <ComparisonSidebar
                        activeComparison={activeComparison}
                        onChangeComparison={setSelectedComparison}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    {questionGroups.map((questionGroup) => {
                        const groupQuestions = questions.filter(
                            (q) => q.group === questionGroup.id,
                        );

                        return (
                            <ProjectSummaryQuestionGroup
                                activeComparison={activeComparison}
                                detailLink={questionGroupUrl(
                                    project.id,
                                    questionGroup.slug,
                                )}
                                key={questionGroup.id}
                                questions={groupQuestions}
                                selectedFilters={selectedFilters}
                                showAllTags={false}
                                title={questionGroup.title}
                            />
                        );
                    })}
                    {questions.filter((q) => q.group === null).length > 0 && (
                        <ProjectSummaryQuestionGroup
                            activeComparison={activeComparison}
                            questions={questions.filter(
                                (q) => q.group === null,
                            )}
                            selectedFilters={selectedFilters}
                            showAllTags={false}
                            title="Ungrouped"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default ProjectSummaryAppV2;
