import { ProjectForm, ProjectFormArrayTypes } from "../../apps/create-project";
import Button, { ButtonVariant } from "../button";
import Input from "../input";
import Textarea from "../textarea";
import NextStepButton from "./next-step-button";

const CreateProjectThemesStep = ({
    addItemToProjectAttribute,
    onGoToNextStep,
    proposedProject,
    removeItemFromProjectAttribute,
    stepValidationErrors,
    updateProjectAttribute,
}: {
    proposedProject: ProjectForm;
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
    updateProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
        obj: ProjectFormArrayTypes,
    ) => void;
    removeItemFromProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
    ) => void;
    addItemToProjectAttribute: (
        attribute: keyof ProjectForm,
        obj?: ProjectFormArrayTypes,
    ) => void;
}) => (
    <>
        {proposedProject.themes.map((theme, index) => (
            <div className="p-2 border-b flex flex-col gap-2" key={index}>
                <div className="flex items-center gap-2">
                    <Input
                        className="theme-field w-full"
                        placeholder="Theme"
                        type="text"
                        value={theme.name}
                        onChange={(name) =>
                            updateProjectAttribute("themes", index, {
                                ...theme,
                                name,
                            })
                        }
                    />
                    <Button
                        className="icon-only"
                        icon="close"
                        variant={ButtonVariant.DANGER}
                        onClick={() =>
                            removeItemFromProjectAttribute("themes", index)
                        }
                    />
                </div>
                <div className="flex flex-col gap-2 ml-6">
                    {theme.subthemes.map((subtheme, subthemeIndex) => (
                        <div
                            className="flex items-start gap-2"
                            key={subthemeIndex}
                        >
                            <div className="flex-1 flex flex-col gap-1">
                                <Input
                                    className="subtheme-field w-full"
                                    placeholder="Subtheme"
                                    type="text"
                                    value={subtheme.name}
                                    onChange={(name) =>
                                        updateProjectAttribute(
                                            "themes",
                                            index,
                                            {
                                                ...theme,
                                                subthemes: [
                                                    ...theme.subthemes.slice(
                                                        0,
                                                        subthemeIndex,
                                                    ),
                                                    { ...subtheme, name },
                                                    ...theme.subthemes.slice(
                                                        subthemeIndex + 1,
                                                    ),
                                                ],
                                            },
                                        )
                                    }
                                />
                                <Textarea
                                    className="w-full"
                                    placeholder="Description (for OpenAI)"
                                    value={subtheme.description}
                                    onChange={(description) =>
                                        updateProjectAttribute(
                                            "themes",
                                            index,
                                            {
                                                ...theme,
                                                subthemes: [
                                                    ...theme.subthemes.slice(
                                                        0,
                                                        subthemeIndex,
                                                    ),
                                                    {
                                                        ...subtheme,
                                                        description,
                                                    },
                                                    ...theme.subthemes.slice(
                                                        subthemeIndex + 1,
                                                    ),
                                                ],
                                            },
                                        )
                                    }
                                />
                            </div>
                            <Button
                                className="icon-only"
                                icon="close"
                                variant={ButtonVariant.DANGER}
                                onClick={() =>
                                    updateProjectAttribute("themes", index, {
                                        ...theme,
                                        subthemes: [
                                            ...theme.subthemes.slice(
                                                0,
                                                subthemeIndex,
                                            ),
                                            ...theme.subthemes.slice(
                                                subthemeIndex + 1,
                                            ),
                                        ],
                                    })
                                }
                            />
                        </div>
                    ))}
                    <Button
                        className="self-end"
                        onClick={() =>
                            updateProjectAttribute("themes", index, {
                                ...theme,
                                subthemes: [
                                    ...theme.subthemes,
                                    { name: "", description: "" },
                                ],
                            })
                        }
                    >
                        Add subtheme
                    </Button>
                </div>
            </div>
        ))}
        <Button
            className="self-start"
            onClick={() =>
                addItemToProjectAttribute("themes", {
                    name: "",
                    subthemes: [{ name: "", description: "" }],
                })
            }
        >
            Add theme
        </Button>

        <NextStepButton
            stepValidationErrors={stepValidationErrors}
            onGoToNextStep={onGoToNextStep}
        />
    </>
);

export default CreateProjectThemesStep;
