export const quoteExplorerUrl = (projectId: number) =>
    `/project/${projectId}/quotes/${window.location.search}`;
export const decisionsExplorerUrl = (projectId: number) =>
    `/project/${projectId}/decision-reasons/${window.location.search}`;
export const questionExplorerUrl = (projectId: number) =>
    `/project/${projectId}/questions/${window.location.search}`;

export const themePageUrl = (projectId: number, themeId: number) =>
    `/project/${projectId}/theme/${themeId}/${window.location.search}`;
export const questionPageUrl = (projectId: number, questionId: number) =>
    `/project/${projectId}/questions/${questionId}/${window.location.search}`;

export const interviewParserUrl = (projectId: number, interviewId: number) =>
    window.waffle.flag_is_active("transcript-o-matic")
        ? `/project/${projectId}/interview-parser/?interview=${interviewId}`
        : `/project/${projectId}/interview-parser/metadata/?interview=${interviewId}`;

export const interviewUrl = (projectId: number, interviewSlug: string) =>
    `/project/${projectId}/interview/${interviewSlug}/`;

export const questionGroupUrl = (
    projectId: number,
    questionGroupSlug: string,
) => `/project/${projectId}/insights/${questionGroupSlug}`;
