import { compact } from "lodash";
import { PropsWithChildren, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../hooks";
import { setInterviewId } from "../../stores/interview-parser";
import Steps from "../steps";

interface Props {
    activeStep: number;
}

const InterviewParserContainer: React.FC<PropsWithChildren<Props>> = ({
    activeStep,
    children,
}) => {
    const [searchParams] = useSearchParams();
    const interviewIdFromUrl = searchParams.get("interview");
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(
            setInterviewId(
                interviewIdFromUrl ? parseInt(interviewIdFromUrl) : null,
            ),
        );
    }, [interviewIdFromUrl, dispatch]);
    const interviewId = useAppSelector(
        (state) => state.interviewParser.interviewId,
    );
    const interviewPart = interviewId ? `?interview=${interviewId}` : "";
    const { projectId } = useParams();

    const steps = compact([
        !window.waffle.flag_is_active("transcript-o-matic") && {
            title: "Transcript",
            href: `/project/${projectId}/interview-parser/${interviewPart}`,
        },
        {
            title: "Metadata",
            href: window.waffle.flag_is_active("transcript-o-matic")
                ? `/project/${projectId}/interview-parser/${interviewPart}`
                : `/project/${projectId}/interview-parser/metadata/${interviewPart}`,
        },
        {
            title: "Decisions",
            href: `/project/${projectId}/interview-parser/decisions/${interviewPart}`,
        },
        {
            title: "Questions",
            href: `/project/${projectId}/interview-parser/questions/${interviewPart}`,
        },
        {
            title: "Quotes",
            href: `/project/${projectId}/interview-parser/quotes/${interviewPart}`,
        },
    ]);

    return (
        <>
            <div className="flex justify-center">
                <Steps
                    steps={steps.map((step, index) => ({
                        ...step,
                        complete: activeStep > index,
                        active: activeStep === index,
                    }))}
                />
            </div>
            {children}
        </>
    );
};

export default InterviewParserContainer;
