import { uniq, uniqBy } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import FilterSidebar from "../components/filters/filter-sidebar";
import QuoteDisplay from "../components/quote";
import { filterInterviews } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";
import { PageWithHeader } from "../routers/project-router";
import { getPercentage } from "../utils";

const ThemeExplorer = () => {
    const { themeId: urlThemeId } = useParams();
    const themeId = parseInt(urlThemeId!);

    const decisionReasons = useAppSelector(
        (state) => state.project.decisionReasons,
    );
    const subthemes = useAppSelector((state) => state.project.subthemes);
    const selectedTheme = useMemo(
        () =>
            uniqBy(
                subthemes.map((subtheme) => subtheme.parent),
                "id",
            ).find((theme) => theme.id === themeId),
        [subthemes, themeId],
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const outcomes = useAppSelector((state) => state.project.outcomes);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
    }, [interviews, selectedFilters, interviewMetadataValues]);

    const filteredDecisions = useMemo(
        () =>
            decisionReasons.filter(
                (decision) =>
                    filteredInterviews
                        .map((interview) => interview.id)
                        .includes(decision.interview) &&
                    subthemes.find(
                        (subtheme) => subtheme.id === decision.subtheme,
                    )?.parent.id === themeId,
            ),
        [decisionReasons, filteredInterviews, subthemes, themeId],
    );
    const filteredDecisionsInterviews = useMemo(
        () => uniq(filteredDecisions.map((decision) => decision.interview)),
        [filteredDecisions],
    );

    if (!selectedTheme) {
        return <div className="mt-8 text-center">Theme not found</div>;
    }

    return (
        <PageWithHeader heading={`Theme: ${selectedTheme.name}`}>
            <section className="grid grid-cols-[350px_1fr] gap-4">
                <FilterSidebar
                    activeFilters={selectedFilters}
                    hideCompetitors
                    onChangeFilters={setSelectedFilters}
                />
                <div className="flex flex-col gap-2">
                    <section className="mb-16 flex flex-col">
                        <div className="w-full flex flex-col card">
                            <div className="flex flex-row w-full">
                                <div className="flex flex-col flex-1 font-medium items-center">
                                    <div className="text-4xl font-bold">
                                        {getPercentage(
                                            filteredDecisionsInterviews.length,
                                            filteredInterviews.length,
                                        )}
                                        %
                                    </div>
                                    <div className="text-sm text-gray-700">
                                        {filteredDecisionsInterviews.length} of
                                        the {filteredInterviews.length}{" "}
                                        {filteredInterviews.length === 1
                                            ? "interview"
                                            : "interviews"}{" "}
                                        conducted
                                    </div>
                                </div>

                                {outcomes.map((outcome) => (
                                    <div
                                        className="flex flex-col flex-1 font-medium items-center"
                                        key={outcome.id}
                                    >
                                        <div className="text-3xl font-bold">
                                            {
                                                filteredInterviews
                                                    .filter((interview) =>
                                                        filteredDecisionsInterviews.includes(
                                                            interview.id,
                                                        ),
                                                    )
                                                    .filter(
                                                        (interview) =>
                                                            interview.outcome
                                                                ?.id ===
                                                            outcome.id,
                                                    ).length
                                            }
                                        </div>
                                        <div className="text-sm text-gray-700">
                                            {outcome.name} interviews
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className="mb-16 last:mb-0 flex flex-col">
                        <h2 className="flex items-center mb-8 text-3xl font-bold">
                            Decision Quotes
                        </h2>
                        <div className="flex flex-col card">
                            {subthemes
                                .filter(
                                    (subtheme) =>
                                        subtheme.parent.id === themeId,
                                )
                                .map((subtheme) => (
                                    <div
                                        className="py-4 border-b first:pt-0 last:border-none last:pb-0"
                                        key={subtheme.id}
                                    >
                                        <div className="font-medium">
                                            {`${subtheme.parent.name} > ${subtheme.name}`}{" "}
                                            Quotes
                                        </div>
                                        {filteredDecisions
                                            .filter(
                                                (decision) =>
                                                    decision.subtheme ===
                                                    subtheme.id,
                                            )
                                            .map((decision) =>
                                                decision.quotes.map((quote) => (
                                                    <div
                                                        className="mt-2 w-1/2 mx-auto"
                                                        key={quote.id}
                                                    >
                                                        <QuoteDisplay
                                                            quote={quote}
                                                        />
                                                    </div>
                                                )),
                                            )}
                                    </div>
                                ))}
                        </div>
                    </section>
                </div>
            </section>
        </PageWithHeader>
    );
};

export default ThemeExplorer;
