import { noop } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { checkCeleryTask, refineAudioTranscript } from "../../api";
import {
    TranscriptParagraph,
    TranscriptSpeaker,
    WithUUID,
} from "../../types/goldpan";
import Button, { ButtonPadding, ButtonVariant } from "../button";
import Checkbox from "../checkbox";
import Modal from "../modal";
import ScrollShadowWrapper from "../scroll-shadow-wrapper";
import Textarea from "../textarea";
import AudioFileUpload, { AudioFileUploadResult } from "./audio-file-upload";

const AudioTranscript = ({
    disabled,
    interviewAudioId,
    projectId,
    setInterviewAudioId,
    setIsUploadingFile,
    setJsonTranscript,
}: {
    disabled: boolean;
    projectId: number;
    interviewAudioId: number | null;
    setInterviewAudioId: (audioId: number) => void;
    setIsUploadingFile: (isUploading: boolean) => void;
    isSubmittingRawTranscript: boolean;
    setJsonTranscript: (data: WithUUID<TranscriptParagraph>[]) => void;
}) => {
    const [audioTranscriptHistory, setAudioTranscriptHistory] = useState<
        string[]
    >([]);
    const [audioTranscriptIndex, setAudioTranscriptIndex] = useState(-1);
    const [goldpanSpeakers, setGoldpanSpeakers] = useState(["0"]);
    const [isSpeakerModalOpen, setIsSpeakerModalOpen] = useState(false);
    const [refinementPrompt, setRefinementPrompt] = useState("");
    const [isRefiningTranscript, setIsRefiningTranscript] = useState(false);

    const audioTranscript = useMemo(() => {
        if (audioTranscriptHistory.length === 0) {
            return null;
        }
        return audioTranscriptHistory[audioTranscriptIndex];
    }, [audioTranscriptHistory, audioTranscriptIndex]);
    const addNewAudioTranscript = useCallback(
        (transcript: string) => {
            setAudioTranscriptHistory([
                ...audioTranscriptHistory.slice(0, audioTranscriptIndex + 1),
                transcript,
            ]);
            setAudioTranscriptIndex(audioTranscriptIndex + 1);
        },
        [audioTranscriptHistory, audioTranscriptIndex],
    );
    const goBackInTranscriptHistory = useCallback(() => {
        if (audioTranscriptIndex > 0) {
            setAudioTranscriptIndex(audioTranscriptIndex - 1);
        }
    }, [audioTranscriptIndex]);
    const goForwardInTranscriptHistory = useCallback(() => {
        if (audioTranscriptIndex < audioTranscriptHistory.length - 1) {
            setAudioTranscriptIndex(audioTranscriptIndex + 1);
        }
    }, [audioTranscriptHistory.length, audioTranscriptIndex]);

    const handleFileUploadStarted = useCallback(
        () => setIsUploadingFile(true),
        [setIsUploadingFile],
    );
    const handleFileUploadFailure = useCallback(
        () => setIsUploadingFile(false),
        [setIsUploadingFile],
    );
    const handleFileUploadSuccess = useCallback(
        async (response: AudioFileUploadResult) => {
            setInterviewAudioId(response.file_id);
            setIsUploadingFile(false);

            checkCeleryTask<string>(
                response.task_id,
                (response) => addNewAudioTranscript(response),
                noop,
            );
        },
        [addNewAudioTranscript, setInterviewAudioId, setIsUploadingFile],
    );

    const handleConfirmTranscript = useCallback(() => {
        if (!audioTranscript) {
            return;
        }

        const paragraphs = audioTranscript?.split("\n\n");
        setJsonTranscript(
            paragraphs.map((paragraph) => {
                const speaker: TranscriptSpeaker = /^Speaker 0:/.test(paragraph)
                    ? TranscriptSpeaker.GOLDPAN
                    : TranscriptSpeaker.INTERVIEWEE;
                return {
                    data: {
                        speaker,
                        dialogue: paragraph
                            .replace(/^Speaker \d+:/g, "")
                            .trim(),
                    },
                    isDirty: false,
                    uuid: uuidv4(),
                };
            }),
        );
    }, [audioTranscript, setJsonTranscript]);

    const handleRefineTranscript = useCallback(async () => {
        if (!audioTranscript || refinementPrompt.trim() === "") {
            return;
        }
        setIsRefiningTranscript(true);
        const response = await refineAudioTranscript(projectId, {
            transcript: audioTranscript,
            prompt: refinementPrompt,
        });
        if (response) {
            checkCeleryTask<string>(
                response.data.task_id,
                (response) => addNewAudioTranscript(response),
                () => setIsRefiningTranscript(false),
            );
        } else {
            setIsRefiningTranscript(false);
        }
    }, [addNewAudioTranscript, audioTranscript, projectId, refinementPrompt]);

    const speakerOptions = useMemo(() => {
        if (!audioTranscript) {
            return [];
        }
        const matches = audioTranscript.matchAll(/Speaker (\d+):/g);
        const options = [];
        for (const match of matches) {
            options.push(match[1]);
        }
        return options;
    }, [audioTranscript]);

    if (!interviewAudioId || !audioTranscript) {
        return (
            <AudioFileUpload
                onUploadFailure={handleFileUploadFailure}
                onUploadStarted={handleFileUploadStarted}
                onUploadSuccess={handleFileUploadSuccess}
            />
        );
    }

    return (
        <>
            <Modal
                footer={
                    <div className="flex justify-end">
                        <Button
                            className="mr-4"
                            onClick={() => setIsSpeakerModalOpen(false)}
                        >
                            Done
                        </Button>
                    </div>
                }
                isOpen={isSpeakerModalOpen}
            >
                <div className="flex flex-col">
                    <div className="font-bold">Goldpan speaker is:</div>
                    {speakerOptions.map((opt) => (
                        <Checkbox
                            checked={goldpanSpeakers.includes(opt)}
                            className="mt-1"
                            key={opt}
                            label={`Speaker ${opt}`}
                            onChange={(checked) => {
                                if (checked) {
                                    setGoldpanSpeakers([
                                        ...goldpanSpeakers,
                                        opt,
                                    ]);
                                } else {
                                    setGoldpanSpeakers(
                                        goldpanSpeakers.filter(
                                            (speaker) => speaker !== opt,
                                        ),
                                    );
                                }
                            }}
                        />
                    ))}
                </div>
            </Modal>

            {!disabled && (
                <div className="flex items-center gap-2 justify-end font-bold">
                    <div>History #{audioTranscriptIndex}</div>
                    <Button
                        className="no-bg icon-only"
                        disabled={
                            isRefiningTranscript || audioTranscriptIndex === 0
                        }
                        icon="chevron_left"
                        padding={ButtonPadding.SLIM}
                        onClick={goBackInTranscriptHistory}
                    />
                    <Button
                        className="no-bg icon-only"
                        disabled={
                            isRefiningTranscript ||
                            audioTranscriptIndex ===
                                audioTranscriptHistory.length - 1
                        }
                        icon="chevron_right"
                        padding={ButtonPadding.SLIM}
                        onClick={goForwardInTranscriptHistory}
                    />
                </div>
            )}

            <ScrollShadowWrapper className="whitespace-pre-line">
                <div>{audioTranscript}</div>
            </ScrollShadowWrapper>

            {!disabled && (
                <>
                    <div className="mt-1 text-sm flex items-center gap-1">
                        <div>
                            Goldpan is:{" "}
                            {goldpanSpeakers
                                .map((speaker) => `Speaker ${speaker}`)
                                .join(", ")}
                        </div>
                        <Button
                            className="no-bg icon-only"
                            disabled={isRefiningTranscript}
                            icon="edit"
                            padding={ButtonPadding.SLIM}
                            onClick={() => setIsSpeakerModalOpen(true)}
                        />
                    </div>
                    <Textarea
                        className="resize-none"
                        disabled={isRefiningTranscript}
                        onChange={(prompt) => setRefinementPrompt(prompt)}
                    />
                    <div className="flex items-center justify-between gap-2 mt-1 text-sm">
                        <Button
                            isLoading={isRefiningTranscript}
                            variant={ButtonVariant.PRIMARY}
                            onClick={handleRefineTranscript}
                        >
                            Refine transcript
                        </Button>
                        <Button
                            disabled={isRefiningTranscript}
                            variant={ButtonVariant.PRIMARY}
                            onClick={handleConfirmTranscript}
                        >
                            Looks good!
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default AudioTranscript;
