import { ProjectForm, ProjectFormArrayTypes } from "../../apps/create-project";
import Button, { ButtonVariant } from "../button";
import Input from "../input";
import Textarea from "../textarea";
import NextStepButton from "./next-step-button";

const CreateProjectQuestionsStep = ({
    addItemToProjectAttribute,
    onGoToNextStep,
    proposedProject,
    removeItemFromProjectAttribute,
    stepValidationErrors,
    updateProjectAttribute,
}: {
    proposedProject: ProjectForm;
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
    updateProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
        obj: ProjectFormArrayTypes,
    ) => void;
    removeItemFromProjectAttribute: (
        attribute: keyof ProjectForm,
        index: number,
    ) => void;
    addItemToProjectAttribute: (
        attribute: keyof ProjectForm,
        obj?: ProjectFormArrayTypes,
    ) => void;
}) => (
    <>
        {proposedProject.questions.map((question, index) => (
            <div className="p-2 border-b flex flex-col gap-2" key={index}>
                <div className="flex items-start gap-2">
                    <div className="flex-1 flex flex-col gap-1">
                        <Input
                            className="question-field w-full"
                            placeholder="Question"
                            type="text"
                            value={question.text}
                            onChange={(text) =>
                                updateProjectAttribute("questions", index, {
                                    ...question,
                                    text,
                                })
                            }
                        />
                        <Input
                            className="w-full"
                            placeholder="Subtext"
                            type="text"
                            value={question.subtext}
                            onChange={(subtext) =>
                                updateProjectAttribute("questions", index, {
                                    ...question,
                                    subtext,
                                })
                            }
                        />
                        <Textarea
                            className="w-full"
                            placeholder="Description (for OpenAI)"
                            value={question.description}
                            onChange={(description) =>
                                updateProjectAttribute("questions", index, {
                                    ...question,
                                    description,
                                })
                            }
                        />
                    </div>
                    <Button
                        className="icon-only"
                        icon="close"
                        variant={ButtonVariant.DANGER}
                        onClick={() =>
                            removeItemFromProjectAttribute("questions", index)
                        }
                    />
                </div>
                <div className="flex flex-col gap-2 ml-6">
                    {question.options.map((option, optionIndex) => (
                        <div
                            className="flex items-start gap-2"
                            key={optionIndex}
                        >
                            <div className="flex-1 flex flex-col gap-1">
                                <Input
                                    className="option-field w-full"
                                    placeholder="Option"
                                    type="text"
                                    value={option.text}
                                    onChange={(text) =>
                                        updateProjectAttribute(
                                            "questions",
                                            index,
                                            {
                                                ...question,
                                                options: [
                                                    ...question.options.slice(
                                                        0,
                                                        optionIndex,
                                                    ),
                                                    { ...option, text },
                                                    ...question.options.slice(
                                                        optionIndex + 1,
                                                    ),
                                                ],
                                            },
                                        )
                                    }
                                />
                                <Textarea
                                    className="w-full"
                                    placeholder="Description (for OpenAI)"
                                    value={option.description}
                                    onChange={(description) =>
                                        updateProjectAttribute(
                                            "questions",
                                            index,
                                            {
                                                ...question,
                                                options: [
                                                    ...question.options.slice(
                                                        0,
                                                        optionIndex,
                                                    ),
                                                    { ...option, description },
                                                    ...question.options.slice(
                                                        optionIndex + 1,
                                                    ),
                                                ],
                                            },
                                        )
                                    }
                                />
                            </div>
                            <Button
                                className="icon-only"
                                icon="close"
                                variant={ButtonVariant.DANGER}
                                onClick={() =>
                                    updateProjectAttribute("questions", index, {
                                        ...question,
                                        options: [
                                            ...question.options.slice(
                                                0,
                                                optionIndex,
                                            ),
                                            ...question.options.slice(
                                                optionIndex + 1,
                                            ),
                                        ],
                                    })
                                }
                            />
                        </div>
                    ))}
                    <Button
                        className="self-end"
                        id="add-option-button"
                        onClick={() =>
                            updateProjectAttribute("questions", index, {
                                ...question,
                                options: [
                                    ...question.options,
                                    { text: "", description: "" },
                                ],
                            })
                        }
                    >
                        Add option
                    </Button>
                </div>
            </div>
        ))}
        <Button
            className="self-start"
            onClick={() =>
                addItemToProjectAttribute("questions", {
                    text: "",
                    subtext: "",
                    description: "",
                    options: [{ text: "", description: "" }],
                })
            }
        >
            Add question
        </Button>

        <NextStepButton
            stepValidationErrors={stepValidationErrors}
            onGoToNextStep={onGoToNextStep}
        />
    </>
);

export default CreateProjectQuestionsStep;
