import { UniqueIdentifier } from "@dnd-kit/core";
import {
    AnimateLayoutChanges,
    defaultAnimateLayoutChanges,
    useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { QuestionGroup } from "../../../types/goldpan";
import { UNGROUPED_ID } from "./constants";
import QuestionGroupItem from "./question-group";

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
    defaultAnimateLayoutChanges({ ...args, wasDragging: true });

const DroppableQuestionGroup = ({
    children,
    disabled,
    group,
    id,
    items,
    onRemove,
    style,
}: {
    children: React.ReactNode;
    group?: QuestionGroup;
    style?: React.CSSProperties;
    onRemove(): void;
    disabled?: boolean;
    id: UniqueIdentifier;
    items: UniqueIdentifier[];
}) => {
    const isUngrouped = id === UNGROUPED_ID;
    const { isDragging, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
            data: {
                type: "container",
                children: items,
            },
            animateLayoutChanges,
        });

    return (
        <QuestionGroupItem
            disabled={disabled || isUngrouped}
            group={group}
            listeners={listeners}
            ref={disabled ? undefined : setNodeRef}
            style={{
                ...style,
                transition,
                transform: CSS.Translate.toString(transform),
                opacity: isDragging ? 0.5 : undefined,
            }}
            onRemove={onRemove}
        >
            {children}
        </QuestionGroupItem>
    );
};

export default DroppableQuestionGroup;
