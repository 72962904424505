import { InterviewOutcome, Sentiment } from "../types/goldpan";

const Outcome = ({ outcome }: { outcome: InterviewOutcome }) => {
    const color =
        outcome.sentiment === Sentiment.NEGATIVE
            ? "gp-sentiment-negative"
            : outcome.sentiment === Sentiment.POSITIVE
            ? "gp-sentiment-positive"
            : "gp-sentiment-neutral";
    return (
        <div
            className={`font-semibold py-0.5 px-2 rounded-md text-sm bg-${color}-light text-${color} border-${color}`}
        >
            {outcome.name}
        </div>
    );
};

export default Outcome;
