import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

import Button, { ButtonVariant } from "./button";
import Icon from "./icon";

interface Props {
    className?: string;
    isOpen: boolean;
    footer?: JSX.Element;
    title?: string | JSX.Element;
    confirmTooltip?: string;
    confirmButtonVariant?: ButtonVariant;
    isConfirmDisabled?: boolean;
    isConfirmLoading?: boolean;
    onClose?: () => void;
    onConfirm?: () => void;
}

const dropIn = {
    hidden: {
        y: "-100vh",
        opacity: 0,
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: {
            duration: 0.1,
            type: "spring",
            damping: 25,
            stiffness: 500,
        },
    },
    exit: {
        opacity: 0,
    },
};

const Modal: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    confirmButtonVariant = ButtonVariant.PRIMARY,
    confirmTooltip,
    footer,
    isConfirmDisabled,
    isConfirmLoading,
    isOpen,
    onClose,
    onConfirm,
    title,
}) => {
    const footerContent = footer ? (
        footer
    ) : (
        <div className="flex flex-items justify-between">
            <Button className="no-bg" onClick={onClose}>
                Cancel
            </Button>
            <Button
                disabled={isConfirmDisabled}
                id="modal-confirm"
                isLoading={isConfirmLoading}
                tooltip={confirmTooltip}
                variant={confirmButtonVariant}
                onClick={onConfirm}
            >
                Confirm
            </Button>
        </div>
    );

    return createPortal(
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    animate={{ opacity: 1 }}
                    className="modal-overlay"
                    exit={{ opacity: 0 }}
                    initial={{ opacity: 0 }}
                    onClick={onClose}
                >
                    <motion.div
                        animate="visible"
                        className={classNames("modal", className)}
                        exit="exit"
                        id="modal"
                        initial="hidden"
                        variants={dropIn}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {title && (
                            <div className="modal-header flex items-center justify-between">
                                <div className="text-lg font-bold">{title}</div>
                                <Button className="no-bg" onClick={onClose}>
                                    <Icon className="h-5 w-5" icon="close" />
                                </Button>
                            </div>
                        )}
                        <div className="modal-content">{children}</div>
                        <div className="modal-footer">{footerContent}</div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.body,
    );
};

export default Modal;
