import { Subtheme } from "../types/goldpan";
import Icon from "./icon";

const ThemeTree = ({ subtheme }: { subtheme: Subtheme }) => {
    return (
        <div className="flex items-center">
            <div>
                <p className="text-xs text-gray-500">Theme</p>
                <p className="font-semibold text-xs">{subtheme.parent.name}</p>
            </div>
            <Icon className="text-gray-500 text-lg mx-4" icon="chevron_right" />
            <div>
                <p className="text-xs text-gray-500">Subtheme</p>
                <p className="font-semibold text-xs">{subtheme.name}</p>
            </div>
        </div>
    );
};

export default ThemeTree;
