import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = ({ title }: { title: string }) => {
    const location = useLocation();
    const appName = `Goldpan${window.production ? "" : " DEV"}`;
    useEffect(() => {
        document.title = `${appName} - ${title}`;

        return () => {
            document.title = `${appName}`;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, location]);
    return null;
};

export default PageTitle;
