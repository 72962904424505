import jsPDF from "jspdf";
import { isArray, sortBy } from "lodash";

import { InterFont, InterFontBold } from "./pdf-fonts";
import {
    DecisionReason,
    Interview,
    InterviewMetadataField,
    Question,
    Quote,
    ResponseSummary,
    Subtheme,
} from "./types/goldpan";
import { assertNonNull } from "./utils";

const a4 = { width: 210, height: 297 };
const marginX = 15;
const marginY = 20;
const sectionSeparation = 6;
const h1 = 20;
const h2 = 12;
const h3 = 10;
const h4 = 9;
const p = 8;
const pointsPerMillimeter = 2.83;
const lineHeight = 1.15;

const textHeight = (fontSize: number, numLines = 1, factor = 1) =>
    (numLines * fontSize * lineHeight * factor) / pointsPerMillimeter;

export const generateInterviewPdf = (
    interview: Interview,
    interviewMetadataFields: InterviewMetadataField[],
    subthemes: Subtheme[],
    decisionReasons: DecisionReason[],
    questions: Question[],
    questionResponses: ResponseSummary[],
    positiveQuotes: Quote[],
    negativeQuotes: Quote[],
    neutralQuotes: Quote[],
) => {
    const doc = new jsPDF({ putOnlyUsedFonts: true });
    doc.addFileToVFS("InterFont.ttf", InterFont);
    doc.addFileToVFS("InterFontBold.ttf", InterFontBold);
    doc.addFont("InterFont.ttf", "InterFont", "normal");
    doc.addFont("InterFontBold.ttf", "InterFontBold", "normal");
    let lineIndex = marginY;

    const moveLineIndex = (plus: number) => {
        if (lineIndex + plus > a4.height - marginY) {
            doc.addPage();
            lineIndex = marginY;
        } else {
            lineIndex += plus;
        }
    };

    const drawDivider = () => {
        doc.setLineWidth(0.25);
        doc.setDrawColor(237, 237, 237);
        doc.line(marginX, lineIndex, a4.width - marginX, lineIndex);
        moveLineIndex(4);
    };

    const addText = (
        fontSize: number,
        text: string | string[],
        x: number = marginX,
        font: "InterFont" | "InterFontBold" = "InterFont",
        moveLines = true,
    ) => {
        if (isArray(text)) {
            const totalHeight = textHeight(fontSize, text.length);
            if (lineIndex + totalHeight > a4.height - marginY) {
                lineIndex = marginY;
                doc.addPage();
            }
        }

        doc.setFont(font);
        doc.setFontSize(fontSize);
        doc.text(text, x, lineIndex);
        if (moveLines) {
            moveLineIndex(
                textHeight(fontSize, isArray(text) ? text.length : 1),
            );
        }
    };

    const image = { width: 43, height: 13 };
    doc.addImage(
        `${window.STATIC_URL}images/logos/GoldpanDarkLogo.png`,
        "PNG",
        a4.width - marginX - image.width,
        lineIndex - image.height / 2,
        image.width,
        image.height,
    );
    addText(h1, interview.name, marginX, "InterFontBold", false);
    moveLineIndex(textHeight(h1, 1, 0.7));
    addText(h2, interview.date);

    doc.setLineWidth(1);
    doc.setDrawColor(30, 27, 176);
    doc.line(marginX, lineIndex, a4.width - marginX, lineIndex);
    moveLineIndex(3);

    const metadata = interview.outcome
        ? [`Outcome: ${interview.outcome.name}`]
        : [];
    interviewMetadataFields.forEach((field) => {
        const data = interview.metadata_list.find(
            (f) => f.interview_field.id === field.id,
        );
        metadata.push(
            `${field.display_title}: ${data ? data.data : "(blank)"}`,
        );
    });
    metadata.forEach((field, index) => {
        const indexIsEven = index % 2 === 0;
        if (indexIsEven) {
            moveLineIndex(textHeight(p, 1, 1.1));
        }
        addText(
            p,
            field,
            indexIsEven ? marginX : (a4.width - marginX * 2) / 2,
            "InterFont",
            false,
        );
    });
    moveLineIndex(textHeight(p));

    if (decisionReasons.length > 0) {
        moveLineIndex(sectionSeparation);
        addText(h2, "Decision Reasons", marginX, "InterFontBold");
        const visibleSubthemes = subthemes.filter((subtheme) => {
            const decisions = decisionReasons.filter(
                (decision) => decision.subtheme === subtheme.id,
            );
            return decisions.length > 0;
        });

        visibleSubthemes.forEach((subtheme, index) => {
            const decisions = decisionReasons.filter(
                (decision) => decision.subtheme === subtheme.id,
            );

            moveLineIndex(2);
            addText(
                h3,
                `${subtheme.parent.name} > ${subtheme.name}`.toLocaleUpperCase(),
                marginX,
                "InterFont",
                false,
            );
            moveLineIndex(textHeight(h3, 1, 1.2));

            decisions.forEach((decision) => {
                const summaryLines = doc
                    .setFontSize(p)
                    .splitTextToSize(decision.summary, a4.width - marginX * 2);
                addText(p, summaryLines, marginX, "InterFontBold");

                decision.quotes.forEach((quote) => {
                    const quoteStr = `"${quote.text}"`;
                    const quoteLines = doc
                        .setFontSize(p)
                        .splitTextToSize(quoteStr, a4.width - marginX * 2);
                    addText(p, quoteLines);
                });
            });

            if (index < visibleSubthemes.length - 1) {
                drawDivider();
            }
        });
    }

    if (questionResponses.length > 0) {
        moveLineIndex(sectionSeparation);
        addText(h2, "Buyer Journey", marginX, "InterFontBold");
        const visibleQuestions = questions.filter((question) => {
            const responses = questionResponses.filter(
                (response) => response.question === question.id,
            );
            return responses.length > 0;
        });
        visibleQuestions.forEach((question, index) => {
            const responses = questionResponses.filter(
                (response) => response.question === question.id,
            );

            moveLineIndex(2);
            addText(
                h3,
                question.text.toLocaleUpperCase(),
                marginX,
                "InterFont",
                false,
            );
            if (question.subtext) {
                moveLineIndex(textHeight(h3));
                addText(h4, question.subtext, marginX, "InterFont", false);
                moveLineIndex(textHeight(h4, 1, 1.2));
            } else {
                moveLineIndex(textHeight(h3, 1, 1.2));
            }

            responses.forEach((response) => {
                const summaryLines = doc
                    .setFontSize(p)
                    .splitTextToSize(response.summary, a4.width - marginX * 2);
                addText(p, summaryLines, marginX, "InterFontBold");
                moveLineIndex(textHeight(p, 1, 0.2));

                const sortedOptions = sortBy(
                    response.options,
                    (responseOption) => {
                        const selectedOption = question.options.find((opt) => {
                            return opt.id === responseOption.option;
                        });
                        return selectedOption?.ordinal ?? 1;
                    },
                );

                sortedOptions.forEach((responseOption) => {
                    const option = question.options.find(
                        (opt) => opt.id === responseOption.option,
                    );
                    addText(
                        p,
                        `Selected option: ${assertNonNull(option?.text)}`,
                    );
                    responseOption.quotes.forEach((quote) => {
                        const quoteStr = `"${quote.text}"`;
                        const quoteLines = doc
                            .setFontSize(p)
                            .splitTextToSize(quoteStr, a4.width - marginX * 2);
                        addText(p, quoteLines);
                    });
                    moveLineIndex(textHeight(p, 1, 0.2));
                });
            });
            if (index < visibleQuestions.length - 1) {
                drawDivider();
            }
        });
    }

    if (positiveQuotes.length > 0) {
        moveLineIndex(sectionSeparation);
        addText(h2, "Positive Quotes", marginX, "InterFontBold");
        positiveQuotes.forEach((quote) => {
            const quoteStr = `"${quote.text}"`;
            const quoteLines = doc
                .setFontSize(p)
                .splitTextToSize(quoteStr, a4.width - marginX * 2);
            addText(p, quoteLines);
            moveLineIndex(textHeight(p, 1, 0.2));
        });
    }
    if (negativeQuotes.length > 0) {
        moveLineIndex(sectionSeparation);
        addText(h2, "Negative Quotes", marginX, "InterFontBold");
        negativeQuotes.forEach((quote) => {
            const quoteStr = `"${quote.text}"`;
            const quoteLines = doc
                .setFontSize(p)
                .splitTextToSize(quoteStr, a4.width - marginX * 2);
            addText(p, quoteLines);
            moveLineIndex(textHeight(p, 1, 0.2));
        });
    }
    if (neutralQuotes.length > 0) {
        moveLineIndex(sectionSeparation);
        addText(h2, "Neutral Quotes", marginX, "InterFontBold");
        neutralQuotes.forEach((quote) => {
            const quoteStr = `"${quote.text}"`;
            const quoteLines = doc
                .setFontSize(p)
                .splitTextToSize(quoteStr, a4.width - marginX * 2);
            addText(p, quoteLines);
            moveLineIndex(textHeight(p, 1, 0.2));
        });
    }

    moveLineIndex(sectionSeparation);
    addText(h2, "Transcript", marginX, "InterFontBold");
    interview.paragraphs.forEach((paragraph) => {
        moveLineIndex(textHeight(p, 1, 0.5));
        const lines = doc
            .setFontSize(p)
            .splitTextToSize(paragraph.text, a4.width - marginX * 2);
        const heading = paragraph.interviewer ? "Goldpan" : "Interviewee";
        addText(p, heading.toLocaleUpperCase(), marginX, "InterFontBold");
        addText(p, lines);
    });

    doc.save(`${interview.name}-${new Date().toISOString().split("T")[0]}.pdf`);
};
