import classNames from "classnames";

export type Tab = {
    id: string;
    label: string;
    disabled?: boolean;
};

const Tabs = ({
    active,
    onClickTab,
    tabs,
}: {
    tabs: Tab[];
    active: string;
    onClickTab: (tab: Tab) => void;
}) => {
    return (
        <ul className="w-full flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200">
            {tabs.map((tab) => (
                <li className="me-2" key={tab.id}>
                    <div
                        className={classNames(
                            "inline-block px-3 py-2 rounded-t-lg select-none tab-item",
                            {
                                "text-gp-blue bg-gray-100": active === tab.id,
                                "hover:text-gray-600 hover:bg-gray-50":
                                    active !== tab.id && !tab.disabled,
                                "text-gray-400 cursor-not-allowed":
                                    tab.disabled,
                            },
                        )}
                        role="button"
                        onClick={() => onClickTab(tab)}
                    >
                        {tab.label}
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default Tabs;
