import classNames from "classnames";
import { PropsWithChildren } from "react";

const Card = ({
    children,
    className,
}: PropsWithChildren<{ className?: string }>) => {
    return <div className={classNames("card", className)}>{children}</div>;
};

export default Card;
