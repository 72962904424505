import { useCallback, useMemo } from "react";
import Select, { SingleValue } from "react-select";

import { setInterviewIdInUrl } from "../../api";
import { useAppDispatch } from "../../hooks";
import { setInterviewId } from "../../stores/interview-parser";
import { Interview } from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";

interface Props {
    interviews: Interview[];
    selectedInterviewId: number | null;
    disabled?: boolean;
    onChange?: (interviewId: number) => void;
}

const InterviewSelector: React.FC<Props> = ({
    disabled,
    interviews,
    onChange,
    selectedInterviewId,
}) => {
    const dispatch = useAppDispatch();
    const interviewOptions = useMemo(
        () =>
            interviews.map((interview) => ({
                value: interview.id,
                label: interview.name,
            })),
        [interviews],
    );

    const handleChooseInterview = useCallback(
        (option: SingleValue<{ value: number; label: string }>) => {
            const interviewId = option!.value;
            dispatch(setInterviewId(interviewId));
            onChange && onChange(interviewId);
            setInterviewIdInUrl(interviewId);
        },
        [onChange, dispatch],
    );

    return (
        <div className="flex items-center">
            <label className="mr-4">Interview:</label>
            <div className="flex-grow">
                <Select
                    {...defaultSelectProps}
                    isDisabled={disabled}
                    options={interviewOptions}
                    value={
                        interviewOptions.find(
                            (i) => i.value === selectedInterviewId,
                        ) ?? null
                    }
                    onChange={handleChooseInterview}
                />
            </div>
        </div>
    );
};

export default InterviewSelector;
