import Tippy from "@tippyjs/react";
import classNames from "classnames";
import { PropsWithChildren } from "react";

import Icon from "./icon";

interface Props {
    label: string;
    infoTooltip?: string;
    className?: string;
}

const FormField: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    infoTooltip,
    label,
}) => {
    return (
        <>
            <div className={classNames("flex items-center gap-2", className)}>
                <label>{label}</label>
                {infoTooltip && (
                    <Tippy content={infoTooltip}>
                        <div className="flex items-center text-gray-400">
                            <Icon icon="info" />
                        </div>
                    </Tippy>
                )}
            </div>
            {children}
        </>
    );
};

export default FormField;
