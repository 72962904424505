import { useCallback, useMemo, useState } from "react";
import { unstable_usePrompt, useParams } from "react-router-dom";

import { parseTranscriptJson } from "../api";
import Button from "../components/button";
import Icon from "../components/icon";
import AudioTranscript from "../components/parser/audio-transcript";
import { isValidSpeaker } from "../components/parser/helpers";
import InterviewForm from "../components/parser/interview-form";
import InterviewParagraphEditor from "../components/parser/interview-paragraph-editor";
import PromptOverrideModal from "../components/parser/prompt-override-modal";
import TextTranscript from "../components/parser/text-transcript";
import ScrollShadowWrapper from "../components/scroll-shadow-wrapper";
import Tabs from "../components/tabs";
import { useAppDispatch, useAppSelector, useCheckWhenLeaving } from "../hooks";
import { setInterviews } from "../stores/project";
import {
    Interview,
    PromptType,
    RawInterviewMetadataValues,
    TranscriptParagraph,
    WithUUID,
} from "../types/goldpan";
import { Messages } from "./message-list";

const ParseTranscriptApp: React.FC = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);

    const dispatch = useAppDispatch();
    const interviews = useAppSelector((state) => state.project.interviews);

    const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
    const [isRawTranscriptText, setIsRawTranscriptText] = useState(true);
    const [rawTranscript, setRawTranscript] = useState<string | null>(null);

    const [interviewAudioId, setInterviewAudioId] = useState<number | null>(
        null,
    );
    const [jsonTranscript, setJsonTranscript] = useState<
        WithUUID<TranscriptParagraph>[] | null
    >(null);
    const [createdInterview, setCreatedInterview] = useState<Interview | null>(
        null,
    );
    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [isSubmittingRawTranscript, setIsSubmittingRawTranscript] =
        useState(false);
    const [isSubmittingJsonTranscript, setIsSubmittingJsonTranscript] =
        useState(false);

    const interviewHasBeenCreated = createdInterview !== null;

    const shouldCheckBeforeLeavingPage = !!(
        (rawTranscript || interviewAudioId) &&
        !interviewHasBeenCreated
    );
    useCheckWhenLeaving(shouldCheckBeforeLeavingPage);
    unstable_usePrompt({
        message: "You have unsaved changes. Are you sure you want to leave?",
        when: ({ currentLocation, nextLocation }) =>
            shouldCheckBeforeLeavingPage &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    const handleSubmitJsonTranscript = useCallback(
        async (
            code: string,
            name: string,
            outcome: number | null,
            metadata: RawInterviewMetadataValues,
        ) => {
            Messages.removeAll();
            setIsSubmittingJsonTranscript(true);
            const response = await parseTranscriptJson(projectId, {
                json_transcript: jsonTranscript!.map(
                    (paragraph) => paragraph.data,
                ),
                project_id: projectId,
                interview_audio: interviewAudioId,
                interview_code: code,
                interview_name: name,
                interview_outcome: outcome,
                interview_metadata: metadata,
                interview_raw_transcript: rawTranscript,
            });
            setIsSubmittingJsonTranscript(false);
            if (response) {
                setCreatedInterview(response.data);
                dispatch(setInterviews([...interviews, response.data]));
                Messages.success("Success");
            }
        },
        [
            projectId,
            jsonTranscript,
            interviewAudioId,
            rawTranscript,
            dispatch,
            interviews,
        ],
    );

    const interviewIsValid = useMemo(
        () =>
            jsonTranscript &&
            jsonTranscript.every((paragraph) =>
                isValidSpeaker(paragraph.data.speaker),
            ),
        [jsonTranscript],
    );

    return (
        <div className="w-full flex flex-grow gap-4 mt-4 ai-transcript-height">
            {isOverrideModalOpen && (
                <PromptOverrideModal
                    projectId={projectId}
                    promptTypes={[PromptType.CLEAN_TRANSCRIPT]}
                    onClose={() => setIsOverrideModalOpen(false)}
                />
            )}
            <div className="w-1/2 flex flex-col">
                <div className="flex items-center mb-4">
                    <h3 className="mr-2">Raw transcript</h3>
                    <Button
                        className="no-bg"
                        tooltip="AI prompt settings"
                        onClick={() => setIsOverrideModalOpen(true)}
                    >
                        <Icon icon="settings" />
                    </Button>
                </div>
                <div className="flex items-center gap-2 text-sm mb-2">
                    <Tabs
                        active={isRawTranscriptText ? "text" : "audio"}
                        tabs={[
                            {
                                id: "text",
                                label: "Text",
                                disabled:
                                    isUploadingFile ||
                                    isSubmittingRawTranscript ||
                                    interviewAudioId !== null ||
                                    jsonTranscript !== null,
                            },
                            {
                                id: "audio",
                                label: "Audio file",
                                disabled:
                                    isUploadingFile ||
                                    isSubmittingRawTranscript ||
                                    interviewAudioId !== null ||
                                    jsonTranscript !== null,
                            },
                        ]}
                        onClickTab={({ id }) =>
                            setIsRawTranscriptText(id === "text")
                        }
                    />
                </div>
                {isRawTranscriptText ? (
                    <TextTranscript
                        disabled={jsonTranscript !== null}
                        isSubmittingRawTranscript={isSubmittingRawTranscript}
                        projectId={projectId}
                        rawTranscript={rawTranscript}
                        setIsSubmittingRawTranscript={
                            setIsSubmittingRawTranscript
                        }
                        setJsonTranscript={setJsonTranscript}
                        setRawTranscript={setRawTranscript}
                    />
                ) : (
                    <AudioTranscript
                        disabled={jsonTranscript !== null}
                        interviewAudioId={interviewAudioId}
                        isSubmittingRawTranscript={isSubmittingRawTranscript}
                        projectId={projectId}
                        setInterviewAudioId={setInterviewAudioId}
                        setIsUploadingFile={setIsUploadingFile}
                        setJsonTranscript={setJsonTranscript}
                    />
                )}
            </div>
            <div className="w-1/2 flex flex-col">
                {jsonTranscript !== null && (
                    <>
                        <h3 className="mb-4">Paragraphs</h3>
                        <ScrollShadowWrapper>
                            <div className="flex flex-col px-2">
                                <InterviewParagraphEditor
                                    interviewHasBeenCreated={
                                        interviewHasBeenCreated
                                    }
                                    paragraphs={jsonTranscript}
                                    setJsonTranscript={setJsonTranscript}
                                />

                                <InterviewForm
                                    disabled={interviewHasBeenCreated}
                                    interview={createdInterview}
                                    interviewIsValid={interviewIsValid}
                                    isSubmitting={isSubmittingJsonTranscript}
                                    projectId={projectId}
                                    onSubmit={handleSubmitJsonTranscript}
                                />
                            </div>
                        </ScrollShadowWrapper>
                    </>
                )}
            </div>
        </div>
    );
};

export default ParseTranscriptApp;
