import classNames from "classnames";

import { InterviewParagraph } from "../../types/goldpan";

interface Props {
    paragraphs: InterviewParagraph[];
}

const InterviewParagraphs: React.FC<Props> = ({ paragraphs }) => {
    return (
        <div className="flex flex-col gap-2">
            {paragraphs.map((paragraph) => (
                <div
                    className={classNames("p-4 rounded-md", {
                        "bg-gray-200": paragraph.interviewer,
                        "bg-blue-500 text-white": !paragraph.interviewer,
                    })}
                    id={`paragraph-${paragraph.id}`}
                    key={paragraph.id}
                >
                    <div>{paragraph.text}</div>
                    <div
                        className={classNames("pt-2 mt-2 text-xs border-t", {
                            "border-slate-500": paragraph.interviewer,
                            "border-white text-white": !paragraph.interviewer,
                        })}
                    >
                        {`Paragraph ${paragraph.ordinal} - ${
                            paragraph.interviewer ? "Goldpan" : "Interviewee"
                        }`}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default InterviewParagraphs;
