import { useCallback, useMemo } from "react";
import Select, { OnChangeValue } from "react-select";

import { useAppSelector } from "../../hooks";
import { Competitor, SelectOption } from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";

type CompetitorSelectOption = SelectOption & { competitor: Competitor };

const CompetitorSelect = ({
    onChange,
    selected,
}: {
    selected: number[];
    onChange: (value: number[]) => void;
}) => {
    const competitors = useAppSelector((state) => state.project.competitors);

    const options: CompetitorSelectOption[] = useMemo(
        () =>
            competitors.map((opt) => ({
                value: assertNonNull(opt.id),
                label: opt.name,
                competitor: opt,
            })),
        [competitors],
    );

    const selectedOptions: CompetitorSelectOption[] = useMemo(() => {
        return selected
            .filter(
                (selectedOpt) =>
                    !!competitors.find(
                        (competitor) => competitor.id === selectedOpt,
                    ),
            )
            .map((selectedOpt) => {
                const competitor = assertNonNull(
                    competitors.find(
                        (competitor) => competitor.id === selectedOpt,
                    ),
                );
                return {
                    value: assertNonNull(competitor.id),
                    label: competitor.name,
                    competitor,
                };
            });
    }, [competitors, selected]);

    const handleChange = useCallback(
        (newValue: OnChangeValue<CompetitorSelectOption, true>) => {
            onChange(newValue.map((val) => val.value));
        },
        [onChange],
    );

    return (
        <Select
            {...defaultSelectProps}
            closeMenuOnSelect={false}
            isClearable={false}
            isMulti
            options={options}
            value={selectedOptions}
            onChange={handleChange}
        />
    );
};

export default CompetitorSelect;
