import classNames from "classnames";

import { ComparisonQuestionOptionData } from "./helpers";

const OptionTag = ({
    index,
    onClick,
    optionData,
    totalInterviews,
}: {
    index: number;
    optionData: ComparisonQuestionOptionData;
    totalInterviews: number;
    onClick?: (questionOptionId: number) => void;
}) => {
    return (
        <div
            className={classNames(
                `w-full flex items-center gap-4 ${
                    index > 0 ? `pt-4` : ``
                } border-gray-200`,
                { [`hover:opacity-50`]: !!onClick },
            )}
            role={onClick ? "button" : undefined}
            onClick={() => onClick && onClick(optionData.optionId)}
        >
            <div className="bg-gp-blue w-9 h-9 shrink-0 content-center text-center rounded-lg text-white">
                {1 + index}
            </div>
            <div className="w-10/12">
                <div className="font-semibold w-auto hyphens-auto break-words">
                    {optionData.optionName}
                </div>
                <div className="text-xs">{`${
                    ((optionData.interviewCount * 100) / totalInterviews) >> 0
                }% (${
                    optionData.interviewCount
                }/${totalInterviews}) interviews`}</div>
            </div>
        </div>
    );
};

export default OptionTag;
