import { useCallback, useMemo, useState } from "react";
import Select, { Options } from "react-select";
import CreatableSelect from "react-select/creatable";

import { getProjectIdFromUrl, saveCompetitor } from "../../api";
import { useAppDispatch } from "../../hooks";
import { setCompetitors } from "../../stores/project";
import {
    Competitor,
    InterviewParagraph,
    Quote,
    QuoteTopicType,
    SelectOption,
    Sentiment,
    WithUUID,
} from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Button, { ButtonPadding } from "../button";
import FormField from "../form-field";
import Icon from "../icon";
import QuoteInput from "./quote-input";
import SaveDeleteButtons from "./save-delete-buttons";

interface Props {
    competitors: Competitor[];
    quote: WithUUID<Quote>;
    paragraphOptions: Options<SelectOption>;
    interviewParagraphs: InterviewParagraph[];
    onSaveQuote: (quoteUuid: string) => Promise<void>;
    onDeleteQuote: (quoteUuid: string) => Promise<void>;
    onUpdateQuote: (quote: Partial<WithUUID<Quote>>) => void;
}

const QuoteCard: React.FC<Props> = ({
    competitors,
    interviewParagraphs,
    onDeleteQuote,
    onSaveQuote,
    onUpdateQuote,
    paragraphOptions,
    quote,
}) => {
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSavingNewOption, setIsSavingNewOption] = useState(false);

    const competitorOptions = useMemo(
        () =>
            competitors.map((competitor) => ({
                value: competitor.id,
                label: competitor.name,
            })) ?? [],
        [competitors],
    );

    const sentimentOptions = useMemo(
        () =>
            Object.entries(Sentiment).map(([label, value]) => ({
                value,
                label,
            })),
        [],
    );

    const topicOptions = useMemo(
        () =>
            Object.entries(QuoteTopicType).map(([label, value]) => ({
                value,
                label,
            })),
        [],
    );

    const handleCreateNewOption = useCallback(
        (text: string) => {
            setIsSavingNewOption(true);
            saveCompetitor(getProjectIdFromUrl(), {
                id: null,
                name: text,
            }).then((competitor) => {
                setIsSavingNewOption(false);
                if (competitor) {
                    dispatch(
                        setCompetitors(competitors.concat([competitor.data])),
                    );
                    onUpdateQuote({
                        ...quote,
                        data: {
                            ...quote.data,
                            competitor: competitor.data.id,
                        },
                        isDirty: true,
                    });
                }
            });
        },
        [dispatch, competitors, onUpdateQuote, quote],
    );

    const handleSaveQuote = useCallback(async () => {
        setIsSaving(true);
        await onSaveQuote(quote.uuid);
        setIsSaving(false);
    }, [quote.uuid, onSaveQuote]);

    const handleDeleteQuote = useCallback(async () => {
        setIsDeleting(true);
        await onDeleteQuote(quote.uuid);
        setIsDeleting(false);
    }, [quote.uuid, onDeleteQuote]);

    return (
        <div className="card flex flex-col h-[350px] mb-4">
            <div className="flex flex-grow gap-2">
                <div className="w-1/2 flex flex-col gap-2">
                    <div>
                        <FormField label="Topic">
                            <Select
                                {...defaultSelectProps}
                                className="mt-2"
                                options={topicOptions}
                                value={
                                    topicOptions.find(
                                        (i) =>
                                            i.value === quote.data.topic_type,
                                    ) ?? null
                                }
                                onChange={(opt) => {
                                    onUpdateQuote({
                                        ...quote,
                                        data: {
                                            ...quote.data,
                                            topic_type: opt!.value,
                                        },
                                        isDirty: true,
                                    });
                                }}
                            />
                        </FormField>
                    </div>
                    {quote.data.topic_type === QuoteTopicType.COMPETITOR && (
                        <div>
                            <FormField label="Competitor">
                                <CreatableSelect
                                    {...defaultSelectProps}
                                    className="mt-2"
                                    isLoading={isSavingNewOption}
                                    options={competitorOptions}
                                    value={
                                        competitorOptions.find(
                                            (i) =>
                                                i.value ===
                                                quote.data.competitor,
                                        ) ?? null
                                    }
                                    onChange={(opt) => {
                                        onUpdateQuote({
                                            ...quote,
                                            data: {
                                                ...quote.data,
                                                competitor: opt!.value,
                                            },
                                            isDirty: true,
                                        });
                                    }}
                                    onCreateOption={handleCreateNewOption}
                                />
                            </FormField>
                        </div>
                    )}
                    <div>
                        <FormField label="Sentiment">
                            <Select
                                {...defaultSelectProps}
                                className="mt-2"
                                options={sentimentOptions}
                                value={
                                    sentimentOptions.find(
                                        (i) => i.value === quote.data.sentiment,
                                    ) ?? null
                                }
                                onChange={(opt) => {
                                    onUpdateQuote({
                                        ...quote,
                                        data: {
                                            ...quote.data,
                                            sentiment: opt!.value,
                                        },
                                        isDirty: true,
                                    });
                                }}
                            />
                        </FormField>
                    </div>
                </div>
                <div className="w-1/2 flex flex-col gap-2">
                    <div>
                        <div className="flex items-center gap-2">
                            <label>Paragraph</label>
                            {quote.data.interview_paragraph && (
                                <Button
                                    className="no-bg icon-only"
                                    padding={ButtonPadding.SLIM}
                                    onClick={() => {
                                        const paragraph =
                                            document.getElementById(
                                                "paragraph-" +
                                                    quote.data
                                                        .interview_paragraph,
                                            );
                                        paragraph?.scrollIntoView({
                                            behavior: "smooth",
                                            block: "center",
                                        });
                                    }}
                                >
                                    <Icon icon="visibility" />
                                </Button>
                            )}
                        </div>
                        <Select
                            {...defaultSelectProps}
                            isClearable
                            options={paragraphOptions}
                            value={
                                paragraphOptions.find(
                                    (i) =>
                                        i.value ===
                                        quote.data.interview_paragraph,
                                ) ?? null
                            }
                            onChange={(opt) => {
                                onUpdateQuote({
                                    ...quote,
                                    data: {
                                        ...quote.data,
                                        interview_paragraph: opt
                                            ? opt.value
                                            : null,
                                        // update text if quote field is blank
                                        text:
                                            quote.data.text === "" && opt
                                                ? interviewParagraphs.find(
                                                      (p) => p.id === opt.value,
                                                  )?.text ?? ""
                                                : quote.data.text,
                                    },
                                    isDirty: true,
                                });
                            }}
                        />
                    </div>
                    <QuoteInput
                        isDisabled={false}
                        paragraph={
                            interviewParagraphs.find(
                                (p) => p.id === quote.data.interview_paragraph,
                            )?.text
                        }
                        quote={quote.data.text}
                        onChange={(text) =>
                            onUpdateQuote({
                                ...quote,
                                data: {
                                    ...quote.data,
                                    text,
                                },
                                isDirty: true,
                            })
                        }
                    />
                </div>
            </div>
            <div className="flex items-center justify-end gap-2 mt-2">
                <SaveDeleteButtons
                    isDeleting={isDeleting}
                    isSaved={!quote.isDirty}
                    isSaving={isSaving}
                    saveClassName="save-quote-button"
                    onDelete={handleDeleteQuote}
                    onSave={handleSaveQuote}
                />
            </div>
        </div>
    );
};

export default QuoteCard;
