import {
    ResponseSummary,
    TranscriptSpeaker,
    emptyQuote,
} from "../../types/goldpan";

export const isValidSpeaker = (speaker: string | null) => {
    return (
        speaker !== null &&
        [
            TranscriptSpeaker.GOLDPAN.toString(),
            TranscriptSpeaker.INTERVIEWEE.toString(),
        ].includes(speaker)
    );
};

export const initResponseSummary = (
    interviewId: number,
    summary: ResponseSummary,
): ResponseSummary => ({
    ...summary,
    options: summary.options.map((option) => {
        if (option.quotes.length > 0) {
            return option;
        }
        return {
            ...option,
            quotes: [emptyQuote(interviewId)],
        };
    }),
});
