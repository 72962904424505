import classNames from "classnames";
import { useCallback, useMemo, useState } from "react";
import { Options } from "react-select";

import { deleteResponseSummary, saveResponseSummary } from "../../api";
import { Messages } from "../../apps/message-list";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    addNewQuestionResponse,
    deleteQuestionResponse,
    setQuestionResponse,
} from "../../stores/interview-parser";
import { setQuestionResponseSummaries } from "../../stores/project";
import {
    InterviewParagraph,
    Question,
    QuestionType,
    ResponseSummary,
    emptyResponseSummaryOption,
} from "../../types/goldpan";
import { assertNonNull } from "../../utils";
import Button, { ButtonVariant } from "../button";
import FormField from "../form-field";
import Icon from "../icon";
import Textarea from "../textarea";
import { initResponseSummary } from "./helpers";
import ResponseSummaryOptionCard from "./response-summary-option-card";
import SaveDeleteButtons from "./save-delete-buttons";

type SelectOption = { value: number; label: string };
interface Props {
    question: Question;
    isDisabled: boolean;
    isParsingQuestionResponses: number[] | null;
    paragraphOptions: Options<SelectOption>;
    interviewId: number;
    interviewParagraphs: InterviewParagraph[];
    onUpdateQuestion: (question: Question) => void;
    onParseQuestionResponse: () => void;
}

const validateResponse = (questionResponseData: ResponseSummary): boolean => {
    let isValid = true;
    if (!questionResponseData.summary) {
        isValid = false;
        Messages.error("Question response missing summary");
    }
    if (!questionResponseData.question) {
        isValid = false;
        Messages.error("Question response missing question");
    }
    if (questionResponseData.options.length === 0) {
        isValid = false;
        Messages.error("Question response requires at least one option");
    }
    if (questionResponseData.options.some((opt) => !opt.option)) {
        isValid = false;
        Messages.error("Question response option missing");
    }
    return isValid;
};

const ResponseSummaryCard: React.FC<Props> = ({
    interviewId,
    interviewParagraphs,
    isDisabled,
    isParsingQuestionResponses,
    onParseQuestionResponse,
    onUpdateQuestion,
    paragraphOptions,
    question,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);

    const dispatch = useAppDispatch();
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const questionResponseSummaries = assertNonNull(
        useAppSelector((state) => state.project.questionResponseSummaries),
    );
    const responseSummary = useAppSelector(
        (state) =>
            state.interviewParser.questionResponses[assertNonNull(question.id)],
    );

    const handleAddNewResponseSummary = useCallback(() => {
        dispatch(
            addNewQuestionResponse({
                questionId: assertNonNull(question.id),
                interviewId,
            }),
        );
    }, [dispatch, interviewId, question]);

    const handleSaveResponseSummary = useCallback(async () => {
        setIsSaving(true);
        Messages.removeAll();
        if (responseSummary === undefined) {
            Messages.error("Cannot find response for question " + question.id);
            return;
        }

        // set the `quotes` array to empty if there isn't any quote text, since
        // the quote is optional
        const questionResponseData: ResponseSummary = {
            ...responseSummary.data,
            options: responseSummary.data.options.map((option) => {
                return {
                    ...option,
                    quotes:
                        option.quotes[0].text.trim() === ""
                            ? []
                            : option.quotes,
                };
            }),
        };
        const isNew = questionResponseData.id === null;
        if (interviewId && validateResponse(responseSummary.data)) {
            const response = await saveResponseSummary(
                project.id,
                questionResponseData,
            );
            if (!response) {
                setIsSaving(false);
                return;
            }
            dispatch(
                setQuestionResponse({
                    questionId: assertNonNull(question.id),
                    data: {
                        data: initResponseSummary(interviewId, response.data),
                        uuid: responseSummary.uuid,
                        isDirty: false,
                    },
                }),
            );
            if (isNew) {
                dispatch(
                    setQuestionResponseSummaries(
                        questionResponseSummaries.concat([response.data]),
                    ),
                );
            } else {
                dispatch(
                    setQuestionResponseSummaries(
                        questionResponseSummaries.map((summary) => {
                            if (summary.id === response.data.id) {
                                return response.data;
                            }
                            return summary;
                        }),
                    ),
                );
            }
            Messages.success("Saved successfully");
        }
        setIsSaving(false);
    }, [
        dispatch,
        interviewId,
        project,
        question,
        questionResponseSummaries,
        responseSummary,
    ]);

    const handleDeleteResponseSummary = useCallback(async () => {
        setIsDeleting(true);
        Messages.removeAll();
        if (responseSummary === undefined) {
            Messages.error("Cannot find response for question " + question.id);
            return;
        }

        if (responseSummary.data.id !== null) {
            const response = await deleteResponseSummary(
                project.id,
                responseSummary.data,
            );
            if (!response) {
                setIsDeleting(false);
                return;
            }
        }
        dispatch(
            deleteQuestionResponse({ questionId: assertNonNull(question.id) }),
        );
        dispatch(
            setQuestionResponseSummaries(
                questionResponseSummaries.filter((summary) => {
                    return summary.id !== responseSummary.data.id;
                }),
            ),
        );
        Messages.success("Question response deleted");
        setIsDeleting(false);
    }, [
        dispatch,
        project,
        question,
        questionResponseSummaries,
        responseSummary,
    ]);

    const handleAddNewResponseSummaryOption = useCallback(() => {
        if (!responseSummary) {
            Messages.error("Response summary not found");
            return;
        }

        if (
            question.type === QuestionType.SINGLE &&
            responseSummary.data.options.length === 1
        ) {
            Messages.error("Single select question can only have one option");
            return;
        }

        dispatch(
            setQuestionResponse({
                questionId: assertNonNull(question.id),
                data: {
                    ...responseSummary,
                    isDirty: true,
                    data: {
                        ...responseSummary.data,
                        options: [
                            emptyResponseSummaryOption(interviewId),
                            ...responseSummary.data.options,
                        ],
                    },
                },
            }),
        );
    }, [responseSummary, question, dispatch, interviewId]);

    const handleDeleteResponseSummaryOption = useCallback(
        async (index: number) => {
            Messages.removeAll();
            if (responseSummary === undefined) {
                Messages.error(
                    "Cannot find response for question " + question.id,
                );
                return;
            }
            const questionResponseOption = responseSummary.data.options[index];
            if (questionResponseOption === undefined) {
                Messages.error("Cannot find response option index " + index);
                return;
            }
            const responseSummaryData = {
                ...responseSummary.data,
                options: [
                    ...responseSummary.data.options.slice(0, index),
                    ...responseSummary.data.options.slice(index + 1),
                ],
            };
            const newResponseSummary = {
                ...responseSummary,
                data: responseSummaryData,
                isDirty: true,
            };
            dispatch(
                setQuestionResponse({
                    questionId: assertNonNull(question.id),
                    data: newResponseSummary,
                }),
            );
        },
        [responseSummary, dispatch, question],
    );

    const addNewOptionDisabled = useMemo(
        () =>
            isDisabled ||
            (question.type === QuestionType.SINGLE &&
                responseSummary?.data.options.length === 1),
        [isDisabled, question, responseSummary],
    );

    const header = (
        <div className="flex items-center gap-2">
            <div className="font-bold text-lg">{question.text}</div>
            <div className="text-gray-400 text-xs">
                {question.type.toLocaleUpperCase()}
            </div>
        </div>
    );
    return (
        <div className="card flex flex-col mb-4">
            {responseSummary ? (
                <>
                    <div className="mb-2 flex items-center justify-between">
                        {header}
                        <div className="flex items-center gap-2">
                            <div
                                className={classNames("flex items-center", {
                                    "text-red-700": responseSummary.isDirty,
                                    "text-green-700": !responseSummary.isDirty,
                                })}
                            >
                                {responseSummary.isDirty ? (
                                    <Icon icon="error" />
                                ) : (
                                    <Icon icon="check_circle" />
                                )}
                            </div>
                            <Button
                                className="no-bg"
                                icon={
                                    isCollapsed ? "expand_more" : "expand_less"
                                }
                                variant={ButtonVariant.NORMAL}
                                onClick={() =>
                                    isCollapsed
                                        ? setIsCollapsed(false)
                                        : setIsCollapsed(true)
                                }
                            />
                        </div>
                    </div>
                    {!isCollapsed && (
                        <>
                            <FormField label="Summary">
                                <Textarea
                                    className="h-48 resize-none"
                                    disabled={isDisabled}
                                    value={responseSummary.data.summary}
                                    onChange={(summary) =>
                                        dispatch(
                                            setQuestionResponse({
                                                questionId: assertNonNull(
                                                    question.id,
                                                ),
                                                data: {
                                                    ...responseSummary,
                                                    data: {
                                                        ...responseSummary.data,
                                                        summary,
                                                    },
                                                    isDirty: true,
                                                },
                                            }),
                                        )
                                    }
                                />
                            </FormField>
                            <div className="flex items-center justify-between mt-4">
                                <div className="font-bold">Options</div>
                                <div className="flex items-center gap-2">
                                    <Button
                                        className="ml-4 text-sm"
                                        disabled={addNewOptionDisabled}
                                        tooltip={
                                            question.type ===
                                                QuestionType.SINGLE &&
                                            responseSummary.data.options
                                                .length === 1
                                                ? "Single select only allows one option"
                                                : undefined
                                        }
                                        onClick={
                                            handleAddNewResponseSummaryOption
                                        }
                                    >
                                        Add new option
                                    </Button>
                                </div>
                            </div>
                            {responseSummary.data.options.map(
                                (responseSummaryOption, index) => (
                                    <ResponseSummaryOptionCard
                                        interviewParagraphs={
                                            interviewParagraphs
                                        }
                                        isDisabled={isDisabled}
                                        key={
                                            responseSummaryOption.id ??
                                            `option_${index}`
                                        }
                                        paragraphOptions={paragraphOptions}
                                        question={question}
                                        responseSummaryOption={
                                            responseSummaryOption
                                        }
                                        onDeleteResponseSummaryOption={() =>
                                            handleDeleteResponseSummaryOption(
                                                index,
                                            )
                                        }
                                        onUpdateQuestion={onUpdateQuestion}
                                        onUpdateResponseSummaryOption={(
                                            option,
                                        ) => {
                                            dispatch(
                                                setQuestionResponse({
                                                    questionId: assertNonNull(
                                                        question.id,
                                                    ),
                                                    data: {
                                                        ...responseSummary,
                                                        data: {
                                                            ...responseSummary.data,
                                                            options: [
                                                                ...responseSummary.data.options.slice(
                                                                    0,
                                                                    index,
                                                                ),
                                                                option,
                                                                ...responseSummary.data.options.slice(
                                                                    index + 1,
                                                                ),
                                                            ],
                                                        },
                                                        isDirty: true,
                                                    },
                                                }),
                                            );
                                        }}
                                    />
                                ),
                            )}
                            <div className="flex items-center justify-end gap-2 mt-4">
                                <SaveDeleteButtons
                                    isDeleting={isDeleting}
                                    isDisabled={isDisabled}
                                    isSaved={!responseSummary.isDirty}
                                    isSaving={isSaving}
                                    saveClassName="save-response-summary-button"
                                    onDelete={handleDeleteResponseSummary}
                                    onSave={handleSaveResponseSummary}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="flex items-center justify-between mb-2">
                    {header}
                    <div className="flex items-center gap-2">
                        <Button
                            className="text-sm"
                            disabled={isDisabled}
                            onClick={handleAddNewResponseSummary}
                        >
                            Start empty response
                        </Button>
                        <Button
                            disabled={isDisabled}
                            id={`parse-question-responses-button-${question.id}`}
                            isLoading={isParsingQuestionResponses?.includes(
                                assertNonNull(question.id),
                            )}
                            variant={ButtonVariant.PRIMARY}
                            onClick={onParseQuestionResponse}
                        >
                            Parse responses
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResponseSummaryCard;
