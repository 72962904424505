import {
    type CSSProperties,
    type ReactNode,
    type RefObject,
    type WheelEvent,
    useEffect,
    useRef,
    useState,
} from "react";

interface ScrollShadowWrapperProps {
    children: ReactNode;
    className?: string;
    style?: CSSProperties;
}

function ScrollShadowWrapper(props: ScrollShadowWrapperProps) {
    const { children, className = "", style = {} } = props;

    const [scrollTop, setScrollTop] = useState(0);
    const [scrollHeight, setScrollHeight] = useState(0);
    const [clientHeight, setClientHeight] = useState(0);

    const onScrollHandler = (event: WheelEvent<HTMLDivElement>) => {
        setScrollTop(event.currentTarget.scrollTop);
        setScrollHeight(event.currentTarget.scrollHeight);
        setClientHeight(event.currentTarget.clientHeight);
    };

    const wrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const resetRefSizes = (ref: RefObject<HTMLDivElement>) => {
            if (!ref.current) return;

            setScrollTop(ref.current.scrollTop);
            setScrollHeight(ref.current.scrollHeight);
            setClientHeight(ref.current.clientHeight);
        };

        resetRefSizes(wrapperRef);
    }, [wrapperRef?.current?.clientHeight]);

    const getVisibleSides = (): { top: boolean; bottom: boolean } => {
        const isBottom = clientHeight === scrollHeight - scrollTop;
        const isTop = scrollTop === 0;
        const isBetween =
            scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

        return {
            top: (isBottom || isBetween) && !(isTop && isBottom),
            bottom: (isTop || isBetween) && !(isTop && isBottom),
        };
    };

    return (
        <div
            className={`relative overflow-y-auto pr-[1px] ${className}`}
            data-testid="scroll-shadow-wrapper"
            ref={wrapperRef}
            style={style}
            onScroll={onScrollHandler}
        >
            <div
                className={`sticky top-0 bg-gradient-to-b from-gray-200 to-transparent w-full h-4 -mb-4 pointer-events-none transition-opacity duration-300 ${
                    getVisibleSides().top ? "opacity-100" : "opacity-0"
                }`}
                data-testid="scroll-shadow-top"
            />
            {children}
            <div
                className={`sticky bottom-0 bg-gradient-to-t from-transparent to-gray-200 w-full h-4 -mt-4 pointer-events-none rotate-180 transition-opacity duration-300 ${
                    getVisibleSides().bottom ? "opacity-100" : "opacity-0"
                }`}
                data-testid="scroll-shadow-bottom"
            />
        </div>
    );
}

export default ScrollShadowWrapper;
