import { flatMap, uniq } from "lodash";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

import FilterSidebar from "../components/filters/filter-sidebar";
import QuoteDisplay from "../components/quote";
import { filterInterviews } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";
import { PageWithHeader } from "../routers/project-router";
import { getPercentage } from "../utils";

const QuestionPage = () => {
    const { questionId: urlQuestionId } = useParams();
    const questionId = parseInt(urlQuestionId!);

    const questionResponseSummaries = useAppSelector(
        (state) => state.project.questionResponseSummaries,
    );
    const questions = useAppSelector((state) => state.project.questions);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );
    const selectedQuestion = useMemo(
        () => questions.find((question) => question.id === questionId),
        [questionId, questions],
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const outcomes = useAppSelector((state) => state.project.outcomes);

    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
    }, [interviews, selectedFilters, interviewMetadataValues]);

    const filteredResponseSummaries = useMemo(
        () =>
            questionResponseSummaries.filter(
                (response) =>
                    filteredInterviews
                        .map((interview) => interview.id)
                        .includes(response.interview) &&
                    response.question === questionId,
            ),
        [filteredInterviews, questionId, questionResponseSummaries],
    );
    const filteredResponsesInterviews = useMemo(
        () =>
            uniq(
                filteredResponseSummaries.map((response) => response.interview),
            ),
        [filteredResponseSummaries],
    );

    if (!selectedQuestion) {
        return <div className="mt-8 text-center">Question not found</div>;
    }

    return (
        <PageWithHeader heading={`Question: ${selectedQuestion.text}`}>
            <section className="grid grid-cols-[350px_1fr] gap-4">
                <FilterSidebar
                    activeFilters={selectedFilters}
                    hideCompetitors
                    onChangeFilters={setSelectedFilters}
                />
                <div className="flex flex-col gap-2">
                    <section className="mb-16 flex flex-col">
                        <div className="w-full flex flex-col card">
                            <div className="flex flex-row w-full">
                                <div className="flex flex-col flex-1 font-medium items-center">
                                    <div className="text-4xl font-bold">
                                        {getPercentage(
                                            filteredResponsesInterviews.length,
                                            filteredInterviews.length,
                                        )}
                                        %
                                    </div>
                                    <div className="text-sm text-gray-700">
                                        {filteredResponsesInterviews.length} of
                                        the {filteredInterviews.length}{" "}
                                        {filteredInterviews.length === 1
                                            ? "interview"
                                            : "interviews"}{" "}
                                        conducted
                                    </div>
                                </div>

                                {outcomes.map((outcome) => (
                                    <div
                                        className="flex flex-col flex-1 font-medium items-center"
                                        key={outcome.id}
                                    >
                                        <div className="text-3xl font-bold">
                                            {
                                                filteredInterviews
                                                    .filter((interview) =>
                                                        filteredResponsesInterviews.includes(
                                                            interview.id,
                                                        ),
                                                    )
                                                    .filter(
                                                        (interview) =>
                                                            interview.outcome
                                                                ?.id ===
                                                            outcome.id,
                                                    ).length
                                            }
                                        </div>
                                        <div className="text-sm text-gray-700">
                                            {outcome.name} interviews
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </section>

                    <section className="mb-16 last:mb-0 flex flex-col">
                        <h2 className="flex items-center mb-8 text-3xl font-bold">
                            Option Quotes
                        </h2>
                        <div className="flex flex-col card">
                            {selectedQuestion.options.map((questionOption) => (
                                <div
                                    className="py-4 border-b first:pt-0 last:border-none last:pb-0"
                                    key={questionOption.id}
                                >
                                    <div className="font-medium">
                                        {`${questionOption.text} Quotes`}
                                    </div>
                                    {flatMap(
                                        filteredResponseSummaries,
                                        (response) =>
                                            response.options.filter(
                                                (responseOption) =>
                                                    responseOption.option ===
                                                    questionOption.id,
                                            ),
                                    ).map((responseOption) =>
                                        responseOption.quotes.map((quote) => (
                                            <div
                                                className="mt-2 w-1/2 mx-auto"
                                                key={quote.id}
                                            >
                                                <QuoteDisplay quote={quote} />
                                            </div>
                                        )),
                                    )}
                                </div>
                            ))}
                        </div>
                    </section>
                </div>
            </section>
        </PageWithHeader>
    );
};

export default QuestionPage;
