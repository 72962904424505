import classNames from "classnames";
import { PropsWithChildren } from "react";

const CardTitle = ({
    children,
    className,
}: PropsWithChildren<{ className?: string }>) => {
    return (
        <div className={classNames("font-bold text-2xl", className)}>
            {children}
        </div>
    );
};

export default CardTitle;
