import classNames from "classnames";
import { useMemo } from "react";
import Select from "react-select";

import {
    AiParagraphChangeType,
    ProposedTranscriptChange,
} from "../../apps/transcript-o-matic";
import Button, { ButtonPadding, ButtonVariant } from "../../components/button";
import Textarea from "../../components/textarea";
import { TranscriptSpeaker } from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";

const ProposedChange = ({
    change,
    isDisabled,
    onApplyProposedChange,
    onDeleteProposedChange,
    onUpdateProposedChange,
}: {
    change: ProposedTranscriptChange;
    isDisabled: boolean;
    onUpdateProposedChange: (
        uuid: string,
        data: Partial<ProposedTranscriptChange>,
    ) => void;
    onApplyProposedChange: (uuid: string) => void;
    onDeleteProposedChange: (uuid: string) => void;
}) => {
    const content = useMemo(() => {
        if (change.change_type === AiParagraphChangeType.REMOVE) {
            return (
                <div className="font-bold text-red-500">
                    Remove this paragraph
                </div>
            );
        }

        if (change.isEditing) {
            return (
                <>
                    <Select
                        {...defaultSelectProps}
                        className="w-1/2 mb-1"
                        options={[
                            {
                                value: TranscriptSpeaker.GOLDPAN,
                                label: "Goldpan",
                            },
                            {
                                value: TranscriptSpeaker.INTERVIEWEE,
                                label: "Interviewee",
                            },
                        ]}
                        value={{
                            value: change.speaker,
                            label: change.speaker.toString(),
                        }}
                        onChange={(opt) => {
                            onUpdateProposedChange(change.uuid, {
                                speaker: assertNonNull(opt).value,
                            });
                        }}
                    />
                    <Textarea
                        className="h-[200px]"
                        value={change.dialogue}
                        onChange={(dialogue) =>
                            onUpdateProposedChange(change.uuid, { dialogue })
                        }
                    />
                </>
            );
        }

        return (
            <>
                <div className="font-bold">{change.speaker}</div>
                <div>{change.dialogue}</div>
            </>
        );
    }, [change, onUpdateProposedChange]);
    return (
        <div
            className={classNames("p-4 rounded-md shadow-md relative h-full", {
                "bg-green-100":
                    change.change_type === AiParagraphChangeType.CHANGE,
                "bg-red-100":
                    change.change_type === AiParagraphChangeType.REMOVE,
            })}
        >
            {change.change_type === AiParagraphChangeType.CHANGE &&
                !change.isEditing && (
                    <Button
                        className="absolute top-1 right-24 no-bg icon-only"
                        disabled={isDisabled}
                        icon="edit"
                        padding={ButtonPadding.SLIM}
                        onClick={() =>
                            onUpdateProposedChange(change.uuid, {
                                isEditing: true,
                            })
                        }
                    />
                )}
            <Button
                className="absolute top-1 right-12 no-bg icon-only"
                disabled={isDisabled}
                icon="save"
                padding={ButtonPadding.SLIM}
                tooltip="Apply change"
                variant={ButtonVariant.SUCCESS}
                onClick={() => onApplyProposedChange(change.uuid)}
            />
            <Button
                className="absolute top-1 right-1 no-bg icon-only"
                disabled={isDisabled}
                icon="delete"
                padding={ButtonPadding.SLIM}
                tooltip="Ignore change"
                variant={ButtonVariant.DANGER}
                onClick={() => onDeleteProposedChange(change.uuid)}
            />
            {content}
        </div>
    );
};

export default ProposedChange;
