import { DraggableSyntheticListeners } from "@dnd-kit/core";
import classNames from "classnames";
import React, { forwardRef, useCallback, useState } from "react";

import { saveQuestionGroup } from "../../../api";
import { Messages } from "../../../apps/message-list";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { setQuestionGroups } from "../../../stores/project";
import { QuestionGroup } from "../../../types/goldpan";
import { assertNonNull } from "../../../utils";
import Button, { ButtonPadding, ButtonVariant } from "../../button";
import Icon from "../../icon";
import Input from "../../input";

interface Props {
    children: React.ReactNode;
    group?: QuestionGroup;
    disabled?: boolean;
    style?: React.CSSProperties;
    listeners?: DraggableSyntheticListeners;
    onRemove?(): void;
}

const QuestionGroupItem = forwardRef<HTMLDivElement, Props>(
    ({ children, disabled, group, listeners, onRemove, style }: Props, ref) => {
        const dispatch = useAppDispatch();
        const questionGroups = useAppSelector(
            (state) => state.project.questionGroups,
        );
        const project = assertNonNull(
            useAppSelector((state) => state.project.project),
        );
        const [isEditing, setIsEditing] = useState(false);
        const [isSaving, setIsSaving] = useState(false);
        const [proposedLabel, setProposedLabel] = useState(group?.title ?? "");

        const handleResetLabel = useCallback(() => {
            if (!group) {
                return;
            }
            setProposedLabel(group.title);
            setIsEditing(false);
        }, [group]);
        const handleSaveQuestionGroup = useCallback(async () => {
            if (!group) {
                return;
            }
            setIsSaving(true);
            const response = await saveQuestionGroup(project.id, {
                ...group,
                title: proposedLabel,
            });
            setIsSaving(false);
            if (response) {
                dispatch(
                    setQuestionGroups(
                        questionGroups.map((g) => {
                            if (g.id === group.id) {
                                return response.data;
                            }
                            return g;
                        }),
                    ),
                );
                setProposedLabel(response.data.title);
                setIsEditing(false);
                Messages.success("Question group saved");
            }
        }, [dispatch, group, project, proposedLabel, questionGroups]);

        return (
            <div
                className={classNames("rounded-md shadow-md", {
                    "bg-gray-100 border border-4 border-dashed border-gray-200":
                        disabled,
                    "bg-white": !disabled,
                })}
                ref={ref}
                style={style}
            >
                <div className="flex items-center justify-between border-b p-4">
                    <div className="flex items-center">
                        {isEditing ? (
                            <>
                                <Input
                                    className="question-group-title-input"
                                    value={proposedLabel}
                                    onChange={setProposedLabel}
                                />
                                <Button
                                    className="save-question-group-btn save-ai-desc text-sm ml-2"
                                    icon="save"
                                    isLoading={isSaving}
                                    padding={ButtonPadding.SLIM}
                                    variant={ButtonVariant.SUCCESS}
                                    onClick={handleSaveQuestionGroup}
                                />
                                <Button
                                    className="text-sm ml-1"
                                    disabled={isSaving}
                                    icon="close"
                                    padding={ButtonPadding.SLIM}
                                    variant={ButtonVariant.DANGER}
                                    onClick={handleResetLabel}
                                />
                            </>
                        ) : (
                            <div className="font-bold text-lg">
                                {group ? group.title : "Ungrouped"}
                            </div>
                        )}
                    </div>
                    {!disabled && !isEditing && (
                        <div className="flex items-center justify-end gap-4">
                            <Button
                                className="edit-question-group-btn no-bg"
                                icon="edit"
                                onClick={() => setIsEditing(true)}
                            />
                            {onRemove && (
                                <Button
                                    className="no-bg"
                                    icon="delete"
                                    variant={ButtonVariant.DANGER}
                                    onClick={onRemove}
                                />
                            )}
                            <Icon
                                className="w-4 h-4 cursor-grabbing"
                                icon="handle"
                                {...listeners}
                            />
                        </div>
                    )}
                </div>
                <div className="p-2">{children}</div>
            </div>
        );
    },
);
QuestionGroupItem.displayName = "QuestionGroupItem";

export default QuestionGroupItem;
