import { uniqBy } from "lodash";
import { useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";

import FilterSidebar from "../components/filters/filter-sidebar";
import Loader from "../components/loader";
import { filterInterviews } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";
import { themePageUrl } from "../urls";
import { assertNonNull, defaultSelectProps, getPercentage } from "../utils";

enum DecisionExplorerGrouping {
    Theme = "Theme",
    Subtheme = "Subtheme",
}
const groupingOptions = [
    { label: "Theme", value: DecisionExplorerGrouping.Theme },
    { label: "Subtheme", value: DecisionExplorerGrouping.Subtheme },
];

const GroupingItem = ({
    percentage,
    themeId,
    title,
}: {
    title: string;
    themeId: number;
    percentage: number;
}) => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    return (
        <div className="flex">
            <div className="flex flex-row flex-1 text-sm pr-4">
                <div className="flex flex-col flex-1">{title}</div>
                <Link to={themePageUrl(projectId, themeId)}>
                    Details
                    {"->"}
                </Link>
                <div className="flex flex-col px-4 text-center w-[80px]">
                    {percentage}%
                </div>
            </div>
            <div className="flex flex-col flex-1 justify-center">
                <div className="flex w-full h-3 rounded-lg bg-gray-200">
                    <div
                        className="flex h-3 rounded-lg bg-gp-blue-light"
                        style={{
                            width: `${percentage}%`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

const DecisionsExplorer = () => {
    const isLoading = useAppSelector((state) => state.project.isProjectLoading);
    const subthemes = useAppSelector((state) => state.project.subthemes);
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );
    const decisionReasons = useAppSelector(
        (state) => state.project.decisionReasons,
    );

    const [selectedGrouping, setSelectedGrouping] = useState(
        DecisionExplorerGrouping.Theme,
    );
    const [selectedFilters, setSelectedFilters] = useFilterParams();

    const themes = useMemo(
        () =>
            uniqBy(
                subthemes.map((subtheme) => subtheme.parent),
                "id",
            ),
        [subthemes],
    );

    const filteredInterviews = useMemo(() => {
        return filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
    }, [interviewMetadataValues, interviews, selectedFilters]);

    const filteredDecisions = useMemo(
        () =>
            decisionReasons.filter((decision) =>
                filteredInterviews
                    .map((interview) => interview.id)
                    .includes(decision.interview),
            ),
        [decisionReasons, filteredInterviews],
    );

    if (isLoading) {
        return (
            <div className="flex justify-center mt-4">
                <Loader large />
            </div>
        );
    }

    return (
        <section className="grid grid-cols-[350px_1fr] gap-4">
            <FilterSidebar
                activeFilters={selectedFilters}
                hideCompetitors
                onChangeFilters={setSelectedFilters}
            />
            <div className="flex flex-col gap-2">
                <div className="flex items-center">
                    <div>Decision reasons by</div>
                    <Select
                        {...defaultSelectProps}
                        className="ml-2"
                        isClearable={false}
                        isMulti={false}
                        options={groupingOptions}
                        value={groupingOptions.find(
                            (opt) => opt.value === selectedGrouping,
                        )}
                        onChange={(opt) => setSelectedGrouping(opt!.value)}
                    />
                </div>

                <div className="card flex flex-col gap-4">
                    {selectedGrouping === DecisionExplorerGrouping.Theme
                        ? themes.map((theme) => {
                              const themeDecisions = filteredDecisions.filter(
                                  (decision) => {
                                      const subtheme = subthemes.find(
                                          (subtheme) =>
                                              subtheme.id === decision.subtheme,
                                      );
                                      return subtheme?.parent.id === theme.id;
                                  },
                              );
                              const percentage = getPercentage(
                                  themeDecisions.length,
                                  filteredDecisions.length,
                              );
                              return (
                                  <GroupingItem
                                      key={theme.id}
                                      percentage={percentage}
                                      themeId={assertNonNull(theme.id)}
                                      title={theme.name}
                                  />
                              );
                          })
                        : subthemes.map((subtheme) => {
                              const themeDecisions = filteredDecisions.filter(
                                  (decision) =>
                                      subtheme.id === decision.subtheme,
                              );
                              const percentage = getPercentage(
                                  themeDecisions.length,
                                  filteredDecisions.length,
                              );
                              return (
                                  <GroupingItem
                                      key={subtheme.id}
                                      percentage={percentage}
                                      themeId={assertNonNull(
                                          subtheme.parent.id,
                                      )}
                                      title={`${subtheme.parent.name} > ${subtheme.name}`}
                                  />
                              );
                          })}
                </div>
            </div>
        </section>
    );
};

export default DecisionsExplorer;
