import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";

import { useDebounce } from "../../hooks";
import FormField from "../form-field";
import Icon, { Icons } from "../icon";
import Textarea from "../textarea";

const calculateSmithWatermanScore = (
    string1: string,
    string2: string,
    gapPenalty: number,
    matchScore: number,
    mismatchPenalty: number,
) => {
    const matrix: number[][] = Array.from(
        { length: string2.length + 1 },
        () => Array.from({ length: string1.length + 1 }).fill(0) as number[],
    );

    let maxScore = 0;
    let maxI = 0;
    let maxJ = 0;

    for (let i = 1; i <= string2.length; i++) {
        for (let j = 1; j <= string1.length; j++) {
            const match =
                string1[j - 1] === string2[i - 1]
                    ? matchScore
                    : mismatchPenalty;
            const scores = [
                matrix[i - 1][j - 1] + match,
                matrix[i - 1][j] - gapPenalty,
                matrix[i][j - 1] - gapPenalty,
                0, // Negative scores are not considered
            ];

            matrix[i][j] = Math.max(...scores, 0);

            if (matrix[i][j] > maxScore) {
                maxScore = matrix[i][j];
                maxI = i;
                maxJ = j;
            }
        }
    }

    // Backtrack to find the aligned substrings
    let i = maxI;
    let j = maxJ;
    const alignedString1 = [];
    const alignedString2 = [];

    while (matrix[i][j] !== 0) {
        const currentScore = matrix[i][j];
        const diagonalScore = matrix[i - 1][j - 1];
        const upScore = matrix[i - 1][j];
        const leftScore = matrix[i][j - 1];

        if (
            currentScore ===
            diagonalScore +
                (string1[j - 1] === string2[i - 1]
                    ? matchScore
                    : mismatchPenalty)
        ) {
            alignedString1.unshift(string1[j - 1]);
            alignedString2.unshift(string2[i - 1]);
            i--;
            j--;
        } else if (currentScore === upScore - gapPenalty) {
            alignedString1.unshift("-");
            alignedString2.unshift(string2[i - 1]);
            i--;
        } else if (currentScore === leftScore - gapPenalty) {
            alignedString1.unshift(string1[j - 1]);
            alignedString2.unshift("-");
            j--;
        }
    }

    return {
        score: maxScore,
        alignedString1: alignedString1.join(""),
        alignedString2: alignedString2.join(""),
    };
};

const getSimilarityConfidence = (quote: string, paragraph: string) => {
    return (
        calculateSmithWatermanScore(
            quote.toLowerCase(),
            paragraph.toLowerCase(),
            2,
            1,
            -1,
        ).score / quote.length
    );
};

const thresholds: {
    range: number;
    color: string;
    text: string;
    icon: Icons;
}[] = [
    {
        range: 0.85,
        color: "green-700",
        icon: "check_circle",
        text: "This quote is part of the interview paragraph",
    },
    {
        range: 0.3,
        color: "amber-500",
        icon: "warning",
        text: "This quote could be part of the interview paragraph",
    },
    {
        range: 0,
        color: "red-500",
        icon: "error",
        text: "Low confidence this quote is part of the interview paragraph",
    },
];

const QuoteInput = ({
    isDisabled,
    onChange,
    paragraph,
    quote,
}: {
    quote: string;
    paragraph?: string;
    isDisabled: boolean;
    onChange: (text: string) => void;
}) => {
    const [score, setScore] = useState(0);

    const debouncedRequest = useDebounce(() => {
        setScore(paragraph ? getSimilarityConfidence(quote, paragraph) : 0);
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => debouncedRequest(), [paragraph, quote]);

    const message = useMemo(
        () => thresholds.find((t) => score >= t.range),
        [score],
    );

    return (
        <div>
            <FormField label="Quote">
                <Textarea
                    className="h-32 flex-grow resize-none"
                    disabled={isDisabled}
                    value={quote}
                    onChange={onChange}
                />
            </FormField>
            {paragraph && quote.trim() !== "" && message && (
                <div
                    className={classNames(
                        "flex text-xs items-center mt-0.5",
                        `text-${message.color}`,
                    )}
                >
                    <Icon icon={message.icon} />
                    <span className="ml-2">
                        {message.text} ({Math.round(score * 100)}%)
                    </span>
                </div>
            )}
        </div>
    );
};

export default QuoteInput;
