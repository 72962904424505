import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { QuestionOption, WithUUID } from "../../types/goldpan";
import QuestionOptionItem from "./question-option-item";

const SortableQuestionOption = ({
    id,
    onRemoveQuestionOption,
    onUpdateQuestionOption,
    questionOption,
}: {
    id: UniqueIdentifier;
    questionOption: WithUUID<QuestionOption>;
    onUpdateQuestionOption: (
        newQuestionOption: WithUUID<QuestionOption>,
    ) => void;
    onRemoveQuestionOption: () => void;
}) => {
    const { isDragging, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
        });

    return (
        <QuestionOptionItem
            listeners={listeners}
            questionOption={questionOption}
            ref={setNodeRef}
            style={
                {
                    transition,
                    transform: CSS.Translate.toString(transform),
                    opacity: isDragging ? 0.5 : undefined,
                } as React.CSSProperties
            }
            onRemoveQuestionOption={onRemoveQuestionOption}
            onUpdateQuestionOption={onUpdateQuestionOption}
        />
    );
};

export default SortableQuestionOption;
