import { reverse, sortBy, take } from "lodash";

import { OptionContentProps } from "./helpers";
import OptionTag from "./option-tag";

const ListQuestionOptionContent = ({
    comparisonData,
    onQuestionOptionClick,
    tagCount,
}: OptionContentProps) => {
    return comparisonData.length === 1 ? (
        <div className="mt-2 flex flex-col rounded-xl border-2 border-gp-beige-border divide-y bg-white p-5 gap-5">
            {take(
                reverse(sortBy(comparisonData[0].options, "interviewCount")),
                tagCount,
            ).map((data, index) => (
                <OptionTag
                    index={index}
                    key={data.optionName}
                    optionData={data}
                    totalInterviews={comparisonData[0].totalInterviews}
                    onClick={onQuestionOptionClick}
                />
            ))}
        </div>
    ) : (
        <div className="mt-2 grid grid-cols-2 rounded-xl border border-gp-beige-border bg-white p-5 gap-5">
            {comparisonData.map((comparisonOptionData, index) => {
                return (
                    <div
                        className="flex flex-col text-ellipsis overflow-hidden gap-3"
                        key={index}
                    >
                        <p className="font-bold border-b-2 border-gray-200 pb-2">
                            {comparisonData[index].comparison?.data}
                        </p>
                        <div className="divide-y gap-5 flex flex-col text-ellipsis overflow-hidden">
                            {take(
                                reverse(
                                    sortBy(
                                        comparisonOptionData.options,
                                        "interviewCount",
                                    ),
                                ),
                                tagCount,
                            ).map((data, index) => (
                                <OptionTag
                                    index={index}
                                    key={data.optionName}
                                    optionData={data}
                                    totalInterviews={
                                        comparisonOptionData.totalInterviews
                                    }
                                    onClick={onQuestionOptionClick}
                                />
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ListQuestionOptionContent;
