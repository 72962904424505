import { ResponsivePie } from "@nivo/pie";
import classNames from "classnames";
import { useMemo } from "react";

import { PROJECT_SUMMARY_MAX_TAGS } from "../../constants";
import { CHART_COLORS, CHART_COLORS_2 } from "../../utils";
import { OptionContentProps } from "./helpers";

type ChartData = {
    id: string;
    label: string;
    value: number;
    color: string;
    optionId?: number;
};

const SingleSelectQuestionOptionContent = ({
    comparisonData,
    onQuestionOptionClick,
    tagCount,
}: OptionContentProps) => {
    const colours =
        tagCount > PROJECT_SUMMARY_MAX_TAGS ? CHART_COLORS : CHART_COLORS_2;
    const chartData: ChartData[][] = useMemo(
        () =>
            comparisonData.map((optionData) => {
                const unselectedCount = optionData.options.reduce(
                    (total, opt) => {
                        return total - opt.interviewCount;
                    },
                    optionData.totalInterviews,
                );
                const emptyOption: ChartData = {
                    value: unselectedCount,
                    label: "No selection",
                    id: "No selection",
                    color: "#eeeeee",
                };
                const data = optionData.options
                    .sort((b, a) => a.interviewCount - b.interviewCount)
                    .map(
                        (optionData, index): ChartData => ({
                            value: optionData.interviewCount,
                            label: optionData.optionName,
                            id: optionData.optionName,
                            optionId: optionData.optionId,
                            color: colours[index % colours.length],
                        }),
                    );

                if (data.length > tagCount) {
                    let otherValue = optionData.totalInterviews;
                    for (let i = 0; i < tagCount; i++) {
                        otherValue -= data[i].value;
                    }
                    return data.slice(0, tagCount).concat({
                        value: otherValue,
                        label: "Other",
                        id: "Other",
                        optionId: -1,
                        color: colours[tagCount],
                    });
                }
                if (emptyOption.value == 0) {
                    return data;
                }
                return data.concat(emptyOption);
            }),
        [comparisonData, colours, tagCount],
    );
    return chartData.map((data, index) => (
        <div
            className="mt-2 p-2  border-2 rounded-xl flex items-center bg-white border-gp-beige-border"
            key={index}
        >
            <div className="w-2/3 h-56">
                <ResponsivePie
                    activeOuterRadiusOffset={8}
                    animate
                    borderWidth={0.5}
                    colors={{ datum: "data.color" }}
                    cornerRadius={0}
                    data={data}
                    enableArcLabels={false}
                    enableArcLinkLabels={false}
                    innerRadius={0.5}
                    isInteractive
                    margin={{
                        top: 20,
                        right: 0,
                        bottom: 20,
                        left: 0,
                    }}
                    padAngle={0.5}
                    onClick={(item) =>
                        item.data.optionId &&
                        onQuestionOptionClick &&
                        onQuestionOptionClick(item.data.optionId)
                    }
                />
            </div>
            <div className="flex flex-col w-1/2 gap-2">
                <p className="font-bold text-center pb-2">
                    {comparisonData[index].comparison?.data}
                </p>
                {data.map((legendItem) => (
                    <div
                        className={classNames(
                            "flex items-center gap-2 text-sm",
                            {
                                "hover:text-gp-blue hover:underline":
                                    legendItem.optionId &&
                                    onQuestionOptionClick,
                            },
                        )}
                        key={legendItem.id}
                        role={
                            legendItem.optionId && onQuestionOptionClick
                                ? "button"
                                : undefined
                        }
                        onClick={() =>
                            legendItem.optionId &&
                            onQuestionOptionClick &&
                            onQuestionOptionClick(legendItem.optionId)
                        }
                    >
                        <div
                            className="h-4 w-4 rounded-md border border-gray-400"
                            style={{ background: legendItem.color }}
                        ></div>
                        <div className="text-ellipsis overflow-hidden">
                            {`${legendItem.label} (${legendItem.value})`}
                        </div>
                    </div>
                ))}
                <div>
                    <p className="text-sm">
                        {comparisonData[index].totalInterviews} Total interviews
                    </p>
                </div>
            </div>
        </div>
    ));
};

export default SingleSelectQuestionOptionContent;
