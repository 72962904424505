import { useCallback } from "react";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

import Button from "../../components/button";
import Icon from "../../components/icon";
import Textarea from "../../components/textarea";
import {
    TranscriptParagraph,
    TranscriptSpeaker,
    WithUUID,
} from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import { isValidSpeaker } from "./helpers";

const InterviewParagraphEditor = ({
    interviewHasBeenCreated,
    paragraphs,
    setJsonTranscript,
}: {
    paragraphs: WithUUID<TranscriptParagraph>[];
    interviewHasBeenCreated: boolean;
    setJsonTranscript: (data: WithUUID<TranscriptParagraph>[]) => void;
}) => {
    const addParagraphAfterIndex = useCallback(
        (index: number) => {
            if (!paragraphs) {
                return;
            }

            setJsonTranscript([
                ...paragraphs.slice(0, index + 1),
                {
                    data: { speaker: null, dialogue: "" },
                    isDirty: false,
                    uuid: uuidv4(),
                },
                ...paragraphs.slice(index + 1),
            ]);
        },
        [paragraphs, setJsonTranscript],
    );
    const removeParagraph = useCallback(
        (index: number) => {
            if (!paragraphs || !confirm("Are you sure?")) {
                return;
            }
            setJsonTranscript([
                ...paragraphs.slice(0, index),
                ...paragraphs.slice(index + 1),
            ]);
        },
        [paragraphs, setJsonTranscript],
    );
    const updateParagraph = useCallback(
        (paragraph: WithUUID<TranscriptParagraph>) => {
            if (!paragraphs) {
                return;
            }
            const newJsonTranscript = paragraphs.map((oldParagraph) => {
                if (oldParagraph.uuid !== paragraph.uuid) {
                    return oldParagraph;
                }
                return {
                    ...oldParagraph,
                    ...paragraph,
                };
            });
            setJsonTranscript(newJsonTranscript);
        },
        [paragraphs, setJsonTranscript],
    );

    return paragraphs.map((paragraph, index) => (
        <div className="mt-4" key={paragraph.uuid}>
            <div className="flex items-center mb-1">
                <Select
                    {...defaultSelectProps}
                    className="transcript-speaker-select w-1/2 mr-2"
                    isDisabled={interviewHasBeenCreated}
                    options={[
                        {
                            value: TranscriptSpeaker.GOLDPAN,
                            label: "Goldpan",
                        },
                        {
                            value: TranscriptSpeaker.INTERVIEWEE,
                            label: "Interviewee",
                        },
                    ]}
                    value={{
                        value: paragraph.data.speaker,
                        label: paragraph.data.speaker?.toString(),
                    }}
                    onChange={(opt) => {
                        updateParagraph({
                            ...paragraph,
                            data: {
                                ...paragraph.data,
                                speaker: opt!.value,
                            },
                        });
                    }}
                />
                {!isValidSpeaker(paragraph.data.speaker) && (
                    <Icon
                        className="transcript-speaker-error text-red-500 font-bold text-lg mr-2"
                        icon="error"
                    />
                )}
                {!interviewHasBeenCreated && (
                    <>
                        <Button
                            className="add-paragraph-button mr-2 text-sm"
                            onClick={() => addParagraphAfterIndex(index)}
                        >
                            Add paragraph below
                        </Button>
                        <Button
                            className="remove-paragraph-button danger text-sm"
                            onClick={() => removeParagraph(index)}
                        >
                            Delete
                        </Button>
                    </>
                )}
            </div>
            <Textarea
                className="transcript-dialogue h-[150px]"
                disabled={interviewHasBeenCreated}
                value={paragraph.data.dialogue}
                onChange={(dialogue) =>
                    updateParagraph({
                        ...paragraph,
                        data: {
                            ...paragraph.data,
                            dialogue,
                        },
                    })
                }
            />
        </div>
    ));
};

export default InterviewParagraphEditor;
