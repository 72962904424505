import { useState } from "react";

import Button, { ButtonVariant } from "../../components/button";
import CreateInterviewModal from "../../components/transcript-o-matic/create-interview-modal";
import { timeFromNow } from "../../format";
import { Interview } from "../../types/goldpan";

const PreAnalysisInterviewTable = ({
    interviews,
    onSetInterview,
    projectId,
}: {
    interviews: Interview[];
    projectId: number;
    onSetInterview: (interview: Interview) => void;
}) => {
    const [isNewModalOpen, setIsNewModalOpen] = useState(false);
    return (
        <>
            <CreateInterviewModal
                isOpen={isNewModalOpen}
                projectId={projectId}
                onClose={() => setIsNewModalOpen(false)}
                onInterviewCreated={onSetInterview}
            />
            <div className="flex items-center gap-4">
                <h1 className="text-xl font-bold mb-2">In progress</h1>
                <Button
                    className="text-sm"
                    variant={ButtonVariant.PRIMARY}
                    onClick={() => setIsNewModalOpen(true)}
                >
                    Start new interview
                </Button>
            </div>
            <section className="bg-white shadow-md rounded-lg">
                <table className="w-full text-sm text-left text-gray-500 border border-gray-50 rounded-sm">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 border-b border-gray-50">
                        <tr>
                            <th className="px-6 py-3" scope="col">
                                Name
                            </th>
                            <th className="px-6 py-3" scope="col">
                                Code
                            </th>
                            <th className="px-6 py-3" scope="col">
                                Created by
                            </th>
                            <th className="px-6 py-3" scope="col">
                                Updated
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {interviews.map((interview) => (
                            <tr
                                className="bg-white border-b hover:bg-gray-50"
                                key={interview.id}
                            >
                                <th
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                                    scope="row"
                                >
                                    <Button
                                        variant={ButtonVariant.LINK}
                                        onClick={() =>
                                            onSetInterview(interview)
                                        }
                                    >
                                        {interview.name}
                                    </Button>
                                </th>
                                <td className="px-6 py-4">{interview.code}</td>
                                <td className="px-6 py-4">
                                    {interview.created_by
                                        ? interview.created_by.email
                                        : "-"}
                                </td>
                                <td className="px-6 py-4">
                                    {timeFromNow(interview.updated)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
        </>
    );
};
export default PreAnalysisInterviewTable;
