import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

import { Messages } from "../apps/message-list";
import {
    ResponseSummary,
    WithUUID,
    emptyResponseSummary,
} from "../types/goldpan";

interface InterviewParserState {
    interviewId: number | null;
    questionResponses: Record<number, WithUUID<ResponseSummary>>;
}

const initialState: InterviewParserState = {
    interviewId: null,
    questionResponses: {},
};

const interviewParserSlice = createSlice({
    name: "interviewParser",
    initialState,
    reducers: {
        setInterviewId: (state, action: PayloadAction<number | null>) => {
            state.interviewId = action.payload;
        },
        resetQuestionResponses: (state) => {
            state.questionResponses = {};
        },
        addNewQuestionResponse: (
            state,
            action: PayloadAction<{ questionId: number; interviewId: number }>,
        ) => {
            if (
                Object.keys(state.questionResponses).includes(
                    `${action.payload.questionId}`,
                )
            ) {
                Messages.error(
                    "Already has response summary for " +
                        action.payload.questionId,
                );
            } else {
                const newResponseSummary: WithUUID<ResponseSummary> = {
                    isDirty: true,
                    uuid: uuidv4(),
                    data: emptyResponseSummary(
                        action.payload.questionId,
                        action.payload.interviewId,
                    ),
                };
                state.questionResponses[action.payload.questionId] =
                    newResponseSummary;
            }
        },
        deleteQuestionResponse: (
            state,
            action: PayloadAction<{ questionId: number }>,
        ) => {
            if (
                !Object.keys(state.questionResponses).includes(
                    `${action.payload.questionId}`,
                )
            ) {
                Messages.error(
                    "Cannot find response for question " +
                        action.payload.questionId,
                );
            } else {
                delete state.questionResponses[action.payload.questionId];
            }
        },
        setQuestionResponse: (
            state,
            action: PayloadAction<{
                questionId: number;
                data: WithUUID<ResponseSummary>;
            }>,
        ) => {
            state.questionResponses[action.payload.questionId] =
                action.payload.data;
        },
    },
});

export const {
    addNewQuestionResponse,
    deleteQuestionResponse,
    resetQuestionResponses,
    setInterviewId,
    setQuestionResponse,
} = interviewParserSlice.actions;

export default interviewParserSlice.reducer;
