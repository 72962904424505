import { compact, uniq } from "lodash";

import { Comparison } from "../../comparisons";
import { Interview, Question, ResponseSummary } from "../../types/goldpan";
import { assertNonNull } from "../../utils";

export type ComparisonQuestionOptionData = {
    optionId: number;
    optionName: string;
    interviewCount: number;
};

export type ComparisonData = {
    comparison?: Comparison;
    totalInterviews: number;
    options: ComparisonQuestionOptionData[];
};

export type OptionContentProps = {
    comparisonData: ComparisonData[];
    tagCount: number;
    onQuestionOptionClick?: (questionOptionId: number) => void;
};

export const getOptionData = (
    question: Question,
    interviews: Interview[],
    questionResponseSummaries: ResponseSummary[],
    comparison?: Comparison,
): ComparisonData => {
    const interviewIds = interviews.map((i) => i.id);
    const questionResponses = questionResponseSummaries.filter(
        (response) =>
            response.question === question.id &&
            interviewIds.includes(response.interview),
    );
    const totalInterviews = interviewIds.length;

    const options = compact(
        question.options.map((questionOption) => {
            const uniqueInterviewIds = uniq(
                compact(
                    questionResponses.map((response) =>
                        response.options
                            .map((responseOpt) => responseOpt.option)
                            .includes(questionOption.id)
                            ? response.interview
                            : null,
                    ),
                ),
            );
            return uniqueInterviewIds.length > 0
                ? {
                      optionId: assertNonNull(questionOption.id),
                      optionName: questionOption.text,
                      interviewCount: uniqueInterviewIds.length,
                  }
                : null;
        }),
    );

    return {
        options,
        totalInterviews,
        comparison,
    };
};
