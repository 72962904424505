const InterviewHeading = ({
    icon,
    name,
    onClick,
    title,
}: {
    icon: JSX.Element | null;
    name: string;
    onClick?: (key: string) => void;
    title: string;
}) => {
    return (
        <button
            className="inline-flex hover:text-gray-600"
            onClick={() => onClick && onClick(name)}
        >
            {title} {icon}
        </button>
    );
};

export default InterviewHeading;
