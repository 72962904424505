import classNames from "classnames";

import { getPercentage } from "../../utils";
import { ComparisonQuestionOptionData } from "./helpers";

const OptionBar = ({
    comparing,
    onClick,
    optionData,
    totalInterviews,
}: {
    comparing: boolean;
    optionData: ComparisonQuestionOptionData;
    totalInterviews: number;
    onClick?: (questionOptionId: number) => void;
}) => {
    return (
        <div
            className={classNames(
                `w-full flex ${comparing ? "flex-col" : "flex-row"}  gap-4`,
                { [`hover:opacity-50`]: !!onClick },
            )}
            role={onClick ? "button" : undefined}
            onClick={() => onClick && onClick(optionData.optionId)}
        >
            <div className={`${comparing ? "w-full" : "w-1/2"}`}>
                <div className="font-semibold w-auto hyphens-auto break-words leading-4 pb-1">
                    {optionData.optionName}
                </div>
                <div className="text-xs">{`${getPercentage(
                    optionData.interviewCount,
                    totalInterviews,
                )}% (${
                    optionData.interviewCount
                }/${totalInterviews}) interviews`}</div>
            </div>
            <div className="flex flex-col flex-1 justify-center">
                <div className="flex w-full h-3 rounded-lg bg-gray-200">
                    <div
                        className="flex h-3 rounded-lg  bg-gp-blue "
                        style={{
                            width: `${getPercentage(
                                optionData.interviewCount,
                                totalInterviews,
                            )}%`,
                        }}
                    ></div>
                </div>
            </div>
        </div>
    );
};

export default OptionBar;
