import { useCallback, useMemo, useState } from "react";
import CreatableSelect from "react-select/creatable";

import { createSubtheme } from "../../api";
import { Messages } from "../../apps/message-list";
import { Subtheme, Theme, emptySubtheme } from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Input from "../input";
import Modal from "../modal";

const CreateThemeModal = ({
    isOpen,
    onClose,
    onNewSubtheme,
    projectId,
    subthemes,
}: {
    isOpen: boolean;
    subthemes: Subtheme[];
    projectId: number;
    onClose: () => void;
    onNewSubtheme: (subtheme: Subtheme) => void;
}) => {
    const [newSubtheme, setNewSubtheme] = useState(emptySubtheme);
    const [newParent, setNewParent] = useState<Theme | null>(null);

    const handleClose = useCallback(() => {
        setNewParent(null);
        setNewSubtheme(emptySubtheme);
        onClose();
    }, [onClose]);

    const parents = useMemo(
        () =>
            subthemes.reduce<Theme[]>((arr, subtheme) => {
                if (arr.find((parent) => parent.id == subtheme.parent.id)) {
                    return arr;
                }
                return [...arr, subtheme.parent];
            }, []),
        [subthemes],
    );

    const parentOptions = useMemo(() => {
        return [
            ...parents.map((parent) => ({
                value: parent.id!,
                label: parent.name,
            })),
            ...(newParent
                ? [{ value: newParent.id, label: newParent.name }]
                : []),
        ];
    }, [parents, newParent]);

    const isNewSubthemeValid: boolean = useMemo(() => {
        return !subthemes.find(
            (subtheme) =>
                subtheme.name === newSubtheme.name &&
                subtheme.parent.id === newSubtheme.parent.id,
        );
    }, [subthemes, newSubtheme]);

    const handleCreateSubtheme = useCallback(async () => {
        if (!newSubtheme.parent.id) {
            return;
        }
        const response = await createSubtheme(projectId, {
            ...newSubtheme,
            parent: {
                ...newSubtheme.parent,
                id: newSubtheme.parent.id < 0 ? null : newSubtheme.parent.id,
            },
        });
        if (response) {
            onNewSubtheme(response.data);
            Messages.success("New theme created successfully");
            handleClose();
        }
    }, [newSubtheme, projectId, onNewSubtheme, handleClose]);

    const handleCreateNewTheme = useCallback(
        (name: string) => {
            const newParent = {
                id: -1,
                name,
            };
            setNewParent(newParent);
            setNewSubtheme({
                ...newSubtheme,
                parent: newParent,
            });
        },
        [newSubtheme],
    );

    return (
        <Modal
            confirmTooltip={
                isNewSubthemeValid ? undefined : "Theme already exists"
            }
            isConfirmDisabled={
                newSubtheme.parent.id === null ||
                newSubtheme.name.trim() === "" ||
                !isNewSubthemeValid
            }
            isOpen={isOpen}
            title="Create new theme"
            onClose={handleClose}
            onConfirm={handleCreateSubtheme}
        >
            <CreatableSelect
                {...defaultSelectProps}
                className="parent-theme-select"
                options={parentOptions}
                placeholder="Parent theme..."
                value={
                    newSubtheme.parent.id
                        ? parentOptions.find(
                              (i) => i.value === newSubtheme.parent.id,
                          )
                        : null
                }
                onChange={(opt) =>
                    setNewSubtheme({
                        ...newSubtheme,
                        parent: {
                            id: opt!.value,
                            name: opt!.label,
                        },
                    })
                }
                onCreateOption={handleCreateNewTheme}
            />
            <Input
                className="subtheme-input mt-2"
                error={!isNewSubthemeValid}
                placeholder="Subtheme..."
                onChange={(name) =>
                    setNewSubtheme({
                        ...newSubtheme,
                        name,
                    })
                }
            />
        </Modal>
    );
};

export default CreateThemeModal;
