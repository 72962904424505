import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, unstable_usePrompt, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
    checkCeleryTask,
    deleteDecisionReason,
    parseDecisionReasons,
    saveDecisionReason,
} from "../api";
import Button, { ButtonVariant } from "../components/button";
import Icon from "../components/icon";
import Loader from "../components/loader";
import CreateThemeModal from "../components/parser/create-theme-modal";
import DecisionReasonCard from "../components/parser/decision-reason-card";
import InterviewParagraphs from "../components/parser/interview-paragraphs";
import InterviewSelector from "../components/parser/interview-selector";
import PromptOverrideModal from "../components/parser/prompt-override-modal";
import ScrollShadowWrapper from "../components/scroll-shadow-wrapper";
import UnsavedCounter from "../components/unsaved-counter";
import { useAppDispatch, useAppSelector, useCheckWhenLeaving } from "../hooks";
import { setDecisionReasons, setSubthemes } from "../stores/project";
import {
    DecisionReason,
    PromptType,
    Subtheme,
    WithUUID,
    emptyDecisionReason,
    emptyQuote,
} from "../types/goldpan";
import { Messages } from "./message-list";

type AiDecisionReason = {
    reason_summary: string;
    theme_id: string;
    quotes: {
        paragraph_quote: string;
        paragraph_id: string;
    }[];
};

const validateReason = (decisionReasonData: DecisionReason): boolean => {
    let isValid = true;
    if (!decisionReasonData.summary) {
        isValid = false;
        Messages.error("Decision reason missing summary");
    }
    if (!decisionReasonData.subtheme) {
        isValid = false;
        Messages.error("Decision reason missing theme");
    }
    if (!decisionReasonData.quotes[0].text) {
        isValid = false;
        Messages.error("Decision reason missing quote text");
    }
    return isValid;
};

const promptTypes = [PromptType.PARSE_DECISION_REASONS];

const ParseDecisionsApp: React.FC = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    const dispatch = useAppDispatch();
    const [isLoadingInterview, setIsLoadingInterview] = useState(false);
    const [isParsingDecisions, setIsParsingDecisions] = useState<
        number[] | null
    >(null);
    const [isOverrideModalOpen, setIsOverrideModalOpen] = useState(false);
    const [isCreatingNewSubtheme, setIsCreatingNewSubtheme] = useState(false);
    const subthemes = useAppSelector((state) => state.project.subthemes);
    const decisionReasons = useAppSelector(
        (state) => state.project.decisionReasons,
    );
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewId = useAppSelector(
        (state) => state.interviewParser.interviewId,
    );

    const [decisionReasonsByThemeId, setDecisionReasonsByThemeId] = useState<
        Map<number, WithUUID<DecisionReason>>
    >(new Map());

    const dirtyCount = useMemo(
        () =>
            Array.from(decisionReasonsByThemeId.values()).filter(
                (reason) => reason.isDirty,
            ).length,
        [decisionReasonsByThemeId],
    );
    const shouldCheckBeforeLeavingPage = useMemo(
        () => dirtyCount > 0,
        [dirtyCount],
    );
    useCheckWhenLeaving(shouldCheckBeforeLeavingPage);
    unstable_usePrompt({
        message: "You have unsaved changes. Are you sure you want to leave?",
        when: ({ currentLocation, nextLocation }) =>
            shouldCheckBeforeLeavingPage &&
            currentLocation.pathname !== nextLocation.pathname,
    });

    useEffect(() => {
        setDecisionReasonsByThemeId(new Map());
        if (!interviewId) {
            return;
        }
        setIsLoadingInterview(true);
        const interviewDecisionReasons = decisionReasons.filter(
            (decision) => decision.interview === interviewId,
        );
        const decisionReasonMap: Map<
            number,
            WithUUID<DecisionReason>
        > = new Map();
        for (const reason of interviewDecisionReasons) {
            const reasonData = {
                ...reason,
                quotes:
                    reason.quotes.length > 0
                        ? reason.quotes
                        : [emptyQuote(interviewId)],
            };
            decisionReasonMap.set(reason.subtheme, {
                data: reasonData,
                isDirty: false,
                uuid: uuidv4(),
            });
        }
        setDecisionReasonsByThemeId(decisionReasonMap);
        setIsLoadingInterview(false);

        // don't include decisionReasons here otherwise re-render unnecessarily
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [interviewId, projectId]);

    const interview = useMemo(
        () => interviews.find((i) => i.id === interviewId) ?? null,
        [interviews, interviewId],
    );

    const updateDecisionReason = useCallback(
        (decisionReason: WithUUID<DecisionReason>) => {
            const newReasons = new Map(decisionReasonsByThemeId);
            newReasons.set(decisionReason.data.subtheme, decisionReason);
            setDecisionReasonsByThemeId(newReasons);
        },
        [decisionReasonsByThemeId],
    );

    const handleParseDecisions = useCallback(
        async (subthemeIds?: number[]) => {
            if (!interviewId) {
                return;
            }
            Messages.removeAll();
            const subthemeList = subthemeIds
                ? subthemeIds
                : subthemes
                      .filter(
                          (subtheme) =>
                              !decisionReasonsByThemeId.has(subtheme.id!),
                      )
                      .map((subtheme) => subtheme.id!);
            setIsParsingDecisions(subthemeList);
            const response = await parseDecisionReasons(
                projectId,
                interviewId,
                subthemeList,
            );
            if (response) {
                checkCeleryTask<AiDecisionReason[]>(
                    response.data.task_id,
                    (response) => {
                        const decisionReasonMap: Map<
                            number,
                            WithUUID<DecisionReason>
                        > = new Map(decisionReasonsByThemeId);
                        response.forEach((reason) => {
                            const themeId = parseInt(reason.theme_id);
                            const subtheme = subthemes.find(
                                (s) => s.id === themeId,
                            );
                            if (!subtheme) {
                                Messages.error(
                                    "Subtheme does not exist in project",
                                );
                                return;
                            }
                            decisionReasonMap.set(themeId, {
                                data: {
                                    id: null,
                                    summary: reason.reason_summary,
                                    subtheme: parseInt(reason.theme_id),
                                    interview: interviewId,
                                    quotes: reason.quotes.map((quote) => ({
                                        ...emptyQuote(interviewId),
                                        id: null,
                                        text: quote.paragraph_quote,
                                        interview: interviewId,
                                        subtheme: parseInt(reason.theme_id),
                                        interview_paragraph:
                                            interview!.paragraphs.find(
                                                (p) =>
                                                    p.ordinal ===
                                                    parseInt(
                                                        quote.paragraph_id,
                                                    ),
                                            )!.id,
                                    })),
                                },
                                isDirty: true,
                                uuid: uuidv4(),
                            });
                        });
                        setDecisionReasonsByThemeId(decisionReasonMap);
                        Messages.success("Decisions ready for review!");
                    },
                    () => setIsParsingDecisions(null),
                );
            } else {
                setIsParsingDecisions(null);
            }
        },
        [
            interview,
            projectId,
            interviewId,
            subthemes,
            decisionReasonsByThemeId,
        ],
    );

    const handleAddNewDecisionReason = useCallback(
        (subthemeId: number) => {
            if (!interviewId) {
                return;
            }
            const newDescision: WithUUID<DecisionReason> = {
                isDirty: true,
                uuid: uuidv4(),
                data: emptyDecisionReason(interviewId, subthemeId),
            };
            if (decisionReasonsByThemeId.has(subthemeId)) {
                Messages.error("Already has decision for " + subthemeId);
            }
            const decisionReasonMap = new Map(decisionReasonsByThemeId);
            decisionReasonMap.set(subthemeId, newDescision);

            setDecisionReasonsByThemeId(decisionReasonMap);
        },
        [interviewId, decisionReasonsByThemeId],
    );

    const handleSaveReason = useCallback(
        async (subthemeId: number) => {
            Messages.removeAll();
            const reason = decisionReasonsByThemeId.get(subthemeId);
            if (reason === undefined) {
                Messages.error("Cannot find reason for subtheme " + subthemeId);
                return;
            }
            const isNew = reason.data.id === null;
            if (interviewId && validateReason(reason.data)) {
                const response = await saveDecisionReason(
                    projectId,
                    reason.data,
                );
                if (!response) {
                    return;
                }
                updateDecisionReason({
                    data: response.data,
                    uuid: reason.uuid,
                    isDirty: false,
                });
                if (isNew) {
                    dispatch(
                        setDecisionReasons(
                            decisionReasons.concat([response.data]),
                        ),
                    );
                } else {
                    dispatch(
                        setDecisionReasons(
                            decisionReasons.map((decision) => {
                                if (decision.id === response.data.id) {
                                    return response.data;
                                }
                                return decision;
                            }),
                        ),
                    );
                }
                Messages.success("Saved successfully");
            }
        },
        [
            decisionReasonsByThemeId,
            interviewId,
            projectId,
            updateDecisionReason,
            dispatch,
            decisionReasons,
        ],
    );
    const handleDeleteReason = useCallback(
        async (subthemeId: number) => {
            Messages.removeAll();
            const reason = decisionReasonsByThemeId.get(subthemeId);
            if (reason === undefined) {
                Messages.error("Cannot find reason for subtheme " + subthemeId);
                return;
            }
            if (interviewId) {
                if (reason.data.id !== null) {
                    const response = await deleteDecisionReason(
                        projectId,
                        reason.data,
                    );
                    if (!response) {
                        return;
                    }
                }
                const newDecisionMap = new Map(decisionReasonsByThemeId);
                newDecisionMap.delete(subthemeId);
                setDecisionReasonsByThemeId(newDecisionMap);
                dispatch(
                    setDecisionReasons(
                        decisionReasons.filter(
                            (decision) => decision.id !== reason.data.id,
                        ),
                    ),
                );
                Messages.success("Decision reason deleted");
            }
        },
        [
            decisionReasons,
            decisionReasonsByThemeId,
            dispatch,
            interviewId,
            projectId,
        ],
    );

    const handleNewSubtheme = useCallback(
        (subtheme: Subtheme) => {
            dispatch(setSubthemes([...subthemes, subtheme]));
        },
        [subthemes, dispatch],
    );

    const paragraphOptions = useMemo(
        () =>
            interview?.paragraphs.map((paragraph) => ({
                value: paragraph.id,
                label: `Paragraph ${paragraph.ordinal}`,
            })) ?? [],
        [interview],
    );

    return (
        <>
            <div className="w-full mt-4 mb-8 flex items-center justify-between">
                <div className="flex items-center">
                    <div className="w-[500px]">
                        <InterviewSelector
                            disabled={isParsingDecisions !== null}
                            interviews={interviews}
                            selectedInterviewId={interviewId}
                        />
                    </div>
                    {interviewId !== null && (
                        <>
                            {isOverrideModalOpen && (
                                <PromptOverrideModal
                                    projectId={projectId}
                                    promptTypes={promptTypes}
                                    onClose={() =>
                                        setIsOverrideModalOpen(false)
                                    }
                                />
                            )}
                            <div className="flex items-center gap-2">
                                <Button
                                    className="ml-4"
                                    disabled={isParsingDecisions !== null}
                                    id="parse-decisions-button"
                                    isLoading={
                                        isParsingDecisions !== null &&
                                        isParsingDecisions.length > 1
                                    }
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={() => handleParseDecisions()}
                                >
                                    Parse all unstarted
                                </Button>
                                <Button
                                    className="no-bg"
                                    tooltip="AI prompt settings"
                                    onClick={() => setIsOverrideModalOpen(true)}
                                >
                                    <Icon icon="settings" />
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                {interviewId !== null && (
                    <div className="flex items-center">
                        <UnsavedCounter dirtyCount={dirtyCount} />
                        <Link
                            className="button px-3.5 py-2 ml-4"
                            to={`/project/${projectId}/interview-parser/questions/?interview=${interviewId}`}
                        >
                            Next step
                        </Link>
                    </div>
                )}
            </div>

            {isLoadingInterview && (
                <div className="flex justify-center mt-8">
                    <Loader large />
                </div>
            )}

            {interview !== null && !isLoadingInterview && (
                <div className="w-full flex flex-grow gap-2 ai-parser-height">
                    <CreateThemeModal
                        isOpen={isCreatingNewSubtheme}
                        projectId={projectId}
                        subthemes={subthemes}
                        onClose={() => setIsCreatingNewSubtheme(false)}
                        onNewSubtheme={handleNewSubtheme}
                    />
                    <div className="w-3/5 flex flex-col">
                        <div className="flex items-center mb-4">
                            <h3>Decision reasons</h3>
                            <Button
                                className="ml-4 text-sm"
                                disabled={isParsingDecisions !== null}
                                icon="add"
                                id="add-theme-button"
                                onClick={() => setIsCreatingNewSubtheme(true)}
                            >
                                Add new theme
                            </Button>
                        </div>
                        <ScrollShadowWrapper>
                            {subthemes.map((subtheme) => (
                                <DecisionReasonCard
                                    decisionReason={decisionReasonsByThemeId.get(
                                        subtheme.id!,
                                    )}
                                    interviewId={interview.id}
                                    interviewParagraphs={interview.paragraphs}
                                    isDisabled={isParsingDecisions !== null}
                                    isParsingDecisions={isParsingDecisions}
                                    key={`${interviewId}_${subtheme.id}`}
                                    paragraphOptions={paragraphOptions}
                                    subtheme={subtheme}
                                    onAddNewDecisionReason={
                                        handleAddNewDecisionReason
                                    }
                                    onDeleteDecisionReason={handleDeleteReason}
                                    onParseDecisionReason={() =>
                                        handleParseDecisions([subtheme.id!])
                                    }
                                    onSaveDecisionReason={handleSaveReason}
                                    onUpdateDecisionReason={
                                        updateDecisionReason
                                    }
                                />
                            ))}
                        </ScrollShadowWrapper>
                    </div>
                    <div className="w-2/5 flex flex-col">
                        <h3 className="mb-4">Transcript</h3>
                        <ScrollShadowWrapper>
                            <InterviewParagraphs
                                paragraphs={interview.paragraphs}
                            />
                        </ScrollShadowWrapper>
                    </div>
                </div>
            )}
        </>
    );
};

export default ParseDecisionsApp;
