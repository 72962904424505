import Tippy from "@tippyjs/react";

import Button, { ButtonVariant } from "../button";

const NextStepButton = ({
    onGoToNextStep,
    stepValidationErrors,
}: {
    stepValidationErrors: string[];
    onGoToNextStep: () => void;
}) => (
    <Button
        className="mt-4 text-lg"
        disabled={stepValidationErrors.length > 0}
        id="next-step-button"
        variant={ButtonVariant.PRIMARY}
        onClick={onGoToNextStep}
    >
        {stepValidationErrors.length > 0 ? (
            <Tippy content={stepValidationErrors[0]}>
                <span className="w-full">Next step</span>
            </Tippy>
        ) : (
            "Next step"
        )}
    </Button>
);

export default NextStepButton;
