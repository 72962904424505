import { noop } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import {
    checkCeleryTask,
    getInterviews,
    saveInterviewParagraphs,
    updateInterviewParagraphs,
} from "../api";
import Button, { ButtonVariant } from "../components/button";
import Icon from "../components/icon";
import Loader from "../components/loader";
import Textarea from "../components/textarea";
import AudioFileUpload from "../components/transcript-o-matic/audio-file-upload";
import PreAnalysisInterviewTable from "../components/transcript-o-matic/pre-analysis-interview-table";
import TranscriptRefinement from "../components/transcript-o-matic/transcript-refinement";
import {
    CeleryTaskResponse,
    Interview,
    InterviewParagraph,
    InterviewStatus,
    TranscriptSpeaker,
} from "../types/goldpan";
import { assertNonNull } from "../utils";
import { Messages } from "./message-list";

export enum AiParagraphChangeType {
    REMOVE = "remove",
    CHANGE = "change",
}
export type AiParagraphChange =
    | {
          change_type: AiParagraphChangeType.REMOVE;
          paragraph_id: number;
      }
    | {
          change_type: AiParagraphChangeType.CHANGE;
          paragraph_id: number;
          speaker: TranscriptSpeaker;
          dialogue: string;
      };

export type ProposedTranscriptChange = AiParagraphChange & {
    isEditing?: boolean;
    uuid: string;
};

const rawTranscriptToParagraphs = (rawTranscript: string) =>
    rawTranscript.split("\n\n").map((paragraph) => {
        const speaker: TranscriptSpeaker = /^Speaker 0:/.test(paragraph)
            ? TranscriptSpeaker.GOLDPAN
            : TranscriptSpeaker.INTERVIEWEE;
        return {
            speaker,
            dialogue: paragraph.replace(/^Speaker \d+:/g, "").trim(),
        };
    });

const TranscriptOMatic = () => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);

    const [isLoading, setIsLoading] = useState(true);
    const [isUploadingAudio, setIsUploadingAudio] = useState(false);
    const [isSavingInterviewParagraphs, setIsSavingInterviewParagraphs] =
        useState(false);
    const [preAnalysisInterviews, setPreAnalysisInterviews] = useState<
        Interview[]
    >([]);
    const [selectedInterview, setSelectedInterview] =
        useState<Interview | null>(null);
    const [rawTranscript, setRawTranscript] = useState("");

    useEffect(() => {
        getInterviews(projectId, { status: InterviewStatus.PRE_ANALYSIS })
            .then((response) => {
                if (response) {
                    setPreAnalysisInterviews(response.data);
                }
            })
            .finally(() => setIsLoading(false));
    }, [projectId]);

    const handleSaveInterviewParagraphs = useCallback(
        (raw: string) => {
            const paragraphs = rawTranscriptToParagraphs(raw);
            setIsSavingInterviewParagraphs(true);
            saveInterviewParagraphs(
                projectId,
                assertNonNull(selectedInterview?.id),
                {
                    json: paragraphs,
                    raw: rawTranscript.trim() !== "" ? rawTranscript : null,
                },
            )
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    setSelectedInterview(response.data);
                    Messages.success("Transcript ready for refinement");
                })
                .finally(() => setIsSavingInterviewParagraphs(false));
        },
        [projectId, selectedInterview, rawTranscript],
    );

    const handleFileUploadSuccess = useCallback(
        async (response: CeleryTaskResponse) => {
            checkCeleryTask<string>(
                response.task_id,
                (response) => {
                    setIsSavingInterviewParagraphs(true);
                    handleSaveInterviewParagraphs(response);
                },
                noop,
            );
        },
        [handleSaveInterviewParagraphs],
    );

    const handleUpdateInterviewParagraphs = useCallback(
        (paragraphs: InterviewParagraph[]) => {
            setIsSavingInterviewParagraphs(true);
            updateInterviewParagraphs(
                projectId,
                assertNonNull(selectedInterview?.id),
                paragraphs,
            )
                .then((response) => {
                    if (!response) {
                        return;
                    }
                    setSelectedInterview(response.data);
                    Messages.success("Change applied successfully");
                })
                .finally(() => setIsSavingInterviewParagraphs(false));
        },
        [projectId, selectedInterview],
    );

    const rawTranscriptIsValid = useMemo(
        () => /^Speaker \d+:/.test(rawTranscript),
        [rawTranscript],
    );

    if (isLoading) {
        return (
            <div className="flex justify-center mt-16">
                <Loader large />
            </div>
        );
    }

    if (!selectedInterview) {
        return (
            <PreAnalysisInterviewTable
                interviews={preAnalysisInterviews}
                projectId={projectId}
                onSetInterview={setSelectedInterview}
            />
        );
    }

    if (selectedInterview.paragraphs.length === 0) {
        return (
            <div className="mx-16 flex flex-col ai-transcript-height">
                <Textarea
                    className="flex-grow resize-none mb-4"
                    disabled={isSavingInterviewParagraphs || isUploadingAudio}
                    id="raw-transcript-input"
                    placeholder="Speaker 0: Transcript text&#10;&#10;Speaker 1: Hi!&#10;&#10;..."
                    value={rawTranscript}
                    onChange={setRawTranscript}
                    onPaste={(evt) => {
                        setRawTranscript(
                            evt.clipboardData
                                .getData("text")
                                .replace(
                                    /speaker (\d+):\n\n/gi,
                                    "Speaker $1: ",
                                ),
                        );
                        evt.preventDefault();
                    }}
                />

                <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4">
                        {!isUploadingAudio && (
                            <>
                                <Button
                                    disabled={
                                        !rawTranscriptIsValid ||
                                        isSavingInterviewParagraphs
                                    }
                                    tooltip={
                                        rawTranscript.trim() !== "" &&
                                        !rawTranscriptIsValid
                                            ? "Transcript does not match expected format"
                                            : undefined
                                    }
                                    variant={ButtonVariant.PRIMARY}
                                    onClick={() =>
                                        handleSaveInterviewParagraphs(
                                            rawTranscript,
                                        )
                                    }
                                >
                                    Load transcript
                                </Button>
                                <div className="font-bold">OR</div>
                            </>
                        )}
                        {(isUploadingAudio || !isSavingInterviewParagraphs) && (
                            <AudioFileUpload
                                interviewId={selectedInterview.id}
                                onUploadFailure={() =>
                                    setIsUploadingAudio(false)
                                }
                                onUploadStarted={() =>
                                    setIsUploadingAudio(true)
                                }
                                onUploadSuccess={handleFileUploadSuccess}
                            />
                        )}
                        {(isUploadingAudio || isSavingInterviewParagraphs) && (
                            <Icon
                                className="text-lg loader-spinner animate-spin"
                                icon="spinner"
                            />
                        )}
                    </div>
                    {!isSavingInterviewParagraphs && !isUploadingAudio && (
                        <Button
                            className="no-bg"
                            onClick={() => setSelectedInterview(null)}
                        >
                            Cancel
                        </Button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <TranscriptRefinement
            interview={selectedInterview}
            projectId={projectId}
            onUpdateInterviewParagraphs={handleUpdateInterviewParagraphs}
        />
    );
};

export default TranscriptOMatic;
