import classNames from "classnames";
import { sortBy } from "lodash";
import { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";

import { Question, ResponseSummary } from "../../types/goldpan";
import Card from "../layout/card";
import CardTitle from "../layout/card-title";
import SubCard from "../layout/sub-card";
import QuoteSlim from "./quote-slim";

const QuestionGroupResponses = ({
    detailLink,
    groupQuestions,
    interviewResponseSummaries,
    title,
}: {
    detailLink?: string;
    title: string;
    groupQuestions: Question[];
    interviewResponseSummaries: ResponseSummary[];
}) => {
    const [showQuotes, setQuotes] = useState<(number | null)[]>([]);
    return (
        <Card>
            <CardTitle className="mb-1">{title}</CardTitle>
            {detailLink && (
                <div className="pb-9">
                    <Link className="text-sm" to={detailLink}>
                        See Details ↗{" "}
                    </Link>
                </div>
            )}
            <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2 }}>
                <Masonry gutter="1.5rem">
                    {groupQuestions
                        .filter(
                            (question) =>
                                interviewResponseSummaries.filter(
                                    (response) =>
                                        response.question === question.id,
                                ).length > 0,
                        )
                        .map((question) => {
                            const responses = interviewResponseSummaries.filter(
                                (response) => response.question === question.id,
                            );

                            return (
                                <SubCard key={question.id}>
                                    <div className="font-semibold text-lg leading-snug">
                                        {question.text}
                                    </div>

                                    <div className="text-sm pb-4">
                                        {question.subtext
                                            ? question.subtext
                                            : ""}
                                    </div>

                                    <div className="flex flex-col mt-2">
                                        {responses.map((response) => {
                                            const isShowingQuotes =
                                                showQuotes.includes(
                                                    response.id,
                                                );
                                            const sortedOptions = sortBy(
                                                response.options,
                                                (responseOption) => {
                                                    const selectedOption =
                                                        question.options.find(
                                                            (opt) => {
                                                                return (
                                                                    opt.id ===
                                                                    responseOption.option
                                                                );
                                                            },
                                                        );
                                                    return (
                                                        selectedOption?.ordinal ??
                                                        1
                                                    );
                                                },
                                            );
                                            return (
                                                <div
                                                    id={`question_response_${response.id}`}
                                                    key={response.id}
                                                >
                                                    <div
                                                        className={`flex ${
                                                            isShowingQuotes
                                                                ? "flex-col"
                                                                : "flex-row flex-wrap gap-3"
                                                        }`}
                                                    >
                                                        {sortedOptions.map(
                                                            (
                                                                responseOption,
                                                            ) => {
                                                                const option =
                                                                    question.options.find(
                                                                        (opt) =>
                                                                            opt.id ===
                                                                            responseOption.option,
                                                                    );

                                                                return (
                                                                    <div
                                                                        key={
                                                                            responseOption.id
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={classNames(
                                                                                "font-medium text-sm rounded-md bg-gp-teal-100 py-2 px-3 w-fit",
                                                                                {
                                                                                    "my-2": isShowingQuotes,
                                                                                },
                                                                            )}
                                                                        >
                                                                            {
                                                                                option?.text
                                                                            }
                                                                        </div>
                                                                        {isShowingQuotes && (
                                                                            <div className="flex flex-col items-center">
                                                                                {responseOption.quotes.map(
                                                                                    (
                                                                                        quote,
                                                                                    ) => (
                                                                                        <QuoteSlim
                                                                                            key={
                                                                                                quote.id
                                                                                            }
                                                                                            quote={
                                                                                                quote
                                                                                            }
                                                                                        />
                                                                                    ),
                                                                                )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                );
                                                            },
                                                        )}
                                                    </div>
                                                    <button
                                                        className="w-full"
                                                        onClick={() =>
                                                            !isShowingQuotes
                                                                ? setQuotes(
                                                                      (
                                                                          prevState,
                                                                      ) => [
                                                                          ...prevState,
                                                                          response.id,
                                                                      ],
                                                                  )
                                                                : setQuotes(
                                                                      (
                                                                          prevState,
                                                                      ) =>
                                                                          prevState.filter(
                                                                              (
                                                                                  id,
                                                                              ) =>
                                                                                  id !==
                                                                                  response.id,
                                                                          ),
                                                                  )
                                                        }
                                                    >
                                                        <div className="flex flex-row w-full items-center mt-5">
                                                            <hr className="flex-grow border-gp-beige-dark rounded-full" />
                                                            <span className="px-2 text-gp-beige-dark text-xs">
                                                                {isShowingQuotes
                                                                    ? "Hide Quotes"
                                                                    : "Show Quotes"}
                                                            </span>
                                                            <hr className="flex-grow border-gp-beige-dark rounded-full" />
                                                        </div>
                                                    </button>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </SubCard>
                            );
                        })}
                </Masonry>
            </ResponsiveMasonry>
        </Card>
    );
};

export default QuestionGroupResponses;
