import { flatMap, uniq } from "lodash";
import { useMemo, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useParams } from "react-router-dom";
import Select from "react-select";

import { Comparison } from "../comparisons";
import ComparisonSidebar from "../components/filters/comparison-sidebar";
import FilterSidebar from "../components/filters/filter-sidebar";
import InterviewPopover from "../components/interview-popover";
import ProjectSummaryQuestionGroup from "../components/project-summary/project-summary-question-group";
import { filterInterviews } from "../filters";
import { useAppSelector, useFilterParams } from "../hooks";
import { PageWithHeader } from "../routers/project-router";
import { Quote, SelectOption } from "../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../utils";

const QuestionGroupQuote = ({ quote }: { quote: Quote }) => {
    const interviews = useAppSelector((state) => state.project.interviews);
    const questions = useAppSelector((state) => state.project.questions);

    const questionOption = useMemo(() => {
        const options = flatMap(questions, (question) =>
            question.options.map((option) => ({
                id: option.id,
                text: option.text,
                question: question.text,
            })),
        );
        return options.find((option) => option.id === quote.question_option);
    }, [questions, quote]);
    const interview = useMemo(
        () => interviews.find(({ id }) => id === quote.interview),
        [interviews, quote],
    );
    return (
        <div className="shadow-md bg-white rounded">
            <div className="whitespace-pre-line rounded-t bg-blue-50 border-l-4 border-gp-blue p-4 text-lg font-medium">
                {quote.text}
            </div>
            <div className="p-4 flex items-center justify-between">
                {interview && <InterviewPopover interview={interview} />}
                {questionOption && (
                    <div className="text-ellipsis overflow-hidden text-right">{`${questionOption.question} - ${questionOption.text}`}</div>
                )}
            </div>
        </div>
    );
};

const QuestionGroup = () => {
    const { questionGroupSlug } = useParams();
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );
    const questions = useAppSelector((state) => state.project.questions);
    const quotes = useAppSelector((state) => state.project.quotes);
    const interviews = useAppSelector((state) => state.project.interviews);
    const interviewMetadataValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );
    const [selectedFilters, setSelectedFilters] = useFilterParams();
    const [activeComparison, setSelectedComparison] = useState<Comparison[]>(
        [],
    );
    const [selectedQuestionOptions, setSelectedQuesitonOptions] = useState<
        number[]
    >([]);

    const questionGroup = useMemo(
        () => questionGroups.find((group) => group.slug === questionGroupSlug),
        [questionGroups, questionGroupSlug],
    );

    const allQuestionOptions = useMemo(
        () =>
            questionGroup
                ? flatMap(
                      questions.filter(
                          (question) => question.group === questionGroup.id,
                      ),
                      (question) => question.options,
                  )
                : [],
        [questionGroup, questions],
    );
    const allQuestionOptionsForSelect: SelectOption[] = useMemo(
        () =>
            questionGroup
                ? flatMap(
                      questions.filter(
                          (question) => question.group === questionGroup.id,
                      ),
                      (question) =>
                          question.options.map((option) => ({
                              label: `${question.text} - ${option.text}`,
                              value: assertNonNull(option.id),
                          })),
                  )
                : [],
        [questionGroup, questions],
    );

    const filteredQuotes = useMemo(() => {
        const filteredInterviews = filterInterviews(
            interviews,
            selectedFilters,
            interviewMetadataValues,
        );
        return quotes.filter(
            (quote) =>
                filteredInterviews
                    .map((interview) => interview.id)
                    .includes(quote.interview) &&
                quote.question_option &&
                allQuestionOptions
                    .filter(
                        (option) =>
                            selectedQuestionOptions.length === 0 ||
                            selectedQuestionOptions.includes(
                                assertNonNull(option.id),
                            ),
                    )
                    .map((option) => option.id)
                    .includes(quote.question_option),
        );
    }, [
        allQuestionOptions,
        interviewMetadataValues,
        interviews,
        quotes,
        selectedFilters,
        selectedQuestionOptions,
    ]);
    if (!questionGroup) {
        return <div className="mt-8 text-center">Insight not found</div>;
    }

    return (
        <PageWithHeader heading={`Insights: ${questionGroup.title}`}>
            <section className="grid grid-cols-1 lg:grid-cols-[350px_1fr] gap-4">
                <div className="hidden lg:flex flex flex-col gap-2">
                    <FilterSidebar
                        activeComparison={activeComparison}
                        activeFilters={selectedFilters}
                        hideCompetitors
                        onChangeFilters={setSelectedFilters}
                    />
                    <ComparisonSidebar
                        activeComparison={activeComparison}
                        onChangeComparison={setSelectedComparison}
                    />
                </div>
                <div className="flex flex-col gap-2">
                    <ProjectSummaryQuestionGroup
                        activeComparison={activeComparison}
                        questions={questions.filter(
                            (question) => question.group === questionGroup.id,
                        )}
                        selectedFilters={selectedFilters}
                        showAllTags={true}
                        title="Top selections"
                        onQuestionOptionClick={(optionId) =>
                            setSelectedQuesitonOptions(
                                uniq([...selectedQuestionOptions, optionId]),
                            )
                        }
                    />
                    <div className="card mt-4">
                        <div className="font-bold text-2xl mb-2">Quotes</div>
                        <Select
                            {...defaultSelectProps}
                            closeMenuOnSelect={false}
                            isClearable={false}
                            isMulti
                            options={allQuestionOptionsForSelect}
                            value={allQuestionOptionsForSelect.filter((opt) =>
                                selectedQuestionOptions.includes(opt.value),
                            )}
                            onChange={(val) =>
                                setSelectedQuesitonOptions(
                                    val.map((opt) => opt.value),
                                )
                            }
                        />
                        <div className="mt-4">
                            <ResponsiveMasonry
                                columnsCountBreakPoints={{ 350: 1, 1200: 2 }}
                            >
                                <Masonry gutter="1rem">
                                    {filteredQuotes.map((quote) => {
                                        return (
                                            <QuestionGroupQuote
                                                key={quote.id}
                                                quote={quote}
                                            />
                                        );
                                    })}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </div>
                </div>
            </section>
        </PageWithHeader>
    );
};

export default QuestionGroup;
