import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import { Question } from "../../../types/goldpan";
import QuestionItem from "./question";

interface SortableQuestionProps {
    id: UniqueIdentifier;
    disabled?: boolean;
    question: Question;
    onQuestionUpdated?: (question: Question) => void;
    onRemoveQuestion: () => void;
}

const SortableQuestion = ({
    disabled,
    id,
    onQuestionUpdated,
    onRemoveQuestion,
    question,
}: SortableQuestionProps) => {
    const { isDragging, listeners, setNodeRef, transform, transition } =
        useSortable({
            id,
        });

    return (
        <QuestionItem
            listeners={listeners}
            question={question}
            ref={disabled ? undefined : setNodeRef}
            style={
                {
                    transition,
                    transform: CSS.Translate.toString(transform),
                    opacity: isDragging ? 0.5 : undefined,
                } as React.CSSProperties
            }
            onQuestionUpdated={onQuestionUpdated}
            onRemoveQuestion={
                question.has_responses ? undefined : onRemoveQuestion
            }
        />
    );
};

export default SortableQuestion;
