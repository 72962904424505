import { unionBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import { createPreAnalysisInterview } from "../../api";
import { useAppSelector } from "../../hooks";
import { Interview, RawInterviewMetadataValues } from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Input from "../input";
import Modal from "../modal";

const CreateInterviewModal = ({
    isOpen,
    onClose,
    onInterviewCreated,
    projectId,
}: {
    projectId: number;
    isOpen: boolean;
    onClose: () => void;
    onInterviewCreated: (interview: Interview) => void;
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const outcomes = useAppSelector((state) => state.project.outcomes);
    const interviewMetadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const interviewMetadataFieldValues = useAppSelector(
        (state) => state.project.interviewMetadataValues,
    );

    const [interviewMetadata, setInterviewMetadata] =
        useState<RawInterviewMetadataValues>({});
    const [interviewCode, setInterviewCode] = useState("");
    const [interviewName, setInterviewName] = useState("");
    const [interviewOutcome, setInterviewOutcome] = useState<number | null>(
        null,
    );

    const outcomeOptions = useMemo(
        () =>
            outcomes.map((outcome) => ({
                value: outcome.id,
                label: outcome.name,
            })),
        [outcomes],
    );

    const handleCreateInterview = useCallback(() => {
        setIsSaving(true);
        createPreAnalysisInterview(projectId, {
            name: interviewName,
            outcome: interviewOutcome,
            code: interviewCode,
            metadata: interviewMetadata,
        })
            .then((response) => {
                if (!response) {
                    return;
                }
                onInterviewCreated(response.data);
            })
            .finally(() => {
                setIsSaving(false);
            });
    }, [
        interviewCode,
        interviewMetadata,
        interviewName,
        interviewOutcome,
        onInterviewCreated,
        projectId,
    ]);
    return (
        <Modal
            confirmTooltip={
                interviewCode.trim() === "" || interviewName.trim() === ""
                    ? "Please fill in the required fields"
                    : undefined
            }
            isConfirmDisabled={
                interviewCode.trim() === "" || interviewName.trim() === ""
            }
            isConfirmLoading={isSaving}
            isOpen={isOpen}
            title="New interview"
            onClose={onClose}
            onConfirm={handleCreateInterview}
        >
            <div className="mb-4">
                <Input
                    id="interview-code"
                    placeholder="Interview code"
                    type="text"
                    value={interviewCode}
                    onChange={setInterviewCode}
                />
            </div>
            <div className="mb-4">
                <Input
                    id="interview-name"
                    placeholder="Interview name"
                    type="text"
                    value={interviewName}
                    onChange={setInterviewName}
                />
            </div>
            <Select
                {...defaultSelectProps}
                className="mb-4"
                id="outcome-select"
                menuPlacement="top"
                options={outcomeOptions}
                placeholder="Outcome"
                value={
                    outcomeOptions.find((o) => o.value === interviewOutcome) ??
                    null
                }
                onChange={(opt) => setInterviewOutcome(opt!.value)}
            />

            {interviewMetadataFields.map((field) => (
                <div className="mb-4" key={field.id}>
                    <CreatableSelect
                        {...defaultSelectProps}
                        id={`inteview-metadata-field-${field.id}`}
                        menuPlacement="top"
                        options={
                            interviewMetadataFieldValues[field.id]
                                ? unionBy(
                                      interviewMetadataFieldValues[
                                          field.id
                                      ].map((val) => ({
                                          value: val.data,
                                          label: val.data,
                                      })),
                                      "value",
                                  )
                                : []
                        }
                        placeholder={`Field: ${field.display_title}`}
                        value={
                            interviewMetadata[field.id]
                                ? {
                                      value: interviewMetadata[field.id],
                                      label: interviewMetadata[field.id],
                                  }
                                : null
                        }
                        onChange={(opt) => {
                            setInterviewMetadata({
                                ...interviewMetadata,
                                [field.id]: opt!.value,
                            });
                        }}
                        onCreateOption={(value) =>
                            setInterviewMetadata({
                                ...interviewMetadata,
                                [field.id]: value,
                            })
                        }
                    />
                </div>
            ))}
        </Modal>
    );
};

export default CreateInterviewModal;
