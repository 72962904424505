import classNames from "classnames";

import Icon from "./icon";

interface Props {
    dirtyCount: number;
}

const UnsavedCounter: React.FC<Props> = ({ dirtyCount }) => (
    <div
        className={classNames("flex items-center", {
            "text-red-700": dirtyCount > 0,
            "text-green-700": dirtyCount === 0,
        })}
    >
        {dirtyCount ? <Icon icon="error" /> : <Icon icon="check_circle" />}
        <div className="ml-2">
            {dirtyCount
                ? `${dirtyCount} ${dirtyCount > 1 ? "items" : "item"} unsaved`
                : "All saved"}
        </div>
    </div>
);

export default UnsavedCounter;
