import { useCallback, useMemo } from "react";
import Select, { OptionProps, SingleValue } from "react-select";

import { Question } from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";
import Icon from "../icon";

type QuestionOption = {
    value: number;
    label: string;
    question: Question;
};

interface Props {
    questions: Question[];
    selectedQuestionId: number | null;
    disabled?: boolean;
    onChange: (questionId: number) => void;
}

const Option = (props: OptionProps<QuestionOption>) => {
    return (
        <div
            className="w-full p-2 hover:bg-blue-100 hover:cursor-pointer flex items-center gap-2"
            {...props.innerProps}
        >
            {props.children}
            {props.data.question.options.some(
                (opt) => opt.ai_prompt_description.trim() === "",
            ) && <Icon className="text-amber-500" icon="warning" />}
        </div>
    );
};

const QuestionSelector: React.FC<Props> = ({
    disabled,
    onChange,
    questions,
    selectedQuestionId,
}) => {
    const questionOptions: QuestionOption[] = useMemo(
        () =>
            questions.map((question) => ({
                value: assertNonNull(question.id),
                label: question.text,
                question,
            })),
        [questions],
    );

    const handleChooseQuestion = useCallback(
        (option: SingleValue<QuestionOption>) => {
            onChange(option!.value);
        },
        [onChange],
    );

    return (
        <div className="flex items-center">
            <label className="mr-4">Question:</label>
            <div className="flex-grow">
                <Select
                    {...defaultSelectProps}
                    className="question-select"
                    components={{ Option }}
                    isDisabled={disabled}
                    isMulti={false}
                    options={questionOptions}
                    value={
                        questionOptions.find(
                            (i) => i.value === selectedQuestionId,
                        ) ?? null
                    }
                    onChange={handleChooseQuestion}
                />
            </div>
        </div>
    );
};

export default QuestionSelector;
