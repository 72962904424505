import UploadButton from "@rpldy/upload-button";
import UploadPreview, { PreviewComponentProps } from "@rpldy/upload-preview";
import Uploady, {
    useBatchAddListener,
    useBatchFinishListener,
    useItemProgressListener,
    useRequestPreSend,
} from "@rpldy/uploady";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { getCSRFToken } from "../../api";
import { Messages } from "../../apps/message-list";
import Loader from "../loader";

export type AudioFileUploadResult = {
    file_id: number;
    task_id: string;
};

type Props = {
    onUploadStarted: () => void;
    onUploadSuccess: (response: AudioFileUploadResult) => void;
    onUploadFailure: () => void;
};

const FileProgressBar = ({ id }: PreviewComponentProps) => {
    const { completed } = useItemProgressListener(id) || { completed: 0 };

    return (
        <>
            <div className="flex flex-col flex-1 justify-center">
                <div className="flex w-full h-3 rounded-lg bg-gray-200">
                    <div
                        className="flex h-3 rounded-lg bg-gp-blue"
                        style={{
                            width: `${completed}%`,
                        }}
                    ></div>
                </div>
            </div>
            {completed === 100 && (
                <div className="text-center mt-2">
                    File uploaded, sending transcript to Deepgram...
                    <div className="mt-4">
                        <Loader large />
                    </div>
                </div>
            )}
        </>
    );
};

const UploadWithProgressPreview = ({
    onUploadFailure,
    onUploadStarted,
    onUploadSuccess,
}: Props) => {
    const [filename, setFilename] = useState<string | null>(null);
    useBatchAddListener((batch) => {
        setFilename(batch.items[0].file.name);
        onUploadStarted();
    });
    useBatchFinishListener((batch) => {
        const status = batch.items[0].uploadStatus;
        const response = batch.items[0].uploadResponse?.data;
        if (status !== 200) {
            const msg = response?.message ?? "File upload failed";
            Messages.error(msg);
            setFilename(null);
            onUploadFailure();
        } else {
            const response = batch.items[0].uploadResponse
                ?.data as AudioFileUploadResult;
            onUploadSuccess(response);
        }
    });
    useRequestPreSend(() => {
        return {
            options: {
                destination: {
                    headers: {
                        "X-CSRFToken": getCSRFToken(),
                    },
                },
            },
        };
    });

    return (
        <div className="mt-8">
            {filename ? (
                <>
                    <div className="text-center">{filename}</div>
                    <UploadPreview PreviewComponent={FileProgressBar} />
                </>
            ) : (
                <UploadButton className="button px-3.5 py-2 w-full">
                    Choose file
                </UploadButton>
            )}
        </div>
    );
};

const AudioFileUpload = (props: Props) => {
    const { projectId: urlProjectId } = useParams();
    const projectId = parseInt(urlProjectId!);
    return (
        <Uploady
            accept="audio/*"
            debug
            destination={{
                url: `/api/project/${projectId}/audio-file-upload/`,
            }}
            multiple={false}
        >
            <UploadWithProgressPreview {...props} />
        </Uploady>
    );
};

export default AudioFileUpload;
