export const MAX_COMPARISONS = 2;

export const comparisonClassnames = ["gp-accent", "gp-accent2"];

export enum ComparisonType {
    OUTCOMES = "outcomes",
    METADATA = "metadata",
}
type GeneralComparison = {
    data: string;
    color?: string;
};
type OutcomeComparison = {
    type: ComparisonType.OUTCOMES;
    outcomeId: number;
};
type MetadataComparison = {
    type: ComparisonType.METADATA;
    fieldId: number;
    valueId: number;
};
export type Comparison = GeneralComparison &
    (OutcomeComparison | MetadataComparison);
