import Tippy from "@tippyjs/react";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";

import { checkCeleryTask, cleanTranscript } from "../../api";
import {
    TranscriptParagraph,
    TranscriptSpeaker,
    WithUUID,
} from "../../types/goldpan";
import { assertNonNull } from "../../utils";
import Button, { ButtonVariant } from "../button";
import Icon from "../icon";
import Textarea from "../textarea";

const TextTranscript = ({
    disabled,
    isSubmittingRawTranscript,
    projectId,
    rawTranscript,
    setIsSubmittingRawTranscript,
    setJsonTranscript,
    setRawTranscript,
}: {
    disabled: boolean;
    isSubmittingRawTranscript: boolean;
    setIsSubmittingRawTranscript: (isSubmitting: boolean) => void;
    projectId: number;
    setJsonTranscript: (data: WithUUID<TranscriptParagraph>[]) => void;
    rawTranscript: string | null;
    setRawTranscript: (transcript: string) => void;
}) => {
    const handleSubmitRawTranscript = useCallback(async () => {
        setIsSubmittingRawTranscript(true);
        const response = await cleanTranscript(projectId, {
            raw_transcript: assertNonNull(rawTranscript),
            project_id: projectId,
        });
        if (response) {
            checkCeleryTask<TranscriptParagraph[]>(
                response.data.task_id,
                (response) =>
                    setJsonTranscript(
                        response.map((paragraph) => ({
                            data: paragraph,
                            isDirty: false,
                            uuid: uuidv4(),
                        })),
                    ),
                () => setIsSubmittingRawTranscript(false),
            );
        } else {
            setIsSubmittingRawTranscript(false);
        }
    }, [
        projectId,
        rawTranscript,
        setIsSubmittingRawTranscript,
        setJsonTranscript,
    ]);

    const handleUseTranscriptAsIs = useCallback(() => {
        if (!rawTranscript) {
            return;
        }

        const paragraphs = rawTranscript?.split("\n\n");
        setJsonTranscript(
            paragraphs.map((paragraph) => {
                const speaker: TranscriptSpeaker = /^Speaker 0:/.test(paragraph)
                    ? TranscriptSpeaker.GOLDPAN
                    : TranscriptSpeaker.INTERVIEWEE;
                return {
                    data: {
                        speaker,
                        dialogue: paragraph
                            .replace(/^Speaker \d+:/g, "")
                            .trim(),
                    },
                    isDirty: false,
                    uuid: uuidv4(),
                };
            }),
        );
    }, [rawTranscript, setJsonTranscript]);

    return (
        <>
            <Textarea
                className="flex-grow resize-none"
                disabled={disabled || isSubmittingRawTranscript}
                id="raw-transcript-input"
                placeholder="Paste interview transcript here..."
                value={rawTranscript ?? ""}
                onChange={setRawTranscript}
                onPaste={(evt) => {
                    setRawTranscript(
                        evt.clipboardData
                            .getData("text")
                            .replace(/speaker (\d+):\n\n/gi, "Speaker $1: "),
                    );
                    evt.preventDefault();
                }}
            />
            {!disabled && (
                <div className="mt-4 flex items-center gap-2">
                    <Button
                        className="w-64"
                        disabled={!rawTranscript}
                        id="submit-raw-transcript"
                        isLoading={isSubmittingRawTranscript}
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleSubmitRawTranscript}
                    >
                        Clean transcript (OpenAI)
                    </Button>
                    <Button
                        disabled={!rawTranscript || isSubmittingRawTranscript}
                        id="use-as-is-transcript"
                        onClick={handleUseTranscriptAsIs}
                    >
                        Use as is
                    </Button>
                    <Tippy
                        content={
                            <div>
                                &quot;Use as is&quot; expects a specific
                                transcript format:
                                <br />
                                <br />
                                Speaker 0: pargraph text...
                                <br />
                                <br />
                                Speaker 1: paragraph text...
                                <br />
                                <br />
                                Speaker 0: more pargraph text...
                                <br />
                                <br />
                                etc...
                            </div>
                        }
                    >
                        <div>
                            <Icon icon="info" />
                        </div>
                    </Tippy>
                </div>
            )}
        </>
    );
};

export default TextTranscript;
