import classNames from "classnames";
import { Link } from "react-router-dom";

import Icon from "./icon";

type WorkflowStep = {
    complete: boolean;
    href: string;
    title: string;
    active: boolean;
};

interface Props {
    steps: WorkflowStep[];
}

const Steps: React.FC<Props> = ({ steps }) => {
    return (
        <ol className="flex items-center w-full max-w-[1150px] text-sm font-medium text-center text-gray-500">
            {steps.map((step, index) => (
                <li
                    className={classNames("flex items-center", {
                        "md:w-full after:w-full after:h-1 after:border-b after:border-gray-200 after:border-1 after:hidden sm:after:inline-block after:mx-6 xl:after:mx-10":
                            index < steps.length - 1,
                        "text-gp-blue": step.active,
                    })}
                    key={step.title}
                >
                    <span className="flex items-center after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
                        <Link
                            className="flex items-center px-2 py-1 rounded-md text-inherit hover:bg-gp-blue hover:text-white hover:no-underline"
                            to={step.href}
                        >
                            {step.complete ? (
                                <Icon
                                    className="fill-current mr-2"
                                    icon="check_circle"
                                />
                            ) : (
                                <span className="mr-2">{index + 1}.</span>
                            )}
                            {step.title}
                        </Link>
                    </span>
                </li>
            ))}
        </ol>
    );
};

export default Steps;
