import * as Sentry from "@sentry/react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { IconContext } from "react-icons";
import { Provider } from "react-redux";
import "tippy.js/dist/tippy.css";
import "vite/modulepreload-polyfill";

import "../css/tailwind.css";
import Login from "./apps/login";
import MessageList from "./apps/message-list";
import ProjectRouter from "./routers/project-router";
import store from "./stores";

document.addEventListener("DOMContentLoaded", function () {
    if (window.production) {
        Sentry.init({
            dsn: "https://8ffb01bc11e46c7d89de2c7946bc06de@o4507177496870912.ingest.us.sentry.io/4507177509715968",
            integrations: [Sentry.replayIntegration()],
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
        Sentry.setContext("user", {
            email: window.goldpanUser?.email,
        });
    }

    const reactApps: { [key: string]: React.JSX.Element } = {
        "login-app": <Login />,
        app: <ProjectRouter />,
        "message-list": <MessageList />,
    };
    for (const appId of Object.keys(reactApps)) {
        const ele = document.getElementById(appId);
        if (ele) {
            createRoot(ele).render(
                <StrictMode>
                    <Provider store={store}>
                        <IconContext.Provider
                            value={{ style: { verticalAlign: "middle" } }}
                        >
                            {reactApps[appId]}
                        </IconContext.Provider>
                    </Provider>
                </StrictMode>,
            );
        }
    }

    // show page content after everything loads
    document.body.style.visibility = "visible";
});
