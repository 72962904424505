import type { DraggableSyntheticListeners } from "@dnd-kit/core";
import React, { useEffect, useState } from "react";

import { Question } from "../../../types/goldpan";
import Button, { ButtonVariant } from "../../button";
import EditQuestionModal from "../../edit-question-modal/edit-question-modal";
import Icon from "../../icon";

interface Props {
    dragOverlay?: boolean;
    listeners?: DraggableSyntheticListeners;
    style?: React.CSSProperties;
    question: Question;
    onRemoveQuestion?: () => void;
    onQuestionUpdated?: (question: Question) => void;
}

const QuestionItem = React.memo(
    React.forwardRef<HTMLDivElement, Props>(
        (
            {
                dragOverlay,
                listeners,
                onQuestionUpdated,
                onRemoveQuestion,
                question,
                style,
            },
            ref,
        ) => {
            const [isEditing, setIsEditing] = useState(false);

            useEffect(() => {
                if (!dragOverlay) {
                    return;
                }

                document.body.style.cursor = "grabbing";

                return () => {
                    document.body.style.cursor = "";
                };
            }, [dragOverlay]);

            return (
                <div
                    className="bg-white p-4 mb-2 last:mb-0 flex items-center justify-between"
                    ref={ref}
                    style={style}
                >
                    {onQuestionUpdated && (
                        <EditQuestionModal
                            isOpen={isEditing}
                            question={question}
                            onClose={() => setIsEditing(false)}
                            onQuestionSaved={(newQuestion) => {
                                setIsEditing(false);
                                onQuestionUpdated(newQuestion);
                            }}
                        />
                    )}
                    <div>{question.text}</div>
                    <div className="flex items-center justify-end gap-4">
                        <Button
                            className="no-bg"
                            icon="edit"
                            onClick={() => setIsEditing(true)}
                        />
                        <Button
                            className="no-bg"
                            disabled={!onRemoveQuestion}
                            icon="delete"
                            tooltip={
                                onRemoveQuestion
                                    ? undefined
                                    : "Question has responses"
                            }
                            variant={ButtonVariant.DANGER}
                            onClick={onRemoveQuestion}
                        />
                        <Icon
                            className="w-4 h-4 cursor-grabbing"
                            icon="handle"
                            {...listeners}
                        />
                    </div>
                </div>
            );
        },
    ),
);

export default QuestionItem;
