import {
    CollisionDetection,
    DndContext,
    DragOverlay,
    DropAnimation,
    KeyboardSensor,
    MeasuringStrategy,
    MouseSensor,
    TouchSensor,
    UniqueIdentifier,
    closestCenter,
    defaultDropAnimationSideEffects,
    getFirstCollision,
    pointerWithin,
    rectIntersection,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { groupBy, min, sortBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import {
    BulkUpdateQuestionGroupsRequest,
    bulkUpdateQuestionGroups,
    deleteQuestion,
    saveQuestionGroup,
} from "../../api";
import { Messages } from "../../apps/message-list";
import { useAppDispatch, useAppSelector, useConfirm } from "../../hooks";
import { setQuestionGroups, setQuestions } from "../../stores/project";
import { Question, QuestionGroup } from "../../types/goldpan";
import { assertNonNull } from "../../utils";
import DropdownMenu from "../dropdown-menu";
import EditQuestionModal from "../edit-question-modal/edit-question-modal";
import { coordinateGetter } from "./multi-containers-keyboard-coords";
import { UNGROUPED_ID } from "./sortable-question-groups/constants";
import DroppableQuestionGroup from "./sortable-question-groups/droppable-question-group";
import QuestionItem from "./sortable-question-groups/question";
import QuestionGroupItem from "./sortable-question-groups/question-group";
import SortableQuestion from "./sortable-question-groups/sortable-question";

const dropAnimation: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.5",
            },
        },
    }),
};

const getGroupUniqueId = (group: QuestionGroup | number) =>
    `group-${typeof group === "number" ? group : assertNonNull(group.id)}`;
const findQuestionGroup = (
    questionGroups: QuestionGroup[],
    groupId: UniqueIdentifier,
) =>
    questionGroups.find(
        (group) => group.id === parseInt(`${groupId}`.replace("group-", "")),
    );

const getItems = (questionGroups: QuestionGroup[], questions: Question[]) => ({
    ...[UNGROUPED_ID]
        .concat(questionGroups.map(getGroupUniqueId))
        .reduce((obj, groupId) => ({ ...obj, [groupId]: [] }), {}),
    ...groupBy(sortBy(questions, "ordinal"), (question) =>
        question.group ? getGroupUniqueId(question.group) : UNGROUPED_ID,
    ),
});
const getContainers = (questionGroups: QuestionGroup[]) =>
    sortBy(questionGroups, "ordinal")
        .map(getGroupUniqueId)
        .concat([UNGROUPED_ID]);

const ProjectQuestions = () => {
    const dispatch = useAppDispatch();
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );
    const questions = useAppSelector((state) => state.project.questions);
    const questionGroups = useAppSelector(
        (state) => state.project.questionGroups,
    );
    const [isAddingNewQuestion, setIsAddingNewQuestion] = useState(false);

    const [items, setItems] = useState<Record<UniqueIdentifier, Question[]>>(
        getItems(questionGroups, questions),
    );
    const [containers, setContainers] = useState<UniqueIdentifier[]>(
        getContainers(questionGroups),
    );

    const {
        confirm: confirmDeleteGroup,
        ConfirmationDialog: DeleteGroupConfirmationDialog,
    } = useConfirm(
        "All questions in this group will be moved to Ungrouped. Proceed?",
    );
    const {
        confirm: confirmDeleteQuestion,
        ConfirmationDialog: DeleteQuestionConfirmationDialog,
    } = useConfirm("Are you sure you want to delete the question?");

    const [clonedItems, setClonedItems] = useState<typeof items | null>(null);
    const [activeId, setActiveId] = useState<
        UniqueIdentifier | null | undefined
    >(undefined);
    const lastOverId = useRef<UniqueIdentifier | null>(null);
    const recentlyMovedToNewContainer = useRef(false);
    const isSortingContainer = activeId ? containers.includes(activeId) : false;
    const sensors = useSensors(
        useSensor(MouseSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter,
        }),
    );

    const handleUpdateQuestionGroups = useCallback(
        async (data?: BulkUpdateQuestionGroupsRequest) => {
            const response = await bulkUpdateQuestionGroups(
                project.id,
                data
                    ? data
                    : {
                          question_groups: containers
                              .filter((groupId) => groupId !== UNGROUPED_ID)
                              .map((groupId, index) => {
                                  const group = assertNonNull(
                                      findQuestionGroup(
                                          questionGroups,
                                          groupId,
                                      ),
                                  );
                                  return {
                                      id: assertNonNull(group.id),
                                      title: group.title,
                                      ordinal: index,
                                      question_ids: items[groupId].map((q) =>
                                          assertNonNull(q.id),
                                      ),
                                  };
                              }),
                          ungrouped_question_ids: items[UNGROUPED_ID].map((q) =>
                              assertNonNull(q.id),
                          ),
                      },
            );
            if (response) {
                dispatch(setQuestionGroups(response.data.question_groups));
                dispatch(setQuestions(response.data.questions));
                setItems(
                    getItems(
                        response.data.question_groups,
                        response.data.questions,
                    ),
                );
                setContainers(getContainers(response.data.question_groups));
            }
        },
        [containers, dispatch, items, project, questionGroups],
    );

    const handleAddQuestionGroup = useCallback(async () => {
        const response = await saveQuestionGroup(project.id, {
            id: null,
            title: `New group ${
                questionGroups.filter(({ title }) =>
                    title.includes("New group"),
                ).length + 1
            }`,
            ordinal:
                questionGroups.length > 0
                    ? assertNonNull(min(questionGroups.map((g) => g.ordinal))) -
                      1
                    : 1,
            slug: "",
        });
        if (response) {
            const newQuestionGroups = questionGroups.concat([response.data]);
            dispatch(setQuestionGroups(newQuestionGroups));
            setItems(getItems(newQuestionGroups, questions));
            setContainers(getContainers(newQuestionGroups));
        }
    }, [project, questionGroups, questions, dispatch]);

    const handleSaveQuestion = useCallback(
        async (question: Question) => {
            const isExistingQuestion = !!questions.find(
                ({ id }) => id === question.id,
            );
            const newQuestions = isExistingQuestion
                ? questions.map((q) => {
                      if (q.id === question.id) {
                          return question;
                      }
                      return q;
                  })
                : sortBy(questions.concat([question]), "ordinal");
            dispatch(setQuestions(newQuestions));
            setItems(getItems(questionGroups, newQuestions));
        },
        [questions, dispatch, questionGroups],
    );

    /**
     * Custom collision detection strategy optimized for multiple containers
     *
     * - First, find any droppable containers intersecting with the pointer.
     * - If there are none, find intersecting containers with the active draggable.
     * - If there are no intersecting containers, return the last matched intersection
     *
     */
    const collisionDetectionStrategy: CollisionDetection = useCallback(
        (args) => {
            if (activeId && activeId in items) {
                return closestCenter({
                    ...args,
                    droppableContainers: args.droppableContainers.filter(
                        (container) => container.id in items,
                    ),
                });
            }

            // Start by finding any intersecting droppable
            const pointerIntersections = pointerWithin(args);
            const intersections =
                pointerIntersections.length > 0
                    ? // If there are droppables intersecting with the pointer, return those
                      pointerIntersections
                    : rectIntersection(args);
            let overId = getFirstCollision(intersections, "id");

            if (overId != null) {
                if (overId in items) {
                    const containerItems = items[overId];

                    // If a container is matched and it contains items (columns 'A', 'B', 'C')
                    if (containerItems.length > 0) {
                        // Return the closest droppable within that container
                        overId = closestCenter({
                            ...args,
                            droppableContainers:
                                args.droppableContainers.filter(
                                    (container) =>
                                        container.id !== overId &&
                                        !!containerItems.find(
                                            (question) =>
                                                question.id == container.id,
                                        ),
                                ),
                        })[0]?.id;
                    }
                }

                lastOverId.current = overId;

                return [{ id: overId }];
            }

            // When a draggable item moves to a new container, the layout may shift
            // and the `overId` may become `null`. We manually set the cached `lastOverId`
            // to the id of the draggable item that was moved to the new container, otherwise
            // the previous `overId` will be returned which can cause items to incorrectly shift positions
            if (recentlyMovedToNewContainer.current && activeId !== undefined) {
                lastOverId.current = activeId;
            }

            // If no droppable is matched, return the last match
            return lastOverId.current ? [{ id: lastOverId.current }] : [];
        },
        [activeId, items],
    );

    const handleRemoveQuestionGroup = useCallback(
        async (questionGroupId: UniqueIdentifier) => {
            const confirmation = await confirmDeleteGroup();
            if (confirmation) {
                handleUpdateQuestionGroups({
                    question_groups: containers
                        .filter(
                            (groupId) =>
                                groupId !== UNGROUPED_ID &&
                                groupId !== questionGroupId,
                        )
                        .map((groupId, index) => {
                            const group = assertNonNull(
                                findQuestionGroup(questionGroups, groupId),
                            );
                            return {
                                id: assertNonNull(group.id),
                                title: group.title,
                                ordinal: index,
                                question_ids: items[groupId].map((q) =>
                                    assertNonNull(q.id),
                                ),
                            };
                        }),
                    ungrouped_question_ids: items[UNGROUPED_ID].concat(
                        items[questionGroupId],
                    ).map((q) => assertNonNull(q.id)),
                });
            }
        },
        [
            confirmDeleteGroup,
            containers,
            handleUpdateQuestionGroups,
            items,
            questionGroups,
        ],
    );

    const handleRemoveQuestion = useCallback(
        async (question: Question) => {
            const confirmation = await confirmDeleteQuestion();
            if (confirmation) {
                const response = await deleteQuestion(project.id, question);
                if (response) {
                    Messages.success("Question deleted successfully");
                    const newQuestions = questions.filter(
                        ({ id }) => id !== question.id,
                    );
                    dispatch(setQuestions(newQuestions));
                    setItems(getItems(questionGroups, newQuestions));
                }
            }
        },
        [confirmDeleteQuestion, dispatch, project, questionGroups, questions],
    );

    const renderSortableItemDragOverlay = useCallback(
        (id: UniqueIdentifier) => {
            const questionId = parseInt(`${id}`.replace("question-", ""), 10);
            const question = assertNonNull(
                questions.find((q) => q.id === questionId),
            );
            return <QuestionItem question={question} />;
        },
        [questions],
    );

    const renderContainerDragOverlay = useCallback(
        (containerId: UniqueIdentifier) => {
            const group = questionGroups.find(
                (group) => group.id === containerId,
            );
            return (
                <QuestionGroupItem
                    group={group}
                    style={{
                        height: "100%",
                    }}
                >
                    {items[containerId].map((item) => (
                        <QuestionItem key={item.id} question={item} />
                    ))}
                </QuestionGroupItem>
            );
        },
        [items, questionGroups],
    );

    const findContainer = (id: UniqueIdentifier) => {
        if (id in items) {
            return id;
        }

        return Object.keys(items).find((key) =>
            items[key].map((question) => question.id).includes(id as number),
        );
    };

    const onDragCancel = () => {
        if (clonedItems) {
            // Reset items to their original state in case items have been
            // Dragged across containers
            setItems(clonedItems);
        }

        setActiveId(null);
        setClonedItems(null);
    };

    useEffect(() => {
        requestAnimationFrame(() => {
            recentlyMovedToNewContainer.current = false;
        });
    }, [items]);

    useEffect(() => {
        if (activeId === null) handleUpdateQuestionGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeId]);

    return (
        <>
            <DeleteGroupConfirmationDialog />
            <DeleteQuestionConfirmationDialog />
            <EditQuestionModal
                isOpen={isAddingNewQuestion}
                onClose={() => setIsAddingNewQuestion(false)}
                onQuestionSaved={(question) => {
                    setIsAddingNewQuestion(false);
                    handleSaveQuestion(question);
                }}
            />
            <div className="mb-2 flex items-center justify-end gap-4">
                <DropdownMenu
                    menuItems={[
                        [
                            {
                                icon: "question",
                                title: "Question",
                                onClick: () => setIsAddingNewQuestion(true),
                            },
                            {
                                icon: "group",
                                title: "Group",
                                onClick: handleAddQuestionGroup,
                            },
                        ],
                    ]}
                    title="Add new"
                />
            </div>
            <DndContext
                collisionDetection={collisionDetectionStrategy}
                measuring={{
                    droppable: {
                        strategy: MeasuringStrategy.Always,
                    },
                }}
                sensors={sensors}
                onDragCancel={onDragCancel}
                onDragEnd={({ active, over }) => {
                    if (active.id in items && over?.id) {
                        setContainers((containers) => {
                            const activeIndex = containers.indexOf(
                                active.id as number,
                            );
                            const overIndex = containers.indexOf(
                                over.id as number,
                            );
                            if (overIndex === containers.length - 1) {
                                return containers;
                            }

                            return arrayMove(
                                containers,
                                activeIndex,
                                overIndex,
                            );
                        });
                    }

                    const activeContainer = findContainer(active.id);

                    if (!activeContainer) {
                        setActiveId(null);
                        return;
                    }

                    const overId = over?.id;

                    if (overId == null) {
                        setActiveId(null);
                        return;
                    }

                    const overContainer = findContainer(overId);

                    if (overContainer) {
                        const activeIndex = items[activeContainer].findIndex(
                            (question) => question.id === active.id,
                        );
                        const overIndex = items[overContainer].findIndex(
                            (question) => question.id === overId,
                        );

                        if (activeIndex !== overIndex) {
                            setItems((items) => {
                                const newItems = {
                                    ...items,
                                    [overContainer]: arrayMove(
                                        items[overContainer],
                                        activeIndex,
                                        overIndex,
                                    ),
                                };
                                return newItems;
                            });
                        }
                    }

                    setActiveId(null);
                }}
                onDragOver={({ active, over }) => {
                    const overId = over?.id;

                    if (overId == null || active.id in items) {
                        return;
                    }

                    const overContainer = findContainer(overId);
                    const activeContainer = findContainer(active.id);

                    if (!overContainer || !activeContainer) {
                        return;
                    }

                    if (activeContainer !== overContainer) {
                        setItems((items) => {
                            const activeItems = items[activeContainer];
                            const overItems = items[overContainer];
                            const overIndex = overItems.findIndex(
                                (question) => question.id === overId,
                            );
                            const activeIndex = activeItems.findIndex(
                                (question) => question.id === active.id,
                            );

                            let newIndex: number;

                            if (overId in items) {
                                newIndex = overItems.length + 1;
                            } else {
                                const isBelowOverItem =
                                    over &&
                                    active.rect.current.translated &&
                                    active.rect.current.translated.top >
                                        over.rect.top + over.rect.height;

                                const modifier = isBelowOverItem ? 1 : 0;

                                newIndex =
                                    overIndex >= 0
                                        ? overIndex + modifier
                                        : overItems.length + 1;
                            }

                            recentlyMovedToNewContainer.current = true;

                            return {
                                ...items,
                                [activeContainer]: items[
                                    activeContainer
                                ].filter(
                                    (question) => question.id !== active.id,
                                ),
                                [overContainer]: [
                                    ...items[overContainer].slice(0, newIndex),
                                    items[activeContainer][activeIndex],
                                    ...items[overContainer].slice(
                                        newIndex,
                                        items[overContainer].length,
                                    ),
                                ],
                            };
                        });
                    }
                }}
                onDragStart={({ active }) => {
                    setActiveId(active.id);
                }}
            >
                <div className="flex flex-col gap-4">
                    <SortableContext
                        items={containers}
                        strategy={verticalListSortingStrategy}
                    >
                        {containers.map((containerId) => {
                            const group = findQuestionGroup(
                                questionGroups,
                                containerId,
                            );
                            return (
                                <DroppableQuestionGroup
                                    group={group}
                                    id={containerId}
                                    items={items[containerId].map((q) =>
                                        assertNonNull(q.id),
                                    )}
                                    key={containerId}
                                    onRemove={() =>
                                        handleRemoveQuestionGroup(containerId)
                                    }
                                >
                                    <SortableContext
                                        items={items[containerId].map((q) => ({
                                            ...q,
                                            id: assertNonNull(q.id),
                                        }))}
                                        strategy={verticalListSortingStrategy}
                                    >
                                        {items[containerId].map((value) => {
                                            return (
                                                <SortableQuestion
                                                    disabled={
                                                        isSortingContainer
                                                    }
                                                    id={assertNonNull(value.id)}
                                                    key={value.id}
                                                    question={value}
                                                    onQuestionUpdated={
                                                        handleSaveQuestion
                                                    }
                                                    onRemoveQuestion={() =>
                                                        handleRemoveQuestion(
                                                            value,
                                                        )
                                                    }
                                                />
                                            );
                                        })}
                                    </SortableContext>
                                </DroppableQuestionGroup>
                            );
                        })}
                    </SortableContext>
                </div>
                {createPortal(
                    <DragOverlay
                        adjustScale={false}
                        dropAnimation={dropAnimation}
                    >
                        {activeId
                            ? containers.includes(activeId)
                                ? renderContainerDragOverlay(activeId)
                                : renderSortableItemDragOverlay(activeId)
                            : null}
                    </DragOverlay>,
                    document.body,
                )}
            </DndContext>
        </>
    );
};

export default ProjectQuestions;
