import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Link } from "react-router-dom";

import { Comparison } from "../../comparisons";
import { PROJECT_SUMMARY_MAX_TAGS } from "../../constants";
import { Filter } from "../../filters";
import { Question } from "../../types/goldpan";
import Card from "../layout/card";
import CardTitle from "../layout/card-title";
import ProjectSummaryQuestion from "./project-summary-question";

const ProjectSummaryQuestionGroup = ({
    activeComparison,
    detailLink,
    onQuestionOptionClick,
    questions,
    selectedFilters,
    showAllTags,
    title,
}: {
    activeComparison: Comparison[];
    selectedFilters: Filter[];
    showAllTags: boolean;
    title: string;
    questions: Question[];
    onQuestionOptionClick?: (questionOptionId: number) => void;
    detailLink?: string;
}) => {
    if (activeComparison.length > 2) {
        throw new Error("Comparisons only support max 2 values");
    }

    return (
        <Card>
            <div className="mb-8">
                <CardTitle>{title}</CardTitle>
                {detailLink && (
                    <Link className="text-sm" to={detailLink}>
                        See Details ↗{" "}
                    </Link>
                )}
            </div>
            <ResponsiveMasonry
                columnsCountBreakPoints={{ 350: 1, 1200: 2, 1600: 3 }}
            >
                <Masonry gutter="1.5rem">
                    {questions.map((question) => {
                        return (
                            <ProjectSummaryQuestion
                                activeComparison={activeComparison}
                                key={question.id}
                                question={question}
                                selectedFilters={selectedFilters}
                                tagCount={
                                    showAllTags
                                        ? question.options.length
                                        : PROJECT_SUMMARY_MAX_TAGS
                                }
                                onQuestionOptionClick={onQuestionOptionClick}
                            />
                        );
                    })}
                </Masonry>
            </ResponsiveMasonry>
        </Card>
    );
};

export default ProjectSummaryQuestionGroup;
