import { useCallback, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";

import EditQuestionOptions from "../components/project-settings/edit-question-options";
import ProjectCompetitors from "../components/project-settings/project-competitors";
import ProjectDetails from "../components/project-settings/project-details";
import ProjectQuestions from "../components/project-settings/project-questions";
import Tabs, { Tab } from "../components/tabs";

enum ProjectSettingsTab {
    PROJECT = "project",
    COMPETITORS = "competitors",
    QUESTIONS = "questions",
    QUESTION_OPTIONS = "question-options",
}

const tabOptions: Tab[] = [
    {
        id: ProjectSettingsTab.PROJECT,
        label: "Project details",
    },
    {
        id: ProjectSettingsTab.COMPETITORS,
        label: "Competitors",
    },
    {
        id: ProjectSettingsTab.QUESTIONS,
        label: "Questions",
    },
    {
        id: ProjectSettingsTab.QUESTION_OPTIONS,
        label: "Question options",
    },
];

const ProjectSettingsContainer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const urlTab = searchParams.get("tab");
    const [activeTab, setActiveTab] = useState<string>(
        urlTab !== null ? urlTab : ProjectSettingsTab.PROJECT,
    );

    const tabContent = useMemo(() => {
        switch (activeTab) {
            case ProjectSettingsTab.PROJECT:
                return <ProjectDetails />;
            case ProjectSettingsTab.COMPETITORS:
                return <ProjectCompetitors />;
            case ProjectSettingsTab.QUESTIONS:
                return <ProjectQuestions />;
            case ProjectSettingsTab.QUESTION_OPTIONS:
                return <EditQuestionOptions />;
        }
    }, [activeTab]);

    const handleChangeTab = useCallback(
        (tab: Tab) => {
            const params = new URLSearchParams();
            params.set("tab", tab.id);
            setSearchParams(params);

            setActiveTab(tab.id);
        },
        [setSearchParams],
    );

    return (
        <>
            <Tabs
                active={activeTab}
                tabs={tabOptions}
                onClickTab={handleChangeTab}
            />
            <div className="mt-8">{tabContent}</div>
        </>
    );
};

export default ProjectSettingsContainer;
