import { useCallback, useMemo, useState } from "react";
import Select from "react-select";

import { deletePrompt, getPrompts, savePrompt } from "../../api";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setAiPrompts } from "../../stores/project";
import { Prompt, PromptType } from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import Modal from "../modal";
import Tabs from "../tabs";
import Textarea from "../textarea";

enum OpenAiRole {
    SYSTEM = "system",
    USER = "user",
}

type EditablePrompt = {
    role: OpenAiRole;
    content: string;
};

type Props = {
    projectId: number;
    promptTypes: PromptType[];
    onClose: () => void;
};

const promptToEditable = (prompt?: Prompt) => {
    if (!prompt) {
        throw new Error("Expected prompt");
    }
    const promptArr: EditablePrompt[] = [];
    const lines = prompt.text.split(/\r?\n|\r|\n/g);
    for (const line of lines) {
        if (line.trim() === "") {
            continue;
        }
        promptArr.push({
            role: line.startsWith("$:") ? OpenAiRole.SYSTEM : OpenAiRole.USER,
            content: line.startsWith("$:") ? line.replace(/^\$:/, "") : line,
        });
    }
    return promptArr;
};

const editablePromptToString = (editablePrompts: EditablePrompt[]) => {
    return editablePrompts
        .map(
            (editablePrompt) =>
                `${editablePrompt.role === OpenAiRole.SYSTEM ? "$:" : ""}${
                    editablePrompt.content
                }`,
        )
        .join("\n");
};

const PromptContext = ({ promptType }: { promptType: PromptType }) => {
    let content = null;
    if (promptType === PromptType.CLEAN_TRANSCRIPT) {
        content = (
            <div>
                Only applies to text transcript input when using &quot;Clean
                transcript (OpenAI)&quot;
            </div>
        );
    }
    if (promptType === PromptType.PARSE_DECISION_REASONS) {
        content = (
            <div>
                <div>
                    For each theme defined by the project, the AI has been told
                    to return data in the following format:
                </div>
                <div>
                    <code>
                        {
                            '{"reason_summary":"","quotes": [{"paragraph_id":"", "paragraph_quote":""},...]'
                        }
                    </code>
                </div>
            </div>
        );
    }
    if (promptType === PromptType.PARSE_QUESTION_RESPONSES) {
        content = (
            <div>
                <div>
                    For each question defined by the project, the AI has been
                    told to return data in the following format:
                </div>
                <div>
                    <code>
                        {
                            '{"summary":"", "options": [{"paragraph_id":"", "paragraph_quote":"", "option_id":""},...]}'
                        }
                    </code>
                </div>
                <div>The question is provided to the AI in the format:</div>
                <div>
                    <code>
                        {
                            '{"question":"","description":"", "options":[{"option_id", "option":"", "description":""}, ...]}'
                        }
                    </code>
                </div>
            </div>
        );
    }
    if (promptType === PromptType.COLLECT_COMPETITOR_QUOTES) {
        content = (
            <div>
                <div>
                    For each competitor defined by the project, the AI has been
                    told to return quotes in the format:
                </div>
                <div>
                    <code>
                        {
                            '[{"paragraph_id":"", "paragraph_quote":"", "sentiment":""},...]'
                        }
                    </code>
                </div>
                <div>
                    The competitor company name is provided automatically.
                </div>
            </div>
        );
    }
    if (promptType === PromptType.COLLECT_CLIENT_QUOTES) {
        content = (
            <div>
                <div>The AI has been told to return quotes in the format:</div>
                <div>
                    <code>
                        {
                            '[{"paragraph_id":"", "paragraph_quote":"", "sentiment":""},...]'
                        }
                    </code>
                </div>
                <div>
                    The client company name from the project configuration is
                    provided automatically.
                </div>
            </div>
        );
    }

    return (
        content && (
            <div className="bg-gray-200 p-2 rounded text-sm">{content}</div>
        )
    );
};

const PromptOverrideModal: React.FC<Props> = ({
    onClose,
    projectId,
    promptTypes,
}) => {
    const dispatch = useAppDispatch();
    const prompts = useAppSelector((state) => state.project.aiPrompts);
    const [activePromptType, setActivePromptType] = useState(promptTypes[0]);
    const [activePrompt, setActivePrompt] = useState(
        prompts.find((prompt) => prompt.type === promptTypes[0]),
    );
    const [isLoading, setIsLoading] = useState(false);
    const [proposedPrompt, setProposedPrompt] = useState(
        promptToEditable(activePrompt),
    );

    const reloadPrompts = useCallback(async () => {
        const response = await getPrompts(projectId);
        if (response) {
            dispatch(setAiPrompts(response.data));
            onClose();
        }
    }, [dispatch, onClose, projectId]);

    const handleSavePrompt = useCallback(async () => {
        if (!proposedPrompt || !activePrompt) {
            return;
        }
        setIsLoading(true);
        const response = await savePrompt(projectId, {
            ...activePrompt,
            text: editablePromptToString(proposedPrompt),
        });
        setIsLoading(false);
        if (response) {
            reloadPrompts();
        }
    }, [proposedPrompt, activePrompt, projectId, reloadPrompts]);

    const handleDeletePrompt = useCallback(async () => {
        if (!activePrompt || !activePrompt.id) {
            return;
        }
        setIsLoading(true);
        const response = await deletePrompt(projectId, activePrompt);
        setIsLoading(false);
        if (response) {
            reloadPrompts();
        }
    }, [activePrompt, projectId, reloadPrompts]);

    const footer = useMemo(
        () => (
            <div className="flex items-center justify-between">
                <div>
                    {activePrompt?.id && (
                        <Button
                            disabled={isLoading}
                            variant={ButtonVariant.DANGER}
                            onClick={handleDeletePrompt}
                        >
                            Delete override
                        </Button>
                    )}
                </div>
                <div className="flex items-center">
                    <Button
                        className="mr-4"
                        disabled={isLoading}
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        disabled={isLoading}
                        id="modal-confirm"
                        variant={ButtonVariant.PRIMARY}
                        onClick={handleSavePrompt}
                    >
                        Confirm
                    </Button>
                </div>
            </div>
        ),
        [
            activePrompt,
            handleDeletePrompt,
            handleSavePrompt,
            isLoading,
            onClose,
        ],
    );

    return (
        <Modal className="xl" footer={footer} isOpen>
            {promptTypes.length > 1 && (
                <div className="flex items-center gap-2 text-sm mb-2">
                    <Tabs
                        active={activePromptType}
                        tabs={promptTypes.map((type) => ({
                            id: type,
                            label: type.replace(/_/g, " "),
                        }))}
                        onClickTab={({ id }) => {
                            const prompt = prompts.find(
                                (prompt) => prompt.type === id,
                            );
                            setActivePromptType(id as PromptType);
                            setActivePrompt(prompt);
                            setProposedPrompt(promptToEditable(prompt));
                        }}
                    />
                </div>
            )}
            <PromptContext promptType={activePromptType} />
            {proposedPrompt.map((editablePrompt, index) => (
                <div className="mt-2" key={`prompt_${index}`}>
                    <Select
                        {...defaultSelectProps}
                        className="w-[175px]"
                        options={[
                            {
                                value: OpenAiRole.SYSTEM,
                                label: "system",
                            },
                            {
                                value: OpenAiRole.USER,
                                label: "user",
                            },
                        ]}
                        value={{
                            value: editablePrompt.role,
                            label: editablePrompt.role.toString(),
                        }}
                        onChange={(opt) => {
                            setProposedPrompt([
                                ...proposedPrompt.slice(0, index),
                                { ...editablePrompt, role: opt!.value },
                                ...proposedPrompt.slice(index + 1),
                            ]);
                        }}
                    />
                    <Textarea
                        className="h-[140px] mt-1"
                        value={editablePrompt.content}
                        onChange={(value) => {
                            const content = value.replace(/\r?\n|\r|\n/g, "");
                            setProposedPrompt([
                                ...proposedPrompt.slice(0, index),
                                { ...editablePrompt, content },
                                ...proposedPrompt.slice(index + 1),
                            ]);
                        }}
                    />
                </div>
            ))}
        </Modal>
    );
};

export default PromptOverrideModal;
