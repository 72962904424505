import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";
import Select from "react-select";

import { saveProject } from "../../api";
import { Messages } from "../../apps/message-list";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setProject } from "../../stores/project";
import { Project } from "../../types/goldpan";
import { assertNonNull, defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import FormField from "../form-field";
import Input from "../input";

type ProjectForm = {
    title: string;
    client_name: string;
    main_interview_field: number | null;
};

const getFormFromProject = (project: Project): ProjectForm => ({
    title: project.title,
    client_name: project.client_name,
    main_interview_field: project.main_interview_field,
});

const ProjectDetails = () => {
    const dispatch = useAppDispatch();
    const metadataFields = useAppSelector(
        (state) => state.project.interviewMetadataFields,
    );
    const project = assertNonNull(
        useAppSelector((state) => state.project.project),
    );

    const [isSaving, setIsSaving] = useState(false);
    const [proposedProject, setProposedProject] = useState<ProjectForm>(
        getFormFromProject(project),
    );

    const handleReset = useCallback(
        () => setProposedProject(getFormFromProject(project)),
        [project],
    );

    const handleSave = useCallback(async () => {
        setIsSaving(true);
        const response = await saveProject(project.id, {
            ...proposedProject,
            id: project.id,
            status: project.status,
        });

        if (response) {
            dispatch(setProject(response.data));
            Messages.success("Project details updated");
        }
        setIsSaving(false);
    }, [dispatch, project, proposedProject]);

    const metadataFieldOptions = useMemo(
        () =>
            metadataFields.map((field) => ({
                label: field.display_title,
                value: field.id,
            })),
        [metadataFields],
    );

    const changesHaveBeenMade = useMemo(
        () => !isEqual(proposedProject, getFormFromProject(project)),
        [project, proposedProject],
    );

    return (
        <div className="max-w-4xl flex flex-col gap-4">
            <FormField label="Title">
                <Input
                    className="project-title-input"
                    value={proposedProject.title}
                    onChange={(title) =>
                        setProposedProject({ ...proposedProject, title })
                    }
                />
            </FormField>
            <FormField label="Client Name">
                <Input
                    className="project-client-name-input"
                    value={proposedProject.client_name}
                    onChange={(client_name) =>
                        setProposedProject({ ...proposedProject, client_name })
                    }
                />
            </FormField>
            <FormField
                infoTooltip="The interview metadata field that differentiates interviews. If blank, interview outcomes will be used"
                label="Main Interview Field"
            >
                <Select
                    {...defaultSelectProps}
                    className="project-main-field-select"
                    isClearable
                    isMulti={false}
                    options={metadataFieldOptions}
                    value={
                        proposedProject.main_interview_field
                            ? metadataFieldOptions.find(
                                  (opt) =>
                                      opt.value ===
                                      proposedProject.main_interview_field,
                              )
                            : null
                    }
                    onChange={(opt) =>
                        setProposedProject({
                            ...proposedProject,
                            main_interview_field: opt ? opt.value : null,
                        })
                    }
                />
            </FormField>

            <div className="flex items-center justify-end gap-4 mt-4">
                <Button
                    className="save-project-details-btn"
                    disabled={!changesHaveBeenMade}
                    icon="save"
                    isLoading={isSaving}
                    variant={ButtonVariant.SUCCESS}
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button disabled={!changesHaveBeenMade} onClick={handleReset}>
                    Discard
                </Button>
            </div>
        </div>
    );
};

export default ProjectDetails;
