import classNames from "classnames";
import { useCallback, useState } from "react";
import Select, { Options, SingleValue } from "react-select";

import {
    DecisionReason,
    InterviewParagraph,
    SelectOption,
    Subtheme,
    WithUUID,
    emptyQuote,
} from "../../types/goldpan";
import { defaultSelectProps } from "../../utils";
import Button, { ButtonVariant } from "../button";
import FormField from "../form-field";
import Icon from "../icon";
import Modal from "../modal";
import Textarea from "../textarea";
import QuoteInput from "./quote-input";
import SaveDeleteButtons from "./save-delete-buttons";

interface Props {
    decisionReason?: WithUUID<DecisionReason>;
    subtheme: Subtheme;
    isDisabled: boolean;
    paragraphOptions: Options<SelectOption>;
    interviewId: number;
    interviewParagraphs: InterviewParagraph[];
    isParsingDecisions: number[] | null;
    onAddNewDecisionReason: (subthemeId: number) => void;
    onSaveDecisionReason: (subthemeId: number) => Promise<void>;
    onDeleteDecisionReason: (subthemeId: number) => Promise<void>;
    onUpdateDecisionReason: (decisionReason: WithUUID<DecisionReason>) => void;
    onParseDecisionReason: () => void;
}

const DecisionReasonCard: React.FC<Props> = ({
    decisionReason,
    interviewId,
    interviewParagraphs,
    isDisabled,
    isParsingDecisions,
    onAddNewDecisionReason,
    onDeleteDecisionReason,
    onParseDecisionReason,
    onSaveDecisionReason,
    onUpdateDecisionReason,
    paragraphOptions,
    subtheme,
}) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isConfirmingDeleteQuote, setIsConfirmingDeleteQuote] = useState<
        number | null
    >(null);

    const handleSaveDecisionReason = useCallback(async () => {
        setIsSaving(true);
        await onSaveDecisionReason(subtheme.id!);
        setIsSaving(false);
    }, [subtheme, onSaveDecisionReason]);

    const handleDeleteDecisionReason = useCallback(async () => {
        setIsDeleting(true);
        await onDeleteDecisionReason(subtheme.id!);
        setIsDeleting(false);
    }, [subtheme, onDeleteDecisionReason]);

    const handleDeleteResponseQuote = useCallback(async () => {
        if (!decisionReason || isConfirmingDeleteQuote === null) {
            return;
        }
        const index = isConfirmingDeleteQuote;
        onUpdateDecisionReason({
            ...decisionReason,
            data: {
                ...decisionReason.data,
                quotes: [
                    ...decisionReason.data.quotes.slice(0, index),
                    ...decisionReason.data.quotes.slice(index + 1),
                ],
            },
            isDirty: true,
        });
        setIsConfirmingDeleteQuote(null);
    }, [decisionReason, isConfirmingDeleteQuote, onUpdateDecisionReason]);

    const handleChangeSummary = useCallback(
        (summary: string) => {
            if (!decisionReason) {
                return;
            }
            onUpdateDecisionReason({
                ...decisionReason,
                data: {
                    ...decisionReason.data,
                    summary,
                },
                isDirty: true,
            });
        },
        [decisionReason, onUpdateDecisionReason],
    );

    const handleChangeParagraph = useCallback(
        (opt: SingleValue<SelectOption>, index: number) => {
            if (!decisionReason) {
                return;
            }
            onUpdateDecisionReason({
                ...decisionReason,
                data: {
                    ...decisionReason.data,
                    quotes: [
                        ...decisionReason.data.quotes.slice(0, index),
                        {
                            ...decisionReason.data.quotes[index],
                            interview_paragraph: opt ? opt.value : null,
                            // update text if quote field is blank
                            text:
                                decisionReason.data.quotes[0].text === "" && opt
                                    ? interviewParagraphs.find(
                                          (p) => p.id === opt.value,
                                      )?.text ?? ""
                                    : decisionReason.data.quotes[0].text,
                        },
                        ...decisionReason.data.quotes.slice(index + 1),
                    ],
                },
                isDirty: true,
            });
        },
        [decisionReason, interviewParagraphs, onUpdateDecisionReason],
    );

    const handleChangeQuote = useCallback(
        (text: string, index: number) => {
            if (!decisionReason) {
                return;
            }
            onUpdateDecisionReason({
                ...decisionReason,
                data: {
                    ...decisionReason.data,
                    quotes: [
                        ...decisionReason.data.quotes.slice(0, index),
                        {
                            ...decisionReason.data.quotes[index],
                            text,
                        },
                        ...decisionReason.data.quotes.slice(index + 1),
                    ],
                },
                isDirty: true,
            });
        },
        [decisionReason, onUpdateDecisionReason],
    );

    const handleAddNewQuote = useCallback(() => {
        if (!decisionReason) {
            return;
        }
        onUpdateDecisionReason({
            ...decisionReason,
            data: {
                ...decisionReason.data,
                quotes: [emptyQuote(interviewId)].concat(
                    decisionReason.data.quotes,
                ),
            },
            isDirty: true,
        });
    }, [decisionReason, interviewId, onUpdateDecisionReason]);

    return (
        <div className="card flex flex-col mb-4">
            {decisionReason ? (
                <>
                    <Modal
                        isOpen={isConfirmingDeleteQuote !== null}
                        onClose={() => setIsConfirmingDeleteQuote(null)}
                        onConfirm={handleDeleteResponseQuote}
                    >
                        Are you sure?
                    </Modal>
                    <div className="mb-2 flex items-center justify-between">
                        <div className="font-bold text-lg ">
                            {`${subtheme.parent.name} > ${subtheme.name}`}
                        </div>
                        <div className="flex items-center gap-2">
                            <div
                                className={classNames("flex items-center", {
                                    "text-red-700": decisionReason.isDirty,
                                    "text-green-700": !decisionReason.isDirty,
                                })}
                            >
                                {decisionReason.isDirty ? (
                                    <Icon icon="error" />
                                ) : (
                                    <Icon icon="check_circle" />
                                )}
                            </div>
                            <Button
                                className="no-bg"
                                icon={
                                    isCollapsed ? "expand_more" : "expand_less"
                                }
                                variant={ButtonVariant.NORMAL}
                                onClick={() =>
                                    isCollapsed
                                        ? setIsCollapsed(false)
                                        : setIsCollapsed(true)
                                }
                            />
                        </div>
                    </div>
                    {!isCollapsed && (
                        <>
                            <FormField label="Summary">
                                <Textarea
                                    className="h-48 resize-none"
                                    disabled={isDisabled}
                                    value={decisionReason.data.summary}
                                    onChange={handleChangeSummary}
                                />
                            </FormField>
                            <div className="flex items-center justify-between mt-4">
                                <div className="font-bold">Quotes</div>
                                <div className="flex items-center gap-2">
                                    <Button
                                        className="ml-4 text-sm"
                                        disabled={isDisabled}
                                        onClick={handleAddNewQuote}
                                    >
                                        Add new quote
                                    </Button>
                                </div>
                            </div>
                            {decisionReason.data.quotes.map((quote, index) => (
                                <div
                                    className="flex flex-grow gap-2 border-t pt-2 mt-2"
                                    key={quote.id ?? `quote_${index}`}
                                >
                                    <div className="flex-1 flex flex-col gap-2">
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <label>Paragraph</label>
                                                {quote.interview_paragraph && (
                                                    <Button
                                                        className="no-bg icon-only"
                                                        onClick={() => {
                                                            const paragraph =
                                                                document.getElementById(
                                                                    "paragraph-" +
                                                                        quote.interview_paragraph,
                                                                );
                                                            paragraph?.scrollIntoView(
                                                                {
                                                                    behavior:
                                                                        "smooth",
                                                                    block: "center",
                                                                },
                                                            );
                                                        }}
                                                    >
                                                        <Icon icon="visibility" />
                                                    </Button>
                                                )}
                                            </div>
                                            <Select
                                                {...defaultSelectProps}
                                                isClearable
                                                isDisabled={isDisabled}
                                                options={paragraphOptions}
                                                value={
                                                    paragraphOptions.find(
                                                        (i) =>
                                                            i.value ===
                                                            quote.interview_paragraph,
                                                    ) ?? null
                                                }
                                                onChange={(opt) =>
                                                    handleChangeParagraph(
                                                        opt,
                                                        index,
                                                    )
                                                }
                                            />
                                        </div>
                                        <QuoteInput
                                            isDisabled={isDisabled}
                                            paragraph={
                                                interviewParagraphs.find(
                                                    (p) =>
                                                        p.id ===
                                                        quote.interview_paragraph,
                                                )?.text
                                            }
                                            quote={quote.text}
                                            onChange={(text) =>
                                                handleChangeQuote(text, index)
                                            }
                                        />
                                    </div>
                                    <Button
                                        className="self-start icon-only"
                                        disabled={isDisabled}
                                        icon="close"
                                        isLoading={isDeleting}
                                        variant={ButtonVariant.DANGER}
                                        onClick={() =>
                                            setIsConfirmingDeleteQuote(index)
                                        }
                                    />
                                </div>
                            ))}
                            <div className="flex items-center justify-end gap-2 mt-4">
                                <SaveDeleteButtons
                                    isDeleting={isDeleting}
                                    isDisabled={isDisabled}
                                    isSaved={!decisionReason.isDirty}
                                    isSaving={isSaving}
                                    saveClassName="save-decision-button"
                                    onDelete={handleDeleteDecisionReason}
                                    onSave={handleSaveDecisionReason}
                                />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className="flex items-center justify-between mb-2">
                    <div className="font-bold text-lg">{`${subtheme.parent.name} > ${subtheme.name}`}</div>
                    <div className="flex items-center gap-2">
                        <Button
                            className="text-sm"
                            disabled={isDisabled}
                            onClick={() => onAddNewDecisionReason(subtheme.id!)}
                        >
                            Start empty decision
                        </Button>
                        <Button
                            disabled={isDisabled}
                            id={`parse-decision-reason-button-${subtheme.id}`}
                            isLoading={isParsingDecisions?.includes(
                                subtheme.id!,
                            )}
                            variant={ButtonVariant.PRIMARY}
                            onClick={onParseDecisionReason}
                        >
                            Parse decisions
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DecisionReasonCard;
